import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import PropTypes, { bool } from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';
//import { Container, Row, Col } from 'reactstrap';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import HelpIcon from '@mui/icons-material/Help';
import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import { AuthContext } from "../../auth/authProvider.jsx";

import Clock from 'react-live-clock';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { CreateInitials } from 'Utils/AvatarHelper.js';
import { CreateUrl, CallGetAPI, CallPutAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import WavesIcon from '@mui/icons-material/Waves';
import { ReactComponent as AshLogo } from '../../assets/ashLogo.svg';
import Tooltip from '@mui/material/Tooltip';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import GutermannLogo from '../../assets/gutermann/gutermann-white.png'

const useStyles = makeStyles(styles);

export default function Header(props) {
    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [groupsOnUser, setGroupsOnUser] = useState(null);
    const [filterGroup, setFilterGroup] = useState(null);
    const [timeFrame, setTimeFrame] = useState(window.sessionStorage.getItem("inactiveTimeframe") != "null" ? window.sessionStorage.getItem("inactiveTimeframe") : "24 Hours");
    const [impersonateUser, setImpersonateUser] = useState(null);
    const [flowUnits, setFlowUnits] = useState(window.sessionStorage.getItem("flowUnits") != "null" ? window.sessionStorage.getItem("flowUnits") : "0");
    const [utcOffset, setUtcOffset] = useState(window.sessionStorage.getItem("utcOffset") != "null" ? (parseInt(window.sessionStorage.getItem("utcOffset")) > 0 ? window.sessionStorage.getItem("utcOffset") : window.sessionStorage.getItem("utcOffset") ) : "0");
    const authContext = useContext(AuthContext);
    const [totaliser, setTotaliser] = useState(window.sessionStorage.getItem("graphTotaliser"))

    const GetUser = async () => {
        const user = await authContext.getUser();
        setUser(user);
        if (props.impersonateUser != "")
        {
            setImpersonateUser(props.impersonateUser);
        }
    };   

    useEffect(() => {
        if (!user) {
            GetUser();
        }
        //fetch groups

        if (filterGroup == null) {
            setFilterGroup(parseInt(window.sessionStorage.getItem("filterGroupId")));
        }

        const userId = sessionStorage.getItem('userId');
        if (groupsOnUser == null && userId != "0") {
            // Set non null to prevent multiple calls
            setGroupsOnUser([]);
            CallGetAPI(CreateUrl('/api/aquaguard/GetAllGroupsForUser?userId=' + userId), {})
                .then(data => {

                    if (data.length > 0) {

                        const groups = [];

                        for (let i = 0; i < data.length; i++) {
                            const name = data[i].groupName;
                            const id = data[i].groupId;
                            //const rec: Group = {
                            //    id: id,
                            //    name: data[i].name,
                            //    kmlFile: data[i].kmlFile,
                            //    FkCompanyId: data[i].fkCompanyId,
                            //    rawXml: null,
                            //    xml: null,
                            //    lastUpdate: data[i].lastUpdate,
                            //    created: data[i].created,
                            //    zone: null
                            //};
                            groups.push({ label: name, value: id });
                        }

                        //me.store.push(records);

                        //me.setState(
                        //    {
                        //        tableHidden: false,
                        //        loading: false
                        //    })

                        setGroupsOnUser(groups);

                    }
                    else {
                        // NOT NEEDED
                        //me.setState(
                        //    {
                        //        tableHidden: false,
                        //        loading: false
                        //    })
                        setGroupsOnUser([]);
                    }


                })
                .catch(function (error) {
                    // NOT NEEDED
                    //me.setState(
                    //    {
                    //        loading: false
                    //    })
                    setGroupsOnUser([]);
                    console.log(error);
                });
        }
    });

    

//    const handleClick = async () => {
      
//    };

    function createSuccessNotification() {
        NotificationManager.success('Saved Changes', 'Success');

    }

    function createErrorNotification(error) {
        NotificationManager.error('Error Saving Changes', 'Check console for details', 5000, () => {
            alert(error);
        });
    }

    function userFilterChanged(e) {
        setFilterGroup(parseInt(e.target.value));
    }

    function userTimeFrameChanged(e) {
        
        setTimeFrame(e.target.value)
    }

    function utcOffsetChanged(e) {
        setUtcOffset(e.target.value);
    }

    function totaliserChanged(e) {
            setTotaliser(e.target.value)
    }

    function tzLookup(offset) {


        // Timezone = UTC for O, otherwise Etc/GMT+? or Etc/GMT-?
        // From moment timezones https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
        // For some reason Clock negates the offset!!!
        
        if (offset == 0) {
            return "Etc/UTC";
        }
        else if (offset > 0) {
            
            return "Etc/GMT-" + parseInt(offset);
        }
        else if (offset < 0 ) {
            
            return "Etc/GMT+" + -(parseInt(offset));
        }
       

    }

    function updateUserPreferences() {
        //********************
        //send model to API

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                UserId: sessionStorage.getItem('userId'),
                UserGuid: sessionStorage.getItem('userGuid'),
                FilterGroupId: filterGroup,
                FlowUnits: flowUnits,
                UtcOffset: parseInt(utcOffset),
                InactiveTimeframe: timeFrame,
                GraphTotaliser: totaliser === "true" ? true : false
            })
        };

        setModalVisible(false);

        CallGetAPI(CreateUrl('/api/aquaguard/SetUserPreferences'), requestOptions)
            .then(data => {
                if (data.status === undefined) {
                    // Update session store
                    window.sessionStorage.setItem("filterGroupId", filterGroup);
                    if (groupsOnUser.length > 0 && filterGroup != 0) {
                        window.sessionStorage.setItem("filterGroupName", groupsOnUser.filter(g => g.value == filterGroup)[0].label);
                    }
                    window.sessionStorage.setItem("flowUnits", flowUnits);
                    window.sessionStorage.setItem("utcOffset", utcOffset);
                    window.sessionStorage.setItem("inactiveTimeframe", timeFrame);
                    window.sessionStorage.setItem("graphTotaliser", totaliser);
                    createSuccessNotification();
                    window.location.href = '/portal/dashboard'
                }
                else {
                    createErrorNotification();
                    console.log(data.title);
                }
            })

            .catch(function (error) {
                createErrorNotification(error);
                console.log(error);
            });
    }

    function flowUnitsChanged(e) {
        setFlowUnits(e.target.value);
    }


    function closeDialog() {
        setModalVisible(false);
    }

    function generateDialog() {
        setTimeFrame(window.sessionStorage.getItem("inactiveTimeframe"))
        setModalVisible(true);
    }

    function signOut() {
        authContext.logout();
    }
    
    function endImpersonation() {
        window.sessionStorage.removeItem('impersonateUser');
        window.sessionStorage.removeItem('impersonateCompany');
        console.log(user)
        // Restore identityadmin details
        window.sessionStorage.setItem('companyId', 0);
        window.sessionStorage.setItem('companyGuid', user.profile.company.toString());
        window.sessionStorage.setItem('userId', 0);
        window.sessionStorage.setItem('userLevel', user.profile.userlevel);
        window.sessionStorage.setItem('userGuid', user.profile.sub.toString());
        window.sessionStorage.setItem('filterGroupId', 0);
        window.sessionStorage.setItem('companyName', "Ashridge");
        window.sessionStorage.setItem("utcOffset", "0");
        window.sessionStorage.setItem("inactiveTimeframe", "24 Hours");
        window.sessionStorage.setItem("graphTotaliser", "true")
        // Reload to update navbar
        window.location.reload();
    }


    function makeBrand() {
        let name;
        props.routes.map(prop => {
            if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
                name = props.rtlActive ? prop.rtlName : prop.name;
            }
            return null;
        });
        return name;
    }
    const { color } = props;
    const appBarClasses = classNames({
        [" " + classes[color]]: color
    });

    const isIAuser = sessionStorage.getItem('userId') == "0";

    return (
        <AppBar className={classes.appBar + appBarClasses} >
        

            <Dialog
                open={modalVisible}
                onHide={() => closeDialog()}
                dialogClassName="modal-100w"
                aria-labelledby="example-custom-modal-styling-title">
                
                <Modal.Header className={classes.primary} style={{borderRadius: "0"}}>
                    <Modal.Title id="example-custom-modal-styling-title" style={{paddingLeft: "20px"} }>
                        {user?.profile.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>Name: <b>{user?.profile.name}</b></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>Company:  <b>{window.sessionStorage.getItem("companyName")}</b></p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>User Level: <b>{user?.profile.userlevel}</b></p>
                        </Grid>
                        <Grid item xs={12}>
                        <p>Groups:<ul> {groupsOnUser?.map((s, i) => {
                            return <li key={i}><p><b>{s.label}</b></p></li>;
                        })}</ul></p>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="middle" role="presentation"><p>Preferences</p></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ marginTop: "15px" }}>Set the time frame for non reporting loggers:</p>
                                <Select
                                    id="outlined-select"
                                    label="Filter"
                                    value={timeFrame}
                                    onChange={userTimeFrameChanged}
                                    variant="outlined"
                                    style={{ width: 200, float: "right" }}
                                    disabled={isIAuser}>

                                    <MenuItem value="24 Hours">24 Hours</MenuItem>
                                    <MenuItem value="3 Days" >3 Days</MenuItem>
                                    <MenuItem value="4 Days" >4 Days</MenuItem>
                                    <MenuItem value="7 Days">7 Days</MenuItem>
                                    <MenuItem value="14 Days">14 Days</MenuItem>

                                </Select>
                            </div>
                        </Grid>
                        
                        {sessionStorage.getItem('userLevel') !== 'user' && <Grid item xs={12}>


                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ marginTop: "15px" }}>Set Group to filter ALL screens:</p>
                                <Select
                                    id="outlined-select"
                                    label="Filter"
                                    value={filterGroup}
                                    onChange={userFilterChanged}
                                    variant="outlined"
                                    style={{ width: 200, float: "right" }}
                                    disabled={isIAuser}
                                >
                                    <MenuItem key="0" value="0">None</MenuItem>
                                    {groupsOnUser?.map((s, i) => {
                                        return <MenuItem key={i} value={s.value} > {s.label}</MenuItem>;
                                    })}
                                </Select>
                            </div>
                        </Grid>}
                        {!window.location.hostname.includes('zonelog.net') && <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ marginTop: "15px" }}>Display graph flow units as:</p>
                                <Select
                                    id="outlined-select"
                                    label="Flow Units"
                                    value={flowUnits}
                                    onChange={flowUnitsChanged}
                                    variant="outlined"
                                    style={{ width: 300, float: "right" }}
                                    disabled={isIAuser}
                                >
                                    <MenuItem value="0">Determine from readings</MenuItem>
                                    <MenuItem value="m3h" >Always m<sup>3</sup>/h</MenuItem>
                                    <MenuItem value="ls">Always l/s</MenuItem>
                                    <MenuItem value="lm">Always l/min</MenuItem>
                                    <MenuItem value="gm">Always gallons/minute</MenuItem>
                                </Select>
                            </div>
                        </Grid>}
                        {!window.location.hostname.includes('zonelog.net') &&<Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ marginTop: "15px" }}>Display graph totaliser:</p>
                                <Select
                                    id="outlined-select"
                                    label="Flow Units"
                                    value={totaliser}
                                    onChange={totaliserChanged}
                                    variant="outlined"
                                    style={{ width: 300, float: "right" }}
                                    disabled={isIAuser}
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false" >No</MenuItem>

                                </Select>
                            </div>

                        </Grid>}
                        
                        <Grid item xs={12}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p style={{ marginTop: "15px" }}>Display times as local - UTC offset:</p>
                                <Select
                                id="outlined-select"
                                label="UTC Offset"
                                value={utcOffset}
                                onChange={utcOffsetChanged}
                                variant="outlined"
                                style={{ width: 300, float: "right" }}
                                disabled={isIAuser}
                            >
                                <MenuItem value="0">Use UTC</MenuItem>
                                <MenuItem value="+1" >+1 hour</MenuItem>
                                <MenuItem value="+2" >+2 hours</MenuItem>
                                <MenuItem value="+3" >+3 hours</MenuItem>
                                <MenuItem value="+4" >+4 hours</MenuItem>
                                <MenuItem value="+5" >+5 hours</MenuItem>
                                <MenuItem value="+6" >+6 hours</MenuItem>
                                <MenuItem value="+7" >+7 hours</MenuItem>
                                <MenuItem value="+8" >+8 hours</MenuItem>
                                <MenuItem value="+9" >+9 hours</MenuItem>
                                <MenuItem value="+10" >+10 hours</MenuItem>
                                <MenuItem value="+11" >+11 hours</MenuItem>
                                <MenuItem value="+12" >+12 hours</MenuItem>
                                <MenuItem value="-1" >-1 hour</MenuItem>
                                <MenuItem value="-2" >-2 hours</MenuItem>
                                <MenuItem value="-3" >-3 hours</MenuItem>
                                <MenuItem value="-4" >-4 hours</MenuItem>
                                <MenuItem value="-5" >-5 hours</MenuItem>
                                <MenuItem value="-6" >-6 hours</MenuItem>
                                <MenuItem value="-7" >-7 hours</MenuItem>
                                <MenuItem value="-8" >-8 hours</MenuItem>
                                <MenuItem value="-9" >-9 hours</MenuItem>
                                <MenuItem value="-10" >-10 hours</MenuItem>
                                <MenuItem value="-11" >-11 hours</MenuItem>
                                </Select>
                            </div>
                        </Grid>
                    </Grid>
                    </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" variant="contained" style={{marginRight: "10px"}} onClick={() => updateUserPreferences()} disabled={isIAuser}>Update Preferences</Button>
                    <Button color="secondary" variant="contained" onClick={() => closeDialog()}>Cancel</Button>
                </Modal.Footer>
            </Dialog>
            {/*<Dialog*/}
            {/*    open={true}*/}
            {/*    dialogClassName="modal-100w">*/}
            {/*    <Modal.Body>*/}
            {/*        <embed src="../assets/20007-MAN-0001-02.pdf" type="application/pdf" width="100%" height="600px" />*/}
            {/*    </Modal.Body>*/}
            {/*</Dialog>*/}
            
            <Toolbar className={classes.container + classes.appBar }>
                <div className={classes.flex}>
                    {/* Here we create navbar brand, based on route name */}
                  
                    <div style={{textTransform: "uppercase", fontWeight: "400", fontSize: "18px"} } >
                        <IconButton
                            aria-label="delete"
                            onClick={() => props.handleDrawerToggle()}
                            size="large"
                            style={{ marginRight: '10px', border: "2px solid white", outline: "none"}}
                        >
                            <MenuIcon style={{ fill: 'white', width: "30px", height: "30px", }} />
                            
                            
                        </IconButton>
                        <Link to="/portal/dashboard">
                            {window.location.hostname.includes('zonelog.net') ? <img src={GutermannLogo} style={{ width: "5%", height: "5%", margin: " 0 10px" }} /> :<AshLogo style={{ width: "60px", height: "60px", marginRight: "10px" }} />}
                            <span style={{color: "white"} }>{props.logoText}</span>
                        </Link>

                        
                    </div>
                </div>
                <Paper elevation={0} sx={{ display: { md: 'block', xs: 'none' }, backgroundColor: "transparent" }}   >
                    {utcOffset == 0 &&
                        <span style={{ color: 'white', padding: 2, 'fontSize': 18 }} >UTC Time:</span>
                    }
                    {utcOffset != 0 &&
                        <span style={{ color: 'white', padding: 2, 'fontSize': 18 }} >Local time (UTC{utcOffset}):</span>
                    }
                </Paper>
                <div className={classes.flex}>
                    <Clock
                        style={{ color: 'white', padding: 2, 'fontSize': 18}}
                        format={'HH:mm:ss'}
                        ticking={true}
                        timezone={tzLookup(utcOffset)}

                    />
                </div>
                {user &&
                    <table>
                    <tbody>
                        <tr>
                            <td rowSpan="3">
                                <Button id="avatarBtn" href="#" className={classes.title}
                                    onClick={() => { generateDialog() }}>
                                    <Avatar>{CreateInitials(user?.profile.name)}</Avatar>
                                </Button>
                            </td>
                            <td>
                                <Button id="nameBtn" href="#" className={classes.title}
                                onClick={() => { generateDialog(); }}>
                                <span style={{ color: 'white', textTransform: "none"}}>
                                    {user?.profile.name + " [ " + (user?.profile.userlevel == 'identityadmin' ? 'ADMIN' :  window.sessionStorage.getItem('companyName')) + " ]" }
                                </span>
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {impersonateUser &&
                                        <span style={{color: "white"} }>As                                
                                    <Button id="restoreBtn" href="#" className={classes.title}
                                        onClick={() => { endImpersonation(); }}>
                                        <span style={{ color: 'white', textTransform: "none"}}>
                                            {impersonateUser}
                                        </span>
                                 </Button>       
                                        </span>
                                }
                            </td>
                        </tr>
                            {sessionStorage.getItem('userLevel') !== 'user' && <tr>
                                <td>
                                    {filterGroup > 0 &&
                                        <span style={{ color: 'white', textTransform: "none" }}>
                                            Views filtered to {window.sessionStorage.getItem("filterGroupName")}
                                        </span>
                                    }
                                </td>
                            </tr>}
                    </tbody>
                    </table>
                }
                <Button variant="contained" onClick={() => signOut()}>
                    Sign Out
                    </Button>
            </Toolbar>

            <NotificationContainer />

        </AppBar>
    );


}

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    routes: PropTypes.arrayOf(PropTypes.object),
    impersonateUser: PropTypes.string
};


//<Hidden smDown implementation="css">
//    {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
//</Hidden>
//    <Hidden mdUp implementation="css">
//        <IconButton
//            color="inherit"
//            aria-label="open drawer"
//            onClick={props.handleDrawerToggle}
//        >
//            <Menu />
//        </IconButton>
//    </Hidden>