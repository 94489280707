import React, { useState, useEffect, ChangeEvent, useCallback, useRef } from "react" 
import Chart, { Grid as GridChart, CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point,  Title, AggregationInterval, Label, Height, Crosshair, Font, MinorTickInterval, Tick, MinorTick, Strip, CommonAxisSettings } from 'devextreme-react/chart';
import RangeSelector, {Size, Chart as ChartOptions, Margin, Scale, Behavior, RangeSelectorTypes,
} from 'devextreme-react/range-selector';
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import ApiFailed from '../../Utils/ApiFailed';
import ClipLoader from "react-spinners/ClipLoader";
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from '../../models/APIGetLoggerReadingsModel';
import moment from 'moment'
import { decodeFlowMultiplier } from 'Utils/FlowMultiplier';
import { adjustTime } from '../../Utils/AdjustTime'
import DateRange from '../../components/Dates/DateRange';
import CardHeader from "components/Card/CardHeader.js";
import { withStyles, createStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { VisualRange } from 'devextreme-react/common/charts';
import { TextField, Chip, Button, Select, MenuItem, Checkbox } from "@mui/material"
import Modal from 'react-bootstrap/Modal';
import { Item } from 'devextreme-react/tab-panel';
import Tabs from 'devextreme-react/tabs'
import { PeriodStatsCHR } from '../../components/Readings/PeriodStatsCHR'
import { PeriodStatsTOR } from '../../components/Readings/PeriodStatsTOR'
import { Export } from 'devextreme-react/data-grid';
import { enumChariotAlarmInput } from '../../models/enumChariotAlarmInput';
import { enumChariotAlarmOperator } from '../../models/enumChariotAlarmOperator';
import GraphSettingsComponent from '../../components/GraphTemplate/GraphSettingsComponent'
import { GraphTemplate, graphTemplateModel, GraphSettings, deepCopyAshridgeDefault } from '../../models/GraphTemplateModel'
import { NotificationManager } from 'react-notifications';
interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            groupId: number | null;
            loggers: Array<number>;
            showBackButton: boolean;
            site: number | null;
        }
    }
    startDateProps: Date;
    endDateProps: Date;
    showDates: boolean;
    ref: React.RefObject<unknown>;

}

interface Alarm {
    id: number;
    serial: number | undefined
    site: number | undefined
    channel: string;
    message: string;
    setLevel: number;
    clrLevel: number;
    setOperator: enumChariotAlarmOperator;
}






interface Readings extends Record<string, any> {
    date: Date;

}
const MultiLoggerGraphV2: React.FC<Props> = ({classes, history, location, startDateProps, endDateProps, showDates, ref }) => {


    const chartRef = useRef(null);
    const styles: Record<any, any> = {
        formControl: {
            minWidth: 120,
        },
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0"
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF"
            }
        },
        cardTitleWhite: {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            color: "#FFFFFF",
            minHeight: "auto",
            fontWeight: "300",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            textDecoration: "none",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1"
            }
        }
    };

    const style = createStyles(styles);

    //UseEffect for initial load

    useEffect(() => {
        setLoading(true)
        reloadData(location.state.groupId, location.state.loggers, location.state.site, null)
    }, [])





    //State
    const [loading, setLoading] = useState<boolean>(true)
    const [APIFailed, setAPIFailed] = useState<boolean>(false)
    const [authorised, setAuthorised] = useState<boolean>(true)
    const [siteNameUserFriendly, setSiteNameUserFriendly] = useState<string>("")
    const [startDate, setStartDate] = useState<moment>((startDateProps != null ? moment(startDateProps) : moment().subtract(7, "days")).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),)
    const [endDate, setEndDate] = useState<moment>(endDateProps != null ? moment(endDateProps) : moment().toDate())
    const [readingsData, setReadingsData] = useState<Array<APIGetLoggerReadingsModel>>([])
    const [graphData, setGraphData] = useState<Array<Readings>>([])
    const [loggers, setLoggers] = useState<Array<APILoggerDetail>>([])
    const [flowAs, setFlowAs] = useState<string | null>(null)
    const [loggersToTemplate, setLoggersToTemplate] = useState<Array<APILoggerDetail>>([])
    const [isDefault, setIsDefault] = useState<boolean>(false)
    const [ashridgeDefaultInUse, setAshridgeDefaultInUse] = useState<boolean>(false)
    ///modal
    const [createCompanyDefault, setCreateCompanyDefault] = useState<boolean>(false)
    const [createTemplateOpen, setCreateTemplateOpen] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [selectLoggerToTemplate, setSelectLoggerToTemplate] = useState<boolean>(false)
    ///Units an values State
    const [unitsAndValues, setUnitsAndValues] = useState<Array<GraphTemplate>>([])
    const [graphTemplate, setGraphTemplate] = useState<GraphTemplate>(graphTemplateModel)
    const [alarms, setAlarms] = useState<Array<Alarm>>([])
    const [showFlow, setShowFlow] = useState<boolean>(false)
    const [showPressure, setShowPressure] = useState<boolean>(false)
    const [hasPressureChannels, setHasPressureChannels] = useState<boolean>(false)
    const [showTotal, setShowTotal] = useState<boolean>(false)
    ///channel References 

    const [channelARef, setChannelARef] = useState<string>("")


    //other

    const [channelsAvail, setChannelsAvail] = useState<number>()


    /// Range state
    const [visualRange, setVisualRange] = useState<VisualRange>({ startValue: startDate, endValue: endDate })
 


    //Callbacks


    


    /// Functions

    const reloadData = (groupId: number | null, loggersProps: Array<number>, site: number | null, dateChange: boolean | null): boolean => {
        
        setLoading(true)

        if (groupId != null && groupId != 0) {

            // Group selected - graph loggers deployed to sites in group
            CallGetAPI(CreateUrl('/api/aquaguard/GetAllLoggersForGroup?groupid=' + groupId), {})
                .then(data => {

                    if (data.length > 0) {

                        let siteId = 0;
                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])
                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        //// Load alarm levels if single Chariot logger
                        if (records.length === 1) {
                            if (records[0].LoggerId > 500000) {
                                loadLCAlarmLevels(records[0].Serial);
                            }
                            else {
                                loadLoggerAlarmLevels(records[0].Serial);
                            }
                        }
                        
                            if (siteId > 0) {
                                // Load alarm levels for Site if all loggers deployed to same site
                                if (records[0].FkSiteId != null) {
                                    loadSiteAlarmLevels(siteId);
                                }
                            }
                        
                        
                        drawGraph(records, null, dateChange);

                    }
                    else {
                        drawGraph([], null, dateChange);
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)

                })
        }


        if (loggersProps.length > 0) {
            // List of loggers selected
            const loggerIds: Array<{ Id: number }> = [];

            if (loggersProps.length > 0) {
                loggersProps.map((l) => {
                    loggerIds.push({ Id: l });

                });
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loggerIds)
            };


            CallGetAPI(CreateUrl('/api/aquaguard/LoggerDetailsByIdList'), requestOptions)
                .then(data => {

                    

                    if (data.length > 0) {

                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        let siteId = 0;
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])

                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        // console.log("RELOAD Records Length " + records.length);

                        // if (records.length > 0) { console.log("RELOAD Records[0] Serial -> " + records[0].Serial); }

                        // Load alarm levels if single Chariot logger
                        if (records.length === 1) {
                            if (records[0].LoggerId > 500000) {
                                loadLCAlarmLevels(records[0].Serial);
                            }
                            else {

                                loadLoggerAlarmLevels(records[0].Serial);
                            }
                        }
                        
                        drawGraph(records, null, dateChange);

                    }
                    else {
                        drawGraph([], null, dateChange);
                    }

                })
                .catch((error) => {
                    console.log(error)
                    setAPIFailed(true)
                    setAuthorised(false)
                        
                });
        }

        // For site just pull readings and alarm levels for site
        if (site != null && site != 0) {

            //TODO: Need to get SiteUserFriendlyName
            CallGetAPI(CreateUrl('/api/aquaguard/site?id=' + site), {})
                .then(data => {

                    if (data.siteNameUserFriendly != null) {
                            setSiteNameUserFriendly(data.siteNameUserFriendly.length > 0 ? data.siteNameUserFriendly : data.siteName)  
                    }
                    else {
                            setSiteNameUserFriendly(data.siteName.length > 0 ? data.siteName : "")
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)
                });

            /*this.loadSiteAlarmLevels(site, this);*/

            drawGraph([], site, dateChange);
        }

        return true;
    }






    

    const drawGraph = (loggerDataArray: Array<APILoggerDetail>, site: number | null, dateChange: boolean | null): void => {
        setLoading(true)

        const ashridgeDefault: GraphTemplate = graphTemplateModel
        /// Ashridge default graph template
        const loggersUnitsAndValues = new Array<GraphTemplate>()
        
        //// Graph query in HERE

        ///call for active templates
        let companyDefault = false

            CallGetAPI(CreateUrl(`/api/aquaguard/graphTemplate?CompanyId=${sessionStorage.getItem('companyId')}`), {})


                .then(data => {

                    const extractedData = data.map(item => {
                        item.graphSettings = JSON.parse(item.graphSettings)
                        return item
                    })


 

                if (!site) {
                    const userTemplate = extractedData.filter(template => {
                        return loggerDataArray.map(item => item.Serial).includes(template.serial)
                    }).filter(template => {
                        return template.userId === parseInt(sessionStorage.getItem('userId'))
                    })

                    const companyTemplate = extractedData.filter(template => {
                        return !template.serial && !template.userId
                    })
                    // if containsA Template there is somekind of template for that logger

                            



                    if (userTemplate.length > 0) {
                        // there is user templates
                        if (userTemplate.length === loggerDataArray.length) {
                            console.log('hit')
                            userTemplate.forEach(template => loggersUnitsAndValues.push(template))
                        } else {
                            const numberOfNonUserTemplateToAdd = loggerDataArray.length - userTemplate.length
                            if (companyTemplate.length > 0) {
                                userTemplate.forEach(template => loggersUnitsAndValues.push(template))
                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {
                                    companyTemplate[0].serial = loggerDataArray[i].Serial
                                    companyTemplate[0].channelsAvail = 0
                                    loggersUnitsAndValues.push(companyTemplate)
                                    companyDefault = true
                                }
                            } else {
                                console.log(numberOfNonUserTemplateToAdd)
                                userTemplate.forEach(template => loggersUnitsAndValues.push(template))
                                for (let i = 0; i < numberOfNonUserTemplateToAdd; i++) {
                                    const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                                    newTemplate.isAshridgeDefault = true
                                    newTemplate.serial = loggerDataArray[i + numberOfNonUserTemplateToAdd].Serial
                                    newTemplate.siteId = site
                                    loggersUnitsAndValues.push(newTemplate)
                                }
                            }
                        }
                    } else if (companyTemplate.length > 0) {
                        for (let i = 0; i < loggerDataArray.length; i++) {
 
                            const copy = deepCopyAshridgeDefault(companyTemplate[0])
                            copy.serial = loggerDataArray[i].Serial
                            copy.channelsAvail = 0
                            loggersUnitsAndValues.push(copy)
                            companyDefault = true

                        }
                    } else {
                        setAshridgeDefaultInUse(true)
                        for (let i = 0; i < loggerDataArray.length; i++) {

                            const newTemplate = deepCopyAshridgeDefault(ashridgeDefault)
                            newTemplate.isAshridgeDefault = true
                            newTemplate.serial = loggerDataArray[i].Serial
                            newTemplate.siteId = site

                            loggersUnitsAndValues.push(newTemplate)

                        }
                    }


                } else {
                    for (let i = 0; i < extractedData.length; i++) {
                        if (site === extractedData[i].siteId) {
                            extractedData[i].isDefault = false
                            loggersUnitsAndValues.push(extractedData[i])
                        }
                    }
                }




                    loggersUnitsAndValues.forEach(unit => unit.alarms = [])
                    console.log(loggersUnitsAndValues)
                })
                .catch((error) => {
                    console.log(error);
                    setAPIFailed(true)
                    setAuthorised(false)
                });

        


    
            



        

        



        

        
        let readingsDataTemp = new Array<APIGetLoggerReadingsModel>();
        let graphDataTemp = new Array<Readings>();


        
        
        
        //let altMaxFValue = Number.NEGATIVE_INFINITY;
        //let altMinFValue = Number.MAX_VALUE;

        //let maxTValueTemp = Number.NEGATIVE_INFINITY;
        //let minTValueTemp = Number.MAX_VALUE;
        //let altMaxTValue = Number.NEGATIVE_INFINITY;
        //let altMinTValue = Number.MAX_VALUE;

        //let maxCValue = Number.NEGATIVE_INFINITY;
        //let minCValue = Number.MAX_VALUE;
        //let maxDValue = Number.NEGATIVE_INFINITY;
        //let minDValue = Number.MAX_VALUE;
        //let maxEValue = Number.NEGATIVE_INFINITY;
        //let minEValue = Number.MAX_VALUE;

        //let unitsTTemp = "";
        

        ////let unitsp = "";

        //let unitsc = "";
        //let unitsd = "";
        //let unitse = "";

        

        //// can't graph flow/total if no values in period


        


        
        //let hasflowb = false;
        //let hasTotalATemp = false;
        //let hastotalb = false;


        //// record channel names from channelserial on readings
       
        //let channelsAvailTemp = 0;

        //// detect if c or d values have min & max saved.
        //let haveminmax = false;


    
        


    //    //get readings for the loggers here

    //    //generate array of Promises each adding the readingsData for each logger
        const promises = new Array<Promise<any>>();
        const offset = sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0
        let offsetStartDate
        let offsetEndDate
        if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
            offsetStartDate = moment(startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
            offsetStartDate = moment(startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else {
            offsetStartDate = moment(startDate).format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(endDate).format("yyyy-MM-DDTHH:mm:ss");
        }
console.log(startDate)
        if (site == null) {
            
            loggerDataArray.map((logger) => {
                
                // console.log("DRAWGRAPH Push to API -> " + '/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59");

                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})

                        .then(json => {
                            
                            readingsDataTemp = readingsDataTemp.concat(buildAPIGetLoggerReadingsModel(json));

                        })
                        .catch(function (ex) {
                           
                            console.log(ex);
                        })
                );


            });
        }
        else {
            promises.push(
                CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadingsBySite?siteId=' + site + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})
                    .then(json => {

                        readingsDataTemp = readingsDataTemp.concat(buildAPIGetLoggerReadingsModel(json));
                       
                    })
                    .catch(function (ex) {
                        
                        console.log(ex);
                    })
            );

        }

        //async promises return, add converted readings to graphData array and calculate maximum / minimum
        Promise.all(promises).then(() => {
            readingsDataTemp.forEach((v: APIGetLoggerReadingsModel) => {
                if (v.dateStamp != null) {

                    let multiplier = decodeFlowMultiplier(0);
                    if (v.multiplier != null) {
                        multiplier = decodeFlowMultiplier(v.multiplier);

                    }
                    let value = v.value;
                    if (value == null) {
                        value = v.value2;
                    }

                    let prefix = v.loggerSerial;
                    if (site != null) {
                        prefix = "Site";
                    }

                    //-32768 is an invalid reading for logger, so should be ignored
                    // Flow is ALWAYS l/s regardless of logger units

                    if (v.channelletter == 'A') {

                        if (v.units == "mm") {
                            // Treat as accumulated rainfall
                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (v.difference != null && v.difference >= 0) ? v.difference * multiplier.factor : null;
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            // Sampling data
                            rec[prefix + 'readingCmin'] = null;
                            rec[prefix + 'readingCmax'] = null;
                            rec[prefix + 'readingDmin'] = null;
                            rec[prefix + 'readingDmax'] = null;
                            rec[prefix + 'readingEmin'] = null;
                            rec[prefix + 'readingEmax'] = null;
                            rec['unitA '] = 'mm';
                            graphDataTemp.push(rec);

                            if (value != -32768) {

                                // Expect 4 readings per hour, aggregated
                                for (let i = 0; i < loggersUnitsAndValues.length; i++) {

                                    if (companyDefault) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x01

                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                            loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                        }

                                    } else if (loggersUnitsAndValues[i].isAshridgeDefault) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x01
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                        }
                                        if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                            loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                        }
                                        if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                            loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                        }
                                    }


                                    else {
                                        if (loggersUnitsAndValues[i].serial === parseInt(v.loggerSerial)) {
                                            loggersUnitsAndValues[i].channelsAvail |= 0x01
                                            if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                            }
                                            if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                            }
                                            if (v.difference != null && (v.difference * multiplier.factor * 4) > loggersUnitsAndValues[i].graphSettings.flowAMaxValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMaxValue = v.difference * multiplier.factor * 4;
                                            }
                                            if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < loggersUnitsAndValues[i].graphSettings.flowAMinValue) {
                                                loggersUnitsAndValues[i].graphSettings.flowAMinValue = v.difference * multiplier.factor;
                                            }
                                            if (v.units && loggersUnitsAndValues[i].graphSettings.flowAUnit == "") {
                                                loggersUnitsAndValues[i].graphSettings.flowAUnit = v.units;
                                            }
                                        }
                                    }

                                }


                            }

                        } else {

                            let conversion = 1; //in what everthe unit of reading is
                            let flowConversion = 1; // always l/s
                            let unitsA = ""
                            let unitsAT = ""
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings
                                unitsA = l.flowAUnit
                                unitsAT = l.flowATotalUnit
                                if (!companyDefault) {
                                    if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                        flowConversion = flowConverter(l.flowAUnit)
                                    }
                                } else {
                                    flowConversion = flowConverter(l.flowAUnit)
                                }
                                ///Totaliser Units
                                if (!companyDefault) {
                                    if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                        if (v.units) {
                                            conversion = totalConverter(l.flowATotalUnit, v.units)
                                        }
                                    }
                                } else {
                                    if (v.units) {
                                        conversion = totalConverter(l.flowATotalUnit, v.units)
                                    }
                                }
                            }


                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (value != -32768) ? value * conversion : null;
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            rec[prefix + 'flowRateA'] = v.flowRate * flowConversion;
                            rec[prefix + 'readingCmin'] = null;
                            rec[prefix + 'readingCmax'] = null;
                            rec[prefix + 'readingDmin'] = null;
                            rec[prefix + 'readingDmax'] = null;
                            rec[prefix + 'readingEmin'] = null;
                            rec[prefix + 'readingEmax'] = null;
                            rec['unitA '] = unitsA
                            rec['unitAT'] = unitsAT


                            graphDataTemp.push(rec);

                            //limits

                            if (value !== -32768) {
                                // Loop over loggersUnitsAndValues to find matching logger serials
                                for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                    const l = loggersUnitsAndValues[i].graphSettings
                                    if (v.flowRate && v.flowRate * flowConversion > l.flowAOriginalMax) {
                                        l.flowAOriginalMax = v.flowRate * flowConversion
                                    }
                                    if (v.flowRate && v.flowRate * flowConversion < l.flowAOriginalMin) {
                                        l.flowAOriginalMin = v.flowRate * flowConversion
                                    }
                                    loggersUnitsAndValues[i].channelsAvail |= 0x01
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowAMinState === 'default' || l.flowAMaxState === 'default') {
                                        if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                            if (v.flowRate != null) {
                                                if (l.flowAMaxState === 'default') {
                                                    if (v.flowRate * flowConversion > l.flowAMaxValue) {
                                                        l.flowAMaxValue = v.flowRate * flowConversion;
                                                    }
                                                }
                                                if (l.flowAMinState === 'default') {
                                                    if (v.flowRate * flowConversion < l.flowAMinValue) {
                                                        l.flowAMinValue = v.flowRate * flowConversion;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    ///totals
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowATotalMinState === 'default' || l.flowATotalMaxState === 'default') {
                                        if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                            if (v.value != null) {
                                                if (l.flowATotalMaxState === 'default') {
                                                    if (v.value > l.flowATotalMaxValue) {
                                                        l.flowATotalMaxValue = v.value * conversion;
                                                    }
                                                }
                                                if (l.flowATotalMinState === 'default') {
                                                    if (v.value < l.flowATotalMinValue) {
                                                        l.flowATotalMinValue = v.value * conversion;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                    if (v.channelletter == 'B') {

                        let conversion = 1; //in what everthe unit of reading is
                        let flowConversion = 1; // always l/s
                        let unitsB = ""
                        let unitsBT = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            unitsB = l.flowBUnit
                            unitsBT = l.flowBTotalUnit
                            if (!companyDefault) {
                                flowConversion = flowConverter(l.flowBUnit)
                            } else {
                                flowConversion = flowConverter(l.flowBUnit)
                            }
                            if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = totalConverter(l.flowBTotalUnit, v.units)
                                    }
                                }
                            } else {
                                conversion = totalConverter(l.flowBTotalUnit, v.units)
                            }
                        }


                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = (value != -32768) ? value * conversion : null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'flowRateB'] = v.flowRate * flowConversion;
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;
                        rec['unitB '] = unitsB
                        rec['unitBT'] = unitsBT


                        graphDataTemp.push(rec);

                        //limits

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.flowRate && v.flowRate * flowConversion > l.flowBOriginalMax) {
                                    l.flowBOriginalMax = v.flowRate * flowConversion    
                                }
                                if (v.flowRate && v.flowRate * flowConversion < l.flowBOriginalMin) {
                                    l.flowBOriginalMin = v.flowRate * flowConversion
                                }
                                if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowBMinState === 'default' || l.flowBMaxState === 'default') {
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        if (v.flowRate != null) {
                                            

                                            if (l.flowBMaxState === 'default') {
                                                if (v.flowRate * flowConversion > l.flowBMaxValue) {
                                                    l.flowBMaxValue = v.flowRate * flowConversion;
                                                }
                                            }
                                            if (l.flowBMinState === 'default') {
                                                if (v.flowRate * flowConversion < l.flowBMinValue) {
                                                    l.flowBMinValue = v.flowRate * flowConversion;
                                                }

                                            }
                                        }
                                    }
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        loggersUnitsAndValues[i].channelsAvail |= 0x02
                                    }
                                }
                                ///totals
                                if (loggersUnitsAndValues[i].isAshridgeDefault || l.flowBTotalMinState === 'default' || l.flowBTotalMaxState === 'default') {
                                    if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                        if (v.value != null) {
                                            if (l.flowBTotalMaxState === 'default') {
                                                if (v.value > l.flowBTotalMaxValue) {
                                                    l.flowBTotalMaxValue = v.value * conversion;
                                                }
                                            }
                                            if (l.flowBTotalMinState === 'default') {
                                                if (v.value < l.flowBTotalMinValue) {
                                                    l.flowBTotalMinValue = v.value * conversion;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (v.channelletter == 'C') {

                        let conversion = 1;

                        let unitsC = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            unitsC = l.pressureCUnit
                            l.pressureCOriginalUnit = v.units
                            if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = pressureConverter(l.pressureCUnit, v.units)
                                    }
                                }
                            } else {
                                if (v.units) {
                                    conversion = pressureConverter(l.pressureCUnit, v.units)
                                }
                            }
                            if (conversion === null) {
                                conversion = 1
                                unitsC = v.units
                                l.pressureCIsNotPressure = true
                            }
                        }


                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = (value != -32768) ? value * conversion : null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = (v.minValue != -32768) ? v.minValue * conversion : null;
                        rec[prefix + 'readingCmax'] = (v.maxValue != -32768) ? v.maxValue * conversion : null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;

                        rec['unitC '] = unitsC


                        graphDataTemp.push(rec);

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureCOriginalMax) {
                                    l.pressureCOriginalMax = v.value * conversion
                                }
                                if (v.value && v.value * conversion < l.pressureCOriginalMin) {
                                    l.pressureCOriginalMin = v.value * conversion
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                    loggersUnitsAndValues[i].channelsAvail |= 0x04

                                    let inputValue = value
                                    if (l.pressureCValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureCValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureCMinState === 'default' || l.pressureCMaxState === 'default') {
                                        if (inputValue != null) {
                                            if (inputValue * conversion > l.pressureCMaxValue) {
                                                l.pressureCMaxValue = (inputValue * conversion)
                                            }
                                            if (inputValue * conversion < l.pressureCMinValue) {
                                                l.pressureCMinValue = (inputValue * conversion)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (v.channelletter == 'D') {

                        let conversion = 1;

                        let unitsD = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            l.pressureDOriginalUnit = v.units
                            unitsD = l.pressureDUnit
                            if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = pressureConverter(l.pressureDUnit, v.units)
                                    }
                                }
                            } else {
                                if (v.units) {
                                    conversion = pressureConverter(l.pressureDUnit, v.units)
                                }
                            }
                            if (conversion === null) {
                                conversion = 1
                                unitsD = v.units
                                l.pressureDIsNotPressure = true
                            }
                        }


                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = (value != -32768) ? value * conversion : null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = (v.minValue != -32768) ? v.minValue * conversion : null;
                        rec[prefix + 'readingDmax'] = (v.maxValue != -32768) ? v.maxValue * conversion : null;
                        rec[prefix + 'readingEmin'] = null;
                        rec[prefix + 'readingEmax'] = null;

                        rec['unitD '] = unitsD


                        graphDataTemp.push(rec);

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureDOriginalMax) {
                                    l.pressureDOriginalMax = v.value * conversion
                                }
                                if (v.value && v.value * conversion < l.pressureDOriginalMin) {
                                    l.pressureDOriginalMin = v.value * conversion
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                    loggersUnitsAndValues[i].channelsAvail |= 0x08
                                    let inputValue = value
                                    if (l.pressureDValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureDValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureDMinState === 'default' || l.pressureDMaxState === 'default') {
                                        if (inputValue != null) {
                                            if (inputValue * conversion > l.pressureDMaxValue) {
                                                l.pressureDMaxValue = (inputValue * conversion)
                                            }
                                            if (inputValue * conversion < l.pressureDMinValue) {
                                                l.pressureDMinValue = (inputValue * conversion)
                                            }

                                        }


                                    }


                                }
                            }
                        }

                    }
                    if (v.channelletter == 'E') {

                        let conversion = 1;

                        let unitsE = ""
                        for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                            const l = loggersUnitsAndValues[i].graphSettings
                            l.pressureEOriginalUnit = v.units
                            unitsE = l.pressureEUnit
                            if (!companyDefault) {
                                if (parseInt(prefix) === loggersUnitsAndValues[i].serial) {
                                    if (v.units) {
                                        conversion = pressureConverter(l.pressureEUnit, v.units)
                                    }
                                }
                            } else {
                                if (v.units) {
                                    conversion = pressureConverter(l.pressureEUnit, v.units)
                                }
                            }
                            if (conversion === null) {
                                conversion = 1
                                unitsE = v.units
                                l.pressureEIsNotPressure = true
                            }
                        }


                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = (value != -32768) ? value * conversion : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = null;
                        rec[prefix + 'readingCmax'] = null;
                        rec[prefix + 'readingDmin'] = null;
                        rec[prefix + 'readingDmax'] = null;
                        rec[prefix + 'readingEmin'] = (v.minValue != -32768) ? v.minValue * conversion : null;
                        rec[prefix + 'readingEmax'] = (v.maxValue != -32768) ? v.maxValue * conversion : null;

                        rec['unitE '] = unitsE


                        graphDataTemp.push(rec);

                        if (value !== -32768) {
                            // Loop over loggersUnitsAndValues to find matching logger serials
                            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                                const l = loggersUnitsAndValues[i].graphSettings
                                if (v.value && v.value * conversion > l.pressureEOriginalMax) {
                                    l.pressureEOriginalMax = v.value * conversion
                                }
                                if (v.value && v.value * conversion < l.pressureEOriginalMin) {
                                    l.pressureEOriginalMin = v.value * conversion
                                }
                                if (parseInt(v.loggerSerial) === loggersUnitsAndValues[i].serial) {
                                    loggersUnitsAndValues[i].channelsAvail |= 0x10
                                    let inputValue = value
                                    if (l.pressureEValueAs === 'max') {
                                        inputValue = v.maxValue * conversion
                                    }
                                    if (l.pressureEValueAs === 'min') {
                                        inputValue = v.minValue * conversion
                                    }
                                    if (loggersUnitsAndValues[i].isAshridgeDefault || l.pressureEMinState === 'default' || l.pressureEMaxState === 'default') {
                                        if (inputValue != null) {
                                            if (inputValue * conversion > l.pressureEMaxValue) {
                                                l.pressureEMaxValue = (inputValue * conversion)
                                            }
                                            if (inputValue * conversion < l.pressureEMinValue) {
                                                l.pressureEMinValue = (inputValue * conversion)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            
            
        })
        

            



            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                
                const l = loggersUnitsAndValues[i].graphSettings

                if (l.flowAMinValue == Number.MAX_VALUE) {
                    l.flowAMinValue = l.flowAMaxValue;
                }
                if (l.flowBMinValue == Number.MAX_VALUE) {
                    l.flowBMinValue = l.flowBMaxValue;
                }
                if (l.pressureCMinValue == Number.MAX_VALUE) {
                    l.pressureCMinValue = l.pressureCMaxValue;
                }
                if (l.pressureDMinValue == Number.MAX_VALUE) {
                    l.pressureDMinValue = l.pressureDMaxValue;
                }
                if (l.pressureEMinValue == Number.MAX_VALUE) {
                    l.pressurEMinValue = l.pressureEMaxValue;
                }

                if (l.pressureCMinState === 'default' && l.pressureDMinState === 'default' && l.pressureEMinState == 'default') {
                    const minPressure = Math.min(l.pressureCMinValue, l.pressureDMinValue, l.pressureEMinValue)
                    l.pressureCMinValue = minPressure
                    l.pressureDMinValue = minPressure
                    l.pressureEMinValue = minPressure
                }
                if (l.pressureCMaxState === 'default' && l.pressureDMaxState === 'default' && l.pressureEMaxState == 'default') {
                    const maxPressure = Math.max(l.pressureCMaxValue, l.pressureDMaxValue, l.pressureEMaxValue)
                    l.pressureCMaxValue = maxPressure
                    l.pressureDMaxValue = maxPressure
                    l.pressureEMaxValue = maxPressure
                }
            }

            
            for (let i = 0; i < loggersUnitsAndValues.length; i++) {
                
                const data = loggersUnitsAndValues[i].graphSettings
                if (data.flowATotalPerDay || data.flowBTotalPerDay) {

                    const graphDataClone = [...graphDataTemp]
                    let filterOutReadingsA = []
                    let filterStartOfDayReadingA = []
                    let filterOutReadingsB = []
                    let filterStartOfDayReadingB = []
                    if (data.flowATotalPerDay) {

                        filterStartOfDayReadingA = graphDataClone.filter(item => {
                            const hasReadingAWithNonNullValue = Object.keys(item).some(key =>
                                key.includes('readingA') && item[key] !== null
                            );
                            
                            return hasReadingAWithNonNullValue && moment(item.date).isSame(moment(item.date).startOf('day'))
                        });



                        /// get sall readings A from original graphData
                        filterOutReadingsA = graphDataTemp.filter(item => {
                            const hasReadingAWithNullValue = Object.keys(item).some(key =>
                                key.includes('readingA') && item[key] !== null
                            );

                            return hasReadingAWithNullValue
                        })
                        
                    }
                    if (data.flowBTotalPerDay) {

                        filterStartOfDayReadingB = graphDataClone.filter(item => {
                            const hasReadingBWithNonNullValue = Object.keys(item).some(key =>
                                key.includes('readingB') && item[key] !== null
                            );

                            return hasReadingBWithNonNullValue && moment(item.date).isSame(moment(item.date).startOf('day'))
                        });



                        /// get sall readings A from original graphData
                        filterOutReadingsB = graphDataTemp.filter(item => {
                            const hasReadingBWithNullValue = Object.keys(item).some(key =>
                                key.includes('readingB') && item[key] !== null
                            );

                            return hasReadingBWithNullValue
                        })

                    }


                    //recombine data
                    const graphDataTempFilter = graphDataTemp.filter(item => {
                        if (data.flowATotalPerDay && data.flowBTotalPerDay) {
                            if (!filterOutReadingsA.includes(item) && !filterOutReadingsB.includes(item)) {
                                return item
                            }
                        } else if (data.flowATotalPerDay) {
                            if (!filterOutReadingsA.includes(item)) {
                                return item
                            }
                        } else if (data.flowBTotalPerDay) {
                            if (!filterOutReadingsB.includes(item)) {
                                return item
                            }
                        }
                    })
                    filterStartOfDayReadingA.forEach(item => graphDataTempFilter.push(item))
                    filterStartOfDayReadingB.forEach(item => graphDataTempFilter.push(item))
                    graphDataTemp = graphDataTempFilter
                }
            }
            
            
            graphDataTemp.sort((a, b) => a.date.getTime() - b.date.getTime());

            let hasFlow = false
            let hasPressure = false
            let hasTotal = false

            loggersUnitsAndValues.forEach(item => {
                const i = item.graphSettings
                const channels = item.channelsAvail
                const channelA = 0x01
                const channelB = 0x02
                const channelC = 0x04
                const channelD = 0x08
                const channelE = 0x10
                console.log(item.graphSettings.flowAVisible, item.graphSettings.flowBVisible)
                if ((channels & (channelA || channelB)) && i.flowAVisible || i.flowBVisible) {
                    hasFlow = true
                }
                if (channels & (channelC || channelD || channelE)) { 
                    setHasPressureChannels(true)
                    if (i.pressureCVisible || i.pressureDVisible || i.pressureEVisible) {
                        hasPressure = true
                    }   
                } 
                if (i.flowATotalVisible || i.flowBTotalVisible) {
                    hasTotal = true
                }
            })
              
            setShowFlow(hasFlow)
            setShowPressure(hasPressure)
            setShowTotal(hasTotal)
            setGraphData(graphDataTemp)
            setReadingsData(readingsDataTemp)
            //units set
            setUnitsAndValues(loggersUnitsAndValues)
            

            setLoggers(loggerDataArray)
            setLoggersToTemplate(loggerDataArray[0].Serial)
        })
        
        setLoading(false)
    }



    useEffect(() => {
        if (alarms.length > 0) {
            checkAlarmAxisLevels();
        }
    }, [alarms]);

    const checkAlarmAxisLevels = (): void => {

        const alarmDetails = {
            visible: false,
            minValue: Number.MAX_VALUE,
            maxValue: Number.NEGATIVE_INFINITY,
            channel: "",
            color: '#ff0000',
            transparency: "15",
            labelSize: 12,
            message: "",
            operator: ""
        };

        if (unitsAndValues.length > 0 && alarms.length > 0) {
            const loggerUnitsAndValues = [...unitsAndValues];
            loggerUnitsAndValues.forEach(values => values.alarms = [])
            const filteredLoggerSettings = loggerUnitsAndValues.filter(item =>
                alarms.some(alarm => alarm.serial === item.serial)
            );

            filteredLoggerSettings.forEach((loggerSetting) => {
                alarms.forEach((alarm: Alarm) => {
                    if (alarm.serial === loggerSetting.serial) {  
                        const alarmDetailsCopy = { ...alarmDetails };  // fresh copy for each loop
                        const a = alarmDetailsCopy;
                        const l = loggerSetting.graphSettings;
                        const letters = ['A', 'B', 'C', 'D', 'E']
                        for (const letter of letters) {
                            let input = ""
                            switch (letter) {
                                case 'A':
                                    input = 'flowA'
                                    break;
                                case 'B':
                                    input = 'flowB'
                                    break;
                                case 'C':
                                    input = 'pressureC'
                                    break;
                                case 'D':
                                    input = 'pressureD'
                                    break;
                                case 'E':
                                    input = 'pressureE'
                                    break;

                            }
                            if (alarm.channel === letter) {
                                a.channel = letter
                                a.message = alarm.message
                                let conversion = 1
                                if (letter === 'A' || letter === 'B') {
                                    conversion = flowConverter(l[`${input}Unit`])
                                } else {
                                    conversion = pressureConverter(l[`${input}Unit`], l[`${input}OriginalUnit`])
                                }
                                if (alarm.setOperator === enumChariotAlarmOperator.GreaterThan ||
                                    alarm.setOperator === enumChariotAlarmOperator.GreaterThanOrEqual) {
                                        a.operator = ">"
        
                                    if (alarm.setLevel * conversion > a.maxValue) {
                                        a.maxValue = alarm.setLevel * conversion;
                                        if (loggerSetting.isAshridgeDefault) {
                                            l[`${input}MaxValue`] = Math.max(a.maxValue, l[`${input}MaxValue`]);
                                        }
                                        
                                        
                                    }

                                    if (alarm.clrLevel * conversion < a.minValue) {
                                        a.minValue = alarm.clrLevel * conversion;
                                        if (loggerSetting.isAshridgeDefault) {
                                            l[`${input}MinValue`] = Math.min(a.minValue, l[`${input}MinValue`]);
                                        }
                                    }
                                    if (alarm.setLevel * conversion < loggerSetting.graphSettings[`${input}OriginalMax`]) {
                                        a.visible = true
                                    }
                                } else if (alarm.setOperator === enumChariotAlarmOperator.LessThan ||
                                    alarm.setOperator === enumChariotAlarmOperator.LessThanOrEqual) {
                                        a.operator = "<"

                                    if (alarm.setLevel * conversion < a.minValue) {
                                        a.minValue = alarm.setLevel * conversion;
                                        if (loggerSetting.isAshridgeDefault) {
                                            l[`${input}MinValue`] = Math.min(a.minValue, l[`${input}MinValue`]);
                                        }
                                        
                                    }

                                    if (alarm.clrLevel * conversion > a.maxValue) {
                                        a.maxValue = alarm.clrLevel * conversion;
                                        if (loggerSetting.isAshridgeDefault) {
                                            l[`${input}MaxValue`] = Math.max(a.maxValue, l[`${input}MaxValue`]);
                                        }
                                    }
                                    if (alarm.setLevel * conversion > loggerSetting.graphSettings[`${input}OriginalMin`]) {
                                        a.visible = true
                                    }
                                }
                            }
                        }
                        loggerSetting.alarms.push(alarmDetailsCopy);  // add only if not a duplicate
                    }
                });
            });
            
            loggerUnitsAndValues.forEach(units => {
                filteredLoggerSettings.forEach(setting => {
                    if (units.serial === setting.serial) {
                        units = setting
                    }
                })
            })
            setUnitsAndValues(loggerUnitsAndValues)
        }
    };

    console.log(alarms)

    const flowConverter = (unit: string): number => {
        let flowConversion = 1
        if (unit === 'l/s') {
            flowConversion = 1
        } else if (unit === 'm3/h') {
            flowConversion = 3.6
        } else if (unit === "gpm") {
            flowConversion = 15.8503
        } else if (unit === 'MGD') {
            flowConversion = 0.022824
        } else if (unit === 'gps') {
            flowConversion = 0.264172
        } else if (unit === 'cfs') {
            flowConversion = 0.0353147
        }
        return flowConversion
    }

    const totalConverter = (userUnit: string, dbUnit: string): number => {
        let conversion = 1
        if (userUnit === 'l') {
            if (dbUnit === 'm3') {
                conversion = 1000
            } else if (dbUnit === 'gal') {
                conversion = 3.78541
            } else if (dbUnit === 'ft3') {
                conversion = 28.3168
            }
        } else if (userUnit === 'm3') {
            if (dbUnit === 'l') {
                conversion = 1 / 1000
            } else if (dbUnit === 'gal') {
                conversion = 0.00378541
            } else if (dbUnit === 'ft3') {
                conversion = 0.0283168
            }
        } else if (userUnit === 'gal') {
            if (dbUnit === 'l') {
                conversion = 0.264172
            } else if (dbUnit === 'm3') {
                conversion = 264.172
            } else if (dbUnit === 'ft3') {
                conversion = 7.48052
            }
        } else if (userUnit === 'ft3') {
            if (dbUnit === 'l') {
                conversion = 0.0353147
            } else if (dbUnit === 'm3') {
                conversion = 35.3147
            } else if (dbUnit === 'gal') {
                conversion = 0.133681
            }
        }
        return conversion
    }

const pressureConverter = (userUnit, dbUnit): number | null => {
    let conversion: number | null = 1
    if (userUnit === 'bar') {
        if (dbUnit === 'mb') {
            conversion = 1 / 1000
        } else if (dbUnit === 'mH2O' || dbUnit === 'mwg') {
            conversion = 0.0980665
        } else if (dbUnit === 'psi') {
            conversion = 0.0689476
        }
    } else if (userUnit === 'mb') {
        if (dbUnit === 'bar') {
            conversion = 1000
        } else if (dbUnit === 'mH2O' || dbUnit === 'mwg') {
            conversion = 98.0665
        } else if (dbUnit === 'psi') {
            conversion = 68.9476
        }

    } else if (userUnit === "mwg" || userUnit === "mH2O") {
        if (dbUnit === 'bar') {
            conversion = 10.1972
        } else if (dbUnit === 'mbar') {
            conversion = 0.0101972
        } else if (dbUnit === 'psi') {
            conversion = 0.70307
        }
    } else if (userUnit === 'psi') {
        if (dbUnit === 'bar') {
            conversion = 14.5038
        } else if (dbUnit === 'mH2O' || dbUnit === 'mwg') {
            conversion = 0.4335
        } else if (dbUnit === 'mb') {
            conversion = 0.0145038
        }

    } else {
        conversion = null
    }
    return conversion
    }




    const loadLCAlarmLevels = (serial: number): void => {
        
        
        CallGetAPI(CreateUrl('/api/aquaguard/LCAlarmConfigurations?serial=' + serial), {})
            .then(data => {
                if (data.length > 0) {
                    const alarmsTemp: Array<Alarm> = [...alarms];
                    for (let i = 0; i < data.length; i++) {
                        let channel = "";
                        let setLevel = 0;
                        let clrLevel = 0;
                        if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                            channel = "A";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.FlowB && data[i].clrInput == enumChariotAlarmInput.FlowB) {
                            channel = "B";
                            setLevel = data[i].setThreshold /** this.flowFactor*/;
                            clrLevel = data[i].clrThreshold /** this.flowFactor*/;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                            channel = "C";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                            channel = "D";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (channel != "") {
                            alarmsTemp.push({
                                id: data[i].id,
                                serial: serial,
                                site: undefined,
                                channel: channel,
                                message: data[i].alarmMessage,
                                setLevel: setLevel,
                                clrLevel: clrLevel,
                                setOperator: data[i].setOperator,
                            });
                        }


                    }
                    setAlarms(alarmsTemp)
                }
                

            })
            .catch(function (error) {
                console.log(error);
                
            });

    }


    const loadLoggerAlarmLevels = (serial: number): void =>  {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + serial + "&startDate=" + moment(startDate).format("yyyy-MM-DD") + "&endDate=" + moment(endDate).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(data => {
            if (data.length > 0) {
                const alarmsTemp = [...alarms];
                const channels: Array<string> = data[0].channels.split(',');
                for (let i = 0; i < channels.length; i++) {
                    let channel = "";
                    let setLevel = 0;
                    let clrLevel = 0;
                    if (channels[i].startsWith("A:") && channels[i].length > 2) {
                        channel = "A";
                        clrLevel = data[0].channelAClearLevel 
                    }
                    if (channels[i].startsWith("B:") && channels[i].length > 2) {
                        channel = "B";
                        setLevel = data[0].channelBSetLevel 
                        clrLevel = data[0].channelBClearLevel 
                    }
                    if (channels[i].startsWith("C:") && channels[i].length > 2) {
                        channel = "C";
                        setLevel = data[0].channelCSetLevel;
                        clrLevel = data[0].channelCClearLevel;
                    }
                    if (channels[i].startsWith("D:") && channels[i].length > 2) {
                        channel = "D";
                        setLevel = data[0].channelDSetLevel;
                        clrLevel = data[0].channelDClearLevel;
                    }
                    if (channels[i].startsWith("E:") && channels[i].length > 2) {
                        channel = "E";
                        setLevel = data[0].channelESetLevel;
                        clrLevel = data[0].channelEClearLevel;
                    }
                    if (channel != "") {
                        alarmsTemp.push({
                            id: i,
                            serial: serial,
                            site: undefined,
                            channel: channel,
                            message: channels[i].substring(2),
                            setLevel: setLevel,
                            clrLevel: clrLevel,
                            setOperator: enumChariotAlarmOperator.GreaterThan,
                        });
                    }


                }

                setAlarms(alarmsTemp)

            }

        })
        .catch(function (error) {
            console.log(error);

        });

    }

    const loadSiteAlarmLevels = (siteId: number): void => {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarmConfigurations?siteId=' + siteId), {})
            .then(data => {

            if (data.length > 0) {
                const alarmsTemp = [...alarms];
                for (let i = 0; i < data.length; i++) {
                    let channel = "";
                    let setLevel = 0;
                    let clrLevel = 0;
                    if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                        channel = "A";
                        setLevel = data[i].setThreshold /* * this.flowFactor; */
                        clrLevel = data[i].clrThreshold /** this.flowFactor; */
                    }
                    if (data[i].setInput == enumChariotAlarmInput.FlowB && data[i].clrInput == enumChariotAlarmInput.FlowB) {
                        channel = "B";
                        setLevel = data[i].setThreshold /* * this.flowFactor; */
                        clrLevel = data[i].clrThreshold /* * this.flowFactor; */
                    }
                    if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                        channel = "C";
                        setLevel = data[i].setThreshold;
                        clrLevel = data[i].clrThreshold;
                    }
                    if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                        channel = "D";
                        setLevel = data[i].setThreshold;
                        clrLevel = data[i].clrThreshold;
                    }
                    if (channel != "") {
                        alarmsTemp.push({
                            id: data[i].id,
                            serial: undefined,
                            site: siteId,
                            channel: channel,
                            message: data[i].alarmMessage,
                            setLevel: setLevel,
                            clrLevel: clrLevel,
                            setOperator: data[i].setOperator,
                        });
                    }
                }
                setAlarms(alarmsTemp)
            }
            
        })
        .catch(function (error) {
            console.log(error);
        
        });

    }







    const onDateChange = (startDateChange: moment, endDateChange : moment, rainfall): void => {
        
        let finalDate = moment(endDateChange).toDate() >= moment().toDate() ? moment(endDateChange).toDate() : moment(endDateChange).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate()
        if (finalDate > moment().toDate()) {
        finalDate = moment().toDate()
        }
        const beginDate = moment(startDateChange).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
        setStartDate(beginDate)
        setEndDate(finalDate)
        setVisualRange({startValue: beginDate, endValue: finalDate})
        setGraphData([])
    
        
    }

    const removeLogger = (serial: number): void => {
        const loggersRemove = [ ...loggers ];
        
        const idx = loggersRemove.findIndex((l) => l.Serial == serial);
        loggersRemove.splice(idx, 1);
        
            setLoggers(loggersRemove)
    }

    useEffect(() => {
        reloadData(location.state.groupId, location.state.loggers, location.state.site, true);
    }, [startDate, endDate])


    const updateVisualRange = useCallback((e: RangeSelectorTypes.ValueChangedEvent) => {
        console.log('hit')
        setVisualRange(e.value);
        
    }, [visualRange]);

    const toggleCreateTemplateOpen = (isSelected: boolean | null, companyDefault: boolean | null): void => {

        if (companyDefault) {
            const newTemplate: GraphTemplate = deepCopyAshridgeDefault(graphTemplate)
            newTemplate.channelsAvail = 31
            setGraphTemplate(newTemplate)
            setCreateTemplateOpen(!createTemplateOpen)
        } else {
            if ((sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') && loggers.length === 1 && !isSelected) {
 
                setCreateTemplateOpen(false)
                setSelectLoggerToTemplate(true)
            }
            else if (loggers.length > 1) {
                if (!isSelected) {

                    setSelectLoggerToTemplate(!selectLoggerToTemplate)
                } else {
 
                    const templateToLoad = unitsAndValues.filter(item => item.serial === (loggersToTemplate))
                    setGraphTemplate(templateToLoad[0])
                    setCreateTemplateOpen(!createTemplateOpen)
                }
            } else {

                const templateToLoad = unitsAndValues.filter(item => item.serial === (loggersToTemplate))

                setGraphTemplate(templateToLoad[0])
                setCreateTemplateOpen(!createTemplateOpen)
            }
        }
    }

    const handleLoggerTemplateChange = (value: number): void => {
        setLoggersToTemplate(value)
    }

    const toggleCreateCompanyDefault = ():void => {
        setCreateCompanyDefault(!createCompanyDefault)
        toggleCreateTemplateOpen(false, true)
    }

    const closeGraphTemplateWindow = ():void => {
        setCreateTemplateOpen(false)
        setCreateCompanyDefault(false)
        setSelectLoggerToTemplate(false)
        setSelectedTab(0)
        
    }

    const handlePostGraphTemplate = (): void => {
        const info = graphTemplate
        const settings = JSON.stringify(info.graphSettings)
        let requestOptions = {}
        if (createCompanyDefault) {
            const confirm = window.confirm('Are you sure you want to overwrite the company default?')
            if (!confirm) return
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    serial: null,
                    siteId: null,
                    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
                    graphSettings: settings,
                    userId: null
                })
            };

        } else {
            requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    serial: parseInt(info.serial),
                    siteId: parseInt(info.siteId),
                    fkCompanyId: parseInt(sessionStorage.getItem('companyId')),
                    graphSettings: settings,
                    userId: parseInt(sessionStorage.getItem('userId'))
                })
            };
        }
        CallPostAPI(CreateUrl('/api/aquaguard/graphtemplate'), requestOptions )
            .then(data => {
                createSuccessNotification()
                closeGraphTemplateWindow()
                reloadData(location.state.groupId, location.state.loggers, location.state.site, null)
                
            })
            .catch(err => {
                console.log(err)
                createErrorNotification()
            })
    }

    
    //NOTIFICATIONS
    const createSuccessNotification = (): void => {
        NotificationManager.success('Saved Template', 'Success')

    };

    const createErrorNotification = (): void => {
        NotificationManager.error('Error Saving Changes');
    };

    const handleGraphSettingsChange = (name: string, value: any, channel: string): void => {

        const graphTemplateTemp = { ...graphTemplate };
        if (isNaN(value) && typeof (value) !==  'string') {
            value= 0
        }
        graphTemplateTemp.graphSettings[`${channel}${name}`] = value;
        
        setGraphTemplate(graphTemplateTemp);
    };

    const colors = {
        red: ["#B22222", "#8B0000", "#DC143C", "#D2691E", "#E34234", "#CD5C5C"],
        yellow: ["#B8860B", "#DAA520", "#FFD700", "#FFC125", "#F4C430", "#CD9B1D"],
        brown: ["#8B4513", "#A0522D", "#6B4423", "#8B5A2B", "#995C32", "#704214"],
        green: ["#006400", "#32CD32", "#228B22", "#00FF00", "#3CB371", "#ADFF2F"],
        blue: ["#0000CD", "#87CEEB", "#1E90FF", "#4169E1", "#4682B4", "#00BFFF"],
        orange: ["#FF6F00", "#FFCC66", "#FFA500", "#FF8500", "#FF9F1C", "#FFB84D"],
        purple: ["#800080", "#9370DB", "#8A2BE2", "#9400D3", "#DDA0DD", "#9932CC"]
    };


    const renderAlarmSeries = (items, settings: GraphTemplate) => {
        let counter = 0
        let type = ""
        const alarmSeries = []
        items.forEach(item => {
           // if (settings.isAshridgeDefault) {
                switch (item.channel) {
                    case "A":
                        item.color = colors.red[counter]
                        type = "Flow"
                        break
                    case "B":
                        item.color = colors.green[counter]
                        type = "Flow"
                        break
                    case "C":
                        item.color = colors.blue[counter]
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        break
                    case "D":
                        item.color = colors.orange[counter]
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        break
                    case "E":
                        item.color = colors.purple[counter]
                        type = window.location.hostname.includes('zonelog.net') ? "Pressure" : "Pressure / Analog"
                        break
                }
           // }
            if (item.operator === '>' && item.visible) {
                alarmSeries.push(<Strip startValue={item.maxValue} color={`${item.color}${item.transparency}`}>
                    <Label text={`${item.message} - Alarm (${type} > ${item.maxValue})`} font={{ size: item.labelSize, color: "#000000" }} ></Label>
                </Strip>)
            }
            if (item.operator === '<' && item.visible) {
                
                alarmSeries.push(<Strip endValue={item.minValue} color={`${item.color}${item.transparency}`}>
                    <Label horizontalAlignment={'right'} text={`${item.message} - Alarm (${type} < ${item.minValue})`} font={{ size: item.labelSize, color: "#000000" }} ></Label>
                </Strip>)
            }
        counter++
        })
        return alarmSeries
    }


    const renderValueAxis = (logger) => {
        let counter = 0;
        const valueAxisArray = [];
        
        unitsAndValues.map(item => {

            const hasAlarms = item.alarms.length > 0
            const i = item.graphSettings
            // flow A
            let flowAMinValueLogger = 0;
            let flowAMaxValueLogger = 0; // Default value now dynamic
            let flowAColor = '';
            let flowAVisibleLogger = false;
            let flowAAxisPosition = '';
            let flowALabelSize = 0;
            let flowAUnit = '';


            ///Total A
            let flowATotalMinValueLogger = 0;
            let flowATotalMaxValueLogger = 0; // Default value now dynamic
            let flowATotalColor = '';
            let flowATotalVisibleLogger = false;
            let flowATotalAxisPosition = '';
            let flowATotalLabelSize = 0;
            let flowATotalUnit = '';
            let flowATotal24Hrs = false

            //flow B
            let flowBMinValueLogger = 0;
            let flowBMaxValueLogger = 0; // Default value now dynamic
            let flowBColor = '';
            let flowBVisibleLogger = false;
            let flowBAxisPosition = '';
            let flowBLabelSize = 0;
            let flowBUnit = '';

            ///Total B
            let flowBTotalMinValueLogger = 0;
            let flowBTotalMaxValueLogger = 0; // Default value now dynamic
            let flowBTotalColor = '';
            let flowBTotalVisibleLogger = false;
            let flowBTotalAxisPosition = '';
            let flowBTotalLabelSize = 0;
            let flowBTotalUnit = '';
            let flowBTotal24Hrs = false

            //pressure C
            let pressureCMinValueLogger = 0;
            let pressureCMaxValueLogger = 0; // Default value now dynamic
            let pressureCColor = '';
            let pressureCVisibleLogger = false;
            let pressureCAxisPosition = '';
            let pressureCLabelSize = 0;
            let pressureCUnit = '';

            //pressure D
            let pressureDMinValueLogger = 0;
            let pressureDMaxValueLogger = 0; // Default value now dynamic
            let pressureDColor = '';
            let pressureDVisibleLogger = false;
            let pressureDAxisPosition = '';
            let pressureDLabelSize = 0;
            let pressureDUnit = '';

            //pressure E
            let pressureEMinValueLogger = 0;
            let pressureEMaxValueLogger = 0; // Default value now dynamic
            let pressureEColor = '';
            let pressureEVisibleLogger = false;
            let pressureEAxisPosition = '';
            let pressureELabelSize = 0;
            let pressureEUnit = '';
            // Match logger with item.serial and get graph settings
            if (item.serial === logger.Serial) {

                //flow A
                flowAMinValueLogger = i.flowAMinValue;
                flowAMaxValueLogger = i.flowAMaxValue; 
                flowAVisibleLogger = i.flowAVisible;
                flowAAxisPosition = i.flowAAxisPosition;
                flowALabelSize = i.flowALabelSize;
                flowAUnit = i.flowAUnit;

                //Total A
                flowATotalMinValueLogger = i.flowATotalMinValue;
                flowATotalMaxValueLogger = i.flowATotalMaxValue;
                flowATotalVisibleLogger = i.flowATotalVisible;
                flowATotalAxisPosition = i.flowATotalAxisPosition;
                flowATotalLabelSize = i.flowATotalLabelSize;
                flowATotalUnit = i.flowATotalUnit;
                flowATotal24Hrs = i.flowATotalPerDay

                //flow B
                flowBMinValueLogger = i.flowBMinValue;
                flowBMaxValueLogger = i.flowBMaxValue; 
                flowBVisibleLogger = i.flowBVisible;
                flowBAxisPosition = i.flowBAxisPosition;
                flowBLabelSize = i.flowBLabelSize;
                flowBUnit = i.flowBUnit;

                //Total B
                flowBTotalMinValueLogger = i.flowBTotalMinValue;
                flowBTotalMaxValueLogger = i.flowBTotalMaxValue;
                flowBTotalVisibleLogger = i.flowBTotalVisible;
                flowBTotalAxisPosition = i.flowBTotalAxisPosition;
                flowBTotalLabelSize = i.flowBTotalLabelSize;
                flowBTotalUnit = i.flowBTotalUnit;
                flowBTotal24Hrs = i.flowBTotalPerDay

                // pressure C
                pressureCMinValueLogger = i.pressureCMinValue
                pressureCMaxValueLogger = i.pressureCMaxValue
                pressureCVisibleLogger = i.pressureCVisible
                pressureCAxisPosition = i.pressureCAxisPosition
                pressureCLabelSize = i.pressureCLabelSize
                pressureCUnit = i.pressureCUnit

                // pressure D
                pressureDMinValueLogger = i.pressureDMinValue
                pressureDMaxValueLogger = i.pressureDMaxValue
                pressureDVisibleLogger = i.pressureDVisible
                pressureDAxisPosition = i.pressureDAxisPosition
                pressureDLabelSize = i.pressureDLabelSize
                pressureDUnit = i.pressureDUnit

                // pressure E
                pressureEMinValueLogger = i.pressureEMinValue
                pressureEMaxValueLogger = i.pressureEMaxValue
                pressureEVisibleLogger = i.pressureEVisible
                pressureEAxisPosition = i.pressureEAxisPosition
                pressureELabelSize = i.pressureELabelSize
                pressureEUnit = i.pressureEUnit
            }

            // Handle default and non-default values for color
            if (!item.isAshridgeDefault) {
                flowAColor = i.flowAColor;
                flowATotalColor = i.flowATotalColor;
                flowBColor = i.flowBColor;
                flowBTotalColor = i.flowBTotalColor;
                pressureCColor = i.pressureCColor
                pressureDColor = i.pressureDColor
                pressureEColor = i.pressureEColor
            } else {
                flowAColor = colors.red[counter];
                flowATotalColor = colors.yellow[counter];
                flowBColor = colors.green[counter];
                flowBTotalColor = colors.brown[counter];
                pressureCColor = colors.blue[counter]
                pressureDColor = colors.orange[counter]
                pressureEColor = colors.purple[counter]
                counter === 5 ? counter = 0 : counter++;
            }
            // Only render the ValueAxis if it's visible
            if (flowAVisibleLogger && item.channelsAvail & 0x01) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`A ${logger.Serial}`}
                        visualRange={{ startValue: flowAMinValueLogger, endValue: flowAMaxValueLogger }}
                        allowDecimals={true}
                        title={{ text: flowAMinValueLogger === Number.NEGATIVE_INFINITY && flowAMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelARef} - NO DATA (Flow 1)` : `${logger.Serial} ${logger.ChannelARef} in ${flowAUnit} (Flow 1)`, font: { color: flowAColor, size: flowALabelSize } }}
                        position={flowAAxisPosition}
                        color={flowAColor}
                        key={`A-${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: flowAColor }} />
                        {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'A'), item)}
                    </ValueAxis>
                );
            }
            if (flowATotalVisibleLogger && item.channelsAvail & 0x01) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`AT-Total ${logger.Serial}`}
                        visualRange={{ startValue: flowATotalMinValueLogger, endValue: flowATotalMaxValueLogger}}
                        allowDecimals={true}
                        title={{ text: flowATotalMinValueLogger === Number.NEGATIVE_INFINITY && flowATotalMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelARef} Total - NO DATA (Totaliser Pulse 1)` : `${logger.Serial} ${logger.ChannelARef} in ${flowATotalUnit} ${flowATotal24Hrs ? '-24hr Total' : ''} (Totaliser Pulse 1)`, font: { color: flowATotalColor, size: flowATotalLabelSize } }}
                        position={flowATotalAxisPosition}
                        color={flowATotalColor}
                        key={`AT-Total ${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: flowATotalColor }} />
                    </ValueAxis>
                );
            }
            if (flowBVisibleLogger && item.channelsAvail & 0x02) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`B ${logger.Serial}`}
                        visualRange={{ startValue: flowBMinValueLogger, endValue: flowBMaxValueLogger}}
                        allowDecimals={true}
                        title={{ text: flowBMinValueLogger === Number.NEGATIVE_INFINITY && flowBMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelBRef} - NO DATA (Flow 2)` : `${logger.Serial} ${logger.ChannelBRef} in ${flowBUnit} (Flow 2)`, font: { color: flowBColor, size: flowBLabelSize } }}
                        position={flowBAxisPosition}
                        color={flowBColor}
                        key={`B-${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: flowBColor }} />
                        {hasAlarms &&  alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'B'), item)}
                    </ValueAxis>
                );
            }
            if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`BT-Total ${logger.Serial}`}
                        visualRange={{ startValue: flowBTotalMinValueLogger, endValue: flowBTotalMaxValueLogger}}
                        allowDecimals={true}
                        title={{ text: flowBTotalMinValueLogger === Number.NEGATIVE_INFINITY && flowBTotalMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelBRef} Total ${flowBTotalUnit} - NO DATA (Totaliser Pulse 2)` : `${logger.Serial} ${logger.ChannelBRef} in ${flowBTotalUnit} ${flowBTotal24Hrs ? '-24hr Total (Totaliser Pulse 2)' : ''}`, font: { color: flowBTotalColor, size: flowBTotalLabelSize } }}
                        position={flowBTotalAxisPosition}
                        color={flowBTotalColor}
                        key={`BT-Total ${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: flowBTotalColor }} />
                    </ValueAxis>
                );
            }
            if (pressureCVisibleLogger && item.channelsAvail & 0x04) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`C ${logger.Serial}`}
                        visualRange={{ startValue: pressureCMinValueLogger, endValue: pressureCMaxValueLogger}}
                        allowDecimals={true}
                        title={{
                            text: pressureCMinValueLogger === Number.NEGATIVE_INFINITY && pressureCMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelCRef} - NO DATA (Pressure 1)` : `${logger.Serial} ${logger.ChannelCRef} in ${pressureCUnit} (Pressure 1)`, font: { color: pressureCColor, size: pressureCLabelSize } }}
                        position={pressureCAxisPosition}
                        color={pressureCColor}
                        key={`C-${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: pressureCColor }} />
                        {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'C'), item)}
                    </ValueAxis>
                );
            }
            if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`D ${logger.Serial}`}
                        visualRange={{ startValue: pressureDMinValueLogger, endValue: pressureDMaxValueLogger}}
                        allowDecimals={true}
                        title={{
                            text: pressureDMinValueLogger === Number.NEGATIVE_INFINITY && pressureDMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelDRef} - NO DATA (Pressure 2)` : `${logger.Serial} ${logger.ChannelDRef} in ${pressureDUnit} (Pressure 2)`, font: { color: pressureDColor, size: pressureDLabelSize }
                        }}
                        position={pressureDAxisPosition}
                        color={pressureDColor}
                        key={`D-${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: pressureDColor }} />
                        {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'D'), item)}
                    </ValueAxis>
                );
            }
            if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
                valueAxisArray.push(
                    <ValueAxis
                        width={2}
                        name={`E ${logger.Serial}`}
                        visualRange={{ startValue: pressureEMinValueLogger, endValue: pressureEMaxValueLogger}}
                        allowDecimals={true}
                        title={{
                            text: pressureEMinValueLogger === Number.NEGATIVE_INFINITY && pressureEMaxValueLogger === Number.NEGATIVE_INFINITY ? `${logger.Serial} ${logger.ChannelERef} - NO DATA (Pressure 3)` : `${logger.Serial} ${logger.ChannelERef} in ${pressureEUnit} (Pressure 3)`, font: { color: pressureEColor, size: pressureELabelSize }
                        }}
                        position={pressureEAxisPosition}
                        color={pressureEColor}
                        key={`E-${logger.Serial}`} // Unique key for each axis
                    >
                        <Label font={{ color: pressureEColor }} />
                        {hasAlarms && alarms.length > 0 && renderAlarmSeries(item.alarms.filter(alarm => alarm.channel === 'E'), item)}
                    </ValueAxis>
                );
            }
        });

        return valueAxisArray;
    };


            

    const renderLoggerSeries = (logger: APILoggerDetail): unknown => {
        let counter = 0
        const loggerSeries = []
        
        unitsAndValues.map(item => {
            const i = item.graphSettings
            //flow A
            let flowAVisibleLogger = false
            let flowAGraphType = ""
            let flowAColor = ''
            let flowALineWidth = 0

            //Total A
            let flowATotalVisibleLogger = false
            let flowATotalGraphType = ""
            let flowATotalColor = ''
            let flowATotalLineWidth = 0


            //flow B
            let flowBVisibleLogger = false
            let flowBGraphType = ""
            let flowBColor = ''
            let flowBLineWidth = 0

            //Total B
            let flowBTotalVisibleLogger = false
            let flowBTotalGraphType = ""
            let flowBTotalColor = ''
            let flowBTotalLineWidth = 0

            //pressure C
            let pressureCVisibleLogger = false
            let pressureCGraphType = ""
            let pressureCColor = ''
            let pressureCLineWidth = 0
            let pressureCValueField = `${logger.Serial}readingC`

            //pressure D
            let pressureDVisibleLogger = false
            let pressureDGraphType = ""
            let pressureDColor = ''
            let pressureDLineWidth = 0
            let pressureDValueField = `${logger.Serial}readingD`

            //pressure E
            let pressureEVisibleLogger = false
            let pressureEGraphType = ""
            let pressureEColor = ''
            let pressureELineWidth = 0
            let pressureEValueField = `${logger.Serial}readingE`

            if (item.serial === logger.Serial) {
                //flow A
                flowAVisibleLogger = i.flowAVisible
                flowAGraphType = i.flowAGraphType
                flowALineWidth = i.flowALineWidth

                ///Total A
                flowATotalVisibleLogger = i.flowATotalVisible
                flowATotalGraphType = i.flowATotalGraphType
                flowATotalLineWidth = i.flowATotalLineWidth
                //flow B
                flowBVisibleLogger = i.flowBVisible
                flowBGraphType = i.flowBGraphType
                flowBLineWidth = i.flowBLineWidth
                ///Total B
                flowBTotalVisibleLogger = i.flowBTotalVisible
                flowBTotalGraphType = i.flowBTotalGraphType
                flowBTotalLineWidth = i.flowBTotalLineWidth
                //pressure C
                pressureCVisibleLogger = i.pressureCVisible
                pressureCGraphType = i.pressureCGraphType
                pressureCLineWidth = i.pressureCLineWidth
                //pressure D
                pressureDVisibleLogger = i.pressureDVisible
                pressureDGraphType = i.pressureDGraphType
                pressureDLineWidth = i.pressureDLineWidth
                //pressure E
                pressureEVisibleLogger = i.pressureEVisible
                pressureEGraphType = item.graphSettings.pressureEGraphType
                pressureELineWidth = item.graphSettings.pressureELineWidth
            }
            if (i.pressureCValueAs === 'max') {
                pressureCValueField = `${logger.Serial}readingCmax`
            }
            if (i.pressureCValueAs === 'min') {
                pressureCValueField = `${logger.Serial}readingCmin`
            }
            if (i.pressureDValueAs === 'max') {
                pressureDValueField = `${logger.Serial}readingDmax`
            }
            if (i.pressureDValueAs === 'min') {
                pressureDValueField = `${logger.Serial}readingDmin`
            }
            if (i.pressureEValueAs === 'max') {
                pressureEValueField = `${logger.Serial}readingEmax`
            }
            if (i.pressureEValueAs === 'min') {
                pressureEValueField = `${logger.Serial}readingEmin`
            }

            if (item.isAshridgeDefault) {
                flowAColor = colors.red[counter]
                flowATotalColor = colors.yellow[counter]
                flowBTotalColor = colors.brown[counter]
                flowBColor = colors.green[counter]
                pressureCColor = colors.blue[counter]
                pressureDColor = colors.orange[counter]
                pressureEColor = colors.purple[counter]
                counter === 5 ? counter = 0 : counter++;
            } else {
                flowAColor = i.flowAColor
                flowATotalColor = i.flowATotalColor
                flowBColor = i.flowBColor
                flowBTotalColor = i.flowBTotalColor
                pressureCColor = i.pressureCColor
                pressureDColor = i.pressureDColor
                pressureEColor = i.pressureEColor
            }
            
        
       
            if (flowAVisibleLogger && item.channelsAvail & 0x01) {
            loggerSeries.push(<Series
                color={flowAColor}
                key={`${logger.Serial}-A`}
                type={flowAGraphType}
                axis={`A ${logger.Serial}`}
                valueField={`${logger.Serial}flowRateA`}
                name={`A ${logger.Serial}`}
                point={{ visible: false }} 
                width = { flowALineWidth }
                barWidth = { flowALineWidth } />)
            }
            if (flowATotalVisibleLogger && item.channelsAvail & 0x01) {
                loggerSeries.push(<Series
                    color={flowATotalColor}
                    key={`${logger.Serial}-A-Total`}
                    type={flowATotalGraphType}
                    axis={`AT-Total ${logger.Serial}`}
                    valueField={`${logger.Serial}readingA`}
                    name={`AT-Total ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowATotalLineWidth}
                    barWidth={flowATotalLineWidth} />)
            }
            if (flowBVisibleLogger && item.channelsAvail & 0x02) {
            loggerSeries.push(<Series
                color={flowBColor}
                key={`${logger.Serial}-B`}
                type={flowBGraphType}
                axis={`B ${logger.Serial}`}
                valueField={`${logger.Serial}flowRateB`}
                name={`B ${logger.Serial}`}
                point={{ visible: false }} 
                width = { flowBLineWidth }
                barWidth = { flowBLineWidth } />)
            }
            if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
                loggerSeries.push(<Series
                    color={flowBTotalColor}
                    key={`${logger.Serial}-B-Total`}
                    type={flowBTotalGraphType}
                    axis={`BT-Total ${logger.Serial}`}
                    valueField={`${logger.Serial}readingB`}
                    name={`BT-Total ${logger.Serial}`}
                    point={{ visible: false }}
                    width={flowBTotalLineWidth}
                    barWidth={flowBTotalLineWidth} />)
            }
            if (pressureCVisibleLogger && item.channelsAvail & 0x04) {
            loggerSeries.push(<Series
                color={pressureCColor}
                key={`${logger.Serial}-C`}
                type={pressureCGraphType}
                axis={`C ${logger.Serial}`}
                valueField={pressureCValueField}
                name={`C ${logger.Serial}`}
                point={{ visible: false }} 
                width={ pressureCLineWidth }
                barWidth={ pressureCLineWidth } />)
            }
            if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
                loggerSeries.push(<Series
                    color={pressureDColor}
                    key={`${logger.Serial}-D`}
                    type={pressureDGraphType}
                    axis={`D ${logger.Serial}`}
                    valueField={pressureDValueField}
                    name={`D ${logger.Serial}`}
                    point={{ visible: false }}
                    width={pressureDLineWidth}
                    barWidth={pressureDLineWidth} />)
            }
            if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
                loggerSeries.push(<Series
                    color={pressureEColor}
                    key={`${logger.Serial}-E`}
                    type={pressureEGraphType}
                    axis={`D ${logger.Serial}`}
                    valueField={pressureEValueField}
                    name={`D ${logger.Serial}`}
                    point={{ visible: false }}
                    width={pressureELineWidth}
                    barWidth={pressureELineWidth} />)
            }
        })

            return loggerSeries
    }


    const renderRangeFinderSeries = (logger) => {
        const rangeSeries = []
        let counter = 0

        

        unitsAndValues.map(item => {
            const i = item.graphSettings
            //flow A
            let flowAVisibleLogger = false
            let flowAColor = ''
            let flowAGraphType = ''

            //flow A Total
            let flowATotalVisibleLogger = false
            let flowATotalColor = ''
            let flowATotalGraphType = ''

            //flowB
            let flowBVisibleLogger = false
            let flowBColor = ''
            let flowBGraphType = ''

            //flow B Total
            let flowBTotalVisibleLogger = false
            let flowBTotalColor = ''
            let flowBTotalGraphType = ''

            //pressure C
            let pressureCVisibleLogger = false
            let pressureCColor = ''
            let pressureCGraphType = ''

            //pressure D
            let pressureDVisibleLogger = false
            let pressureDColor = ''
            let pressureDGraphType = ''

            //pressure E
            let pressureEVisibleLogger = false
            let pressureEColor = ''
            let pressureEGraphType = ''


            if (item.serial === logger.Serial) {
                // Flow A
                flowAVisibleLogger = i.flowAVisible
                flowAGraphType = i.flowAGraphType
                // Flow A Total
                flowATotalVisibleLogger = i.flowATotalVisible
                flowATotalGraphType = i.flowATotalGraphType
                //Flow B
                flowBVisibleLogger = i.flowBVisible
                flowBGraphType = i.flowBGraphType
                // Flow B Total
                flowBTotalVisibleLogger = i.flowBTotalVisible
                flowBTotalGraphType = i.flowBTotalGraphType
                //pressure C
                pressureCVisibleLogger = i.pressureCVisible
                pressureCGraphType = i.pressureCGraphType
                //pressure D
                pressureDVisibleLogger = i.pressureDVisible
                pressureDGraphType = i.pressureDGraphType

                //pressure E
                pressureEVisibleLogger = i.pressureEVisible
                pressureEGraphType = i.pressureEGraphType
            }
            if (item.isAshridgeDefault) {
                flowAColor = colors.red[counter]
                flowATotalColor = colors.yellow[counter]
                flowBColor = colors.green[counter]
                flowBTotalColor = colors.brown[counter]
                pressureCColor = colors.blue[counter]
                pressureDColor = colors.orange[counter]
                pressureEColor = colors.purple[counter]
                counter === 5 ? counter = 0 : counter++;
            } else {
                flowAColor = i.flowAColor
                flowATotalColor = i.flowATotalColor
                flowBColor = i.flowBColor
                flowBTotalColor = i.flowBTotalColor
                pressureCColor = i.pressureCColor
                pressureDColor = i.pressureDColor
                pressureEColor = i.pressureEColor
            }
            
        
        
        if (flowAVisibleLogger && item.channelsAvail & 0x01) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}flowRateA`}
                color={flowAColor}
                key={`${logger.Serial}-A`}
                type={flowAGraphType}
            />)
            }
            if (flowATotalVisibleLogger && item.channelsAvail & 0x01) {
                rangeSeries.push(<Series
                    argumentField="date"
                    valueField={`${logger.Serial}readingA`}
                    color={flowATotalColor}
                    key={`${logger.Serial}-A-Total`}
                    type={flowATotalGraphType}
                />)
            }
        if (flowBVisibleLogger && item.channelsAvail & 0x02) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}flowRateB`}
                color={flowBColor}
                key={`${logger.Serial}-B`}
                type={flowBGraphType}
            />)
            }
        if (flowBTotalVisibleLogger && item.channelsAvail & 0x02) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingB`}
                color={flowBTotalColor}
                key={`${logger.Serial}-B-Total`}
                type={flowBTotalGraphType}
            />)
            }
        if (pressureCVisibleLogger && item.channelsAvail & 0x04) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingC`}
                color={pressureCColor}
                key={`${logger.Serial}-C`}
                type={pressureCGraphType}
            />)
        }
        if (pressureDVisibleLogger && item.channelsAvail & 0x08) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingD`}
                color={pressureDColor}
                key={`${logger.Serial}-D`}
                type={pressureDGraphType}
            />)
        }
        if (pressureEVisibleLogger && item.channelsAvail & 0x10) {
            rangeSeries.push(<Series
                argumentField="date"
                valueField={`${logger.Serial}readingE`}
                color={pressureEColor}
                key={`${logger.Serial}-E`}
                type={pressureEGraphType}
            />)
            }

        })

        return rangeSeries
    }

    

    const renderTooltip = (point: any): React.ReactNode => {
        const value = point.value.toFixed(3)
        const channel = point.seriesName
        const unit = point.point.data[`unit${point.point.series.axis.slice(0, 2)}`]
        //const logger = point.point.data[]

        
        return (
            <div>
                <p>{`Channel: ${channel}`}</p>
                <p>{`Value: ${value} ${unit}`}</p>
                <p>{moment(point.argument).format("DD-MM-yyyy hh:mm:ss A")}</p>
               
            </div>
        )
    }

    


    const resetZoom = useCallback(() => {
        chartRef.current.instance().resetVisualRange();
        setVisualRange({startValue: startDate, endValue: endDate})
    }, []);

    
    const handleToggleFlow = (e, type): void => {
        const checked = e.target.checked
        const unitsAndValuesTemp = unitsAndValues

        unitsAndValuesTemp.forEach(logger => {
            const l = logger.graphSettings
            const chan = logger.channelsAvail
            if (type === 'Flow') {
                l.flowAVisible = checked
                if (chan & 0x02) {
                    l.flowBVisible = checked
                }
                setShowFlow(checked)
            } else if (type === 'Pressure') {
                if (chan & 0x04) {
                    l.pressureCVisible = checked
                } 
                if (chan & 0x08) {
                    l.pressureDVisible = checked
                }
                if (!window.location.hostname.includes('zonelog.net') && (chan & 0x10)) {
                    l.pressureEVisible = checked
                }
                setShowPressure(checked)
            } else if (type === 'Total') {
                l.flowATotalVisible = checked
                if (chan & 0x02) {
                    l.flowBTotalVisible = checked
                }
                setShowTotal(checked)
            }
        })

        setUnitsAndValues(unitsAndValuesTemp)
    }



    ///tabs
    const tabsIconandTextSingleLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",
            
        },
        {
            id: 1,
            text: window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analog Channels",
            icon: "fa fa-gauge"
        },
        {
            id: 2,
            text: "Totaliser",
            icon: "fa fa-signal"
        }/*,
        {
            id: 3,
            text: "Alarms",
            icon: "fa fa-exclamation-triangle"
        }*/
    ]

    const tabsIconandTextNoPressureSingleLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: "Totaliser",
            icon: "fa fa-signal"
        }/*,
        {
            id: 2,
            text: "Alarms",
            icon: "fa fa-exclamation-triangle"
        }*/
    ]

    const tabsIconandTextMultiLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: window.location.hostname.includes('zonelog.net') ? "Pressure" : "Analog Channels",
            icon: "fa fa-gauge"
        },
        {
            id: 2,
            text: "Totaliser",
            icon: "fa fa-signal"
        }

    ]

    const tabsIconandTextNoPressureMultiLogger = [
        {
            id: 0,
            text: "Flow",
            icon: "fa fa-water",

        },
        {
            id: 1,
            text: "Totaliser",
            icon: "fa fa-signal"
        }
    ]



    const overViewStyle = {

        borderRadius: "5px",
        padding: "10px 10px 30px 10px",

        boxShadow: "2px 2px 10px #00000060",
        marginBottom: "30px",

    }
    const flowRateUnits = [
        { name: 'l/s', value: 'l/s' },
        { name: 'm3/h', value: 'm3/h' },
        { name: 'million gal/day (MGD)', value: 'MGD' },
        { name: 'gal/day (GPD)', value: 'GPD' },
        { name: 'gal/s (gps)', value: 'gps' },
        { name: 'feet3/s (cfs)', value: 'cfs' }
    ]

    const flowUnits = [
        { name: 'l', value: 'l' },
        { name: 'm3', value: 'm3' },
        { name: 'gal', value: 'gal' },
        { name: 'ft3', value: 'ft3' }
    ]

    const pressureUnits = [
        { name: 'bar', value: 'bar' },
        { name: 'millibar', value: 'mb' },
        { name: 'mH2O', value: 'mH2O' },
        { name: 'mwg', value: 'mwg' },
        { name: 'psi', value: 'psi' }
    ]
    
    const modalParagraphStyle = { margin: "auto 0 ", color: "#000000", fontWeight: 400 }

    const settings = graphTemplate.graphSettings
   
    return (

        
        <>

            {/*switch loading back around later */ }
            {loading &&
                <div style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 13000
                }}>
                    <ClipLoader
                        size={150}
                        color={"#123abc"}
                        loading={loading}
                    />
                </div>
            }
            {!loading &&
                <div>

                    {APIFailed &&
                        <ApiFailed />
                    }
                    {!APIFailed &&
                        <div>
                            <Modal
                                show={selectLoggerToTemplate}
                                style={{
                                    width: "40%",
                                    height: "50%",
                                    //marginTop: "100px",
                                    position: 'absolute', left: '50%', top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    //zIndex: 10000
                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>Which logger would you like to create settings for?
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                        {loggers.length > 1 ?
                                            <div style={{display: "flex", justifyContent: "center"} }>
                                                <Select
                                                value={loggersToTemplate}
                                                size="small"
                                                style={{ background: "#ffffff", zIndex: 10001000, width: "214px" }}
                                                onChange={(e): void => { handleLoggerTemplateChange(e.target.value) }}
                                                >
                                                {loggers.map((logger) => {

                                                    return (<MenuItem style={{ zIndex: 11000000 }} value={logger.Serial} key={logger.LoggerId}>{logger.Serial}</MenuItem>)
                                                })}
                                                </Select>
                                            </div> :
                                            <Button variant="contained" style={{ width: "214px", margin: "auto" }} onClick={(): void => toggleCreateTemplateOpen(true, false)}>{loggers.length > 0 && loggers[0].Serial}</Button>}
                                   
                                        {(sessionStorage.getItem('userLevel') === 'useradmin' || sessionStorage.getItem('userLevel') === 'identityadmin') &&
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <div style={{display: "flex", justifyContent: "center", flexDirection: "column"} }>
                                                    <h5 style={{ margin: "0 auto" }}>or</h5>
                                                    <Button variant="contained" color="error" onClick={(): void => toggleCreateCompanyDefault()}>Create Company Default</Button>
                                                </div>
                                            </div>}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    
                                    {loggers.length > 1 && <Button variant="contained" color="primary" style={{ marginRight: "10px" }} onClick={(): void => toggleCreateTemplateOpen(true)}>Select</Button>}
                                    <Button variant="contained" color="secondary" onClick={(): void => closeGraphTemplateWindow() }>Cancel</Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={createTemplateOpen}
                                style={{
                                    //zIndex: 11,
                                    width: "100%",
                                    height: "90%",
                                    position: 'absolute',
                                    transform: 'translate(0%, 30%)',
                                   
                                    


                                }}
                            >
                                <Modal.Header>
                                    <Modal.Title>Create Graph Settings Template</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {!createCompanyDefault && <div style={{ display: "flex", justifyContent: "space-between", borderLeft: "10px solid #000000", width: "98.7%", margin: " 20px 300px 0 10px", backgroundImage: "linear-gradient(30deg, #f7f7f7, #f7f7f7 71%, #808080)", ...overViewStyle }}>
                                        <div style={{ display: "flex" }}>
                                            <h4 style={{ margin: "auto" }}>Current Selected Loggers that styling will be applied to: </h4>
                                            <div>

                                                {loggersToTemplate && <Chip style={{ margin: " 5px 5px auto 10px" }}
                                                    label={loggersToTemplate} color="primary" />
                                                }
                                            </div>
                                        </div>
                                    </div>}
                                    <div style={{ display: "flex" }}>
                                        <Tabs
                                            onItemClick={(e): void => setSelectedTab(e.itemIndex)}
                                            dataSource={loggers.length === 1 ? (graphTemplate.channelsAvail > 3 ? tabsIconandTextSingleLogger : tabsIconandTextNoPressureSingleLogger) : (graphTemplate.channelsAvail > 3 ? tabsIconandTextMultiLogger : tabsIconandTextNoPressureMultiLogger)}
                                            defaultSelectedIndex={0}
                                            style={{ fontSize: "18px" }}
                                            selectionMode={'single'}
                                            selectedIndex={selectedTab}


                                        />


                                    </div>
                                    {unitsAndValues.length > 0 && <div style={{ border: "1px solid #dddddd" }}>
                                        {selectedTab === 0 &&
                                            <div>



                                                {/*Flow A*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x01)) &&
                                                    <GraphSettingsComponent
                                                    overViewStyle={overViewStyle}
                                                    borderColor={'#009fe3'}
                                                    modalParagraphStyle={modalParagraphStyle}
                                                    handleGraphSettingsChange={handleGraphSettingsChange}
                                                    changeGroup={"flowA"}
                                                    isPressure={false}
                                                    visible={settings.flowAVisible}
                                                    unit={settings.flowAUnit}
                                                    minState={settings.flowAMinState}
                                                    minValue={settings.flowAMinValue}
                                                    maxState={settings.flowAMaxState}
                                                    maxValue={settings.flowAMaxValue}
                                                    valueAs={null}
                                                    axisPosition={settings.flowAAxisPosition}
                                                    lineWidth={settings.flowALineWidth}
                                                    graphType={settings.flowAGraphType}
                                                    color={settings.flowAColor}
                                                    labelSize={settings.flowALabelSize}
                                                    startDate={startDate}
                                                    loggers={loggers}
                                                    channel={"Flow 1"}
                                                    displayUnits={flowRateUnits}
                                                    isTotaliser={false}
                                                    totalPerDay={settings.flowATotalPerDay}
                                                    />
                                                }



                                                {/*Flow B*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x02)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#eed202'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowB"}
                                                        isPressure={false}
                                                        visible={settings.flowBVisible}
                                                        unit={settings.flowBUnit}
                                                        minState={settings.flowBMinState}
                                                        minValue={settings.flowBMinValue}
                                                        maxState={settings.flowBMaxState}
                                                        maxValue={settings.flowBMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowBAxisPosition}
                                                        lineWidth={settings.flowBLineWidth}
                                                        graphType={settings.flowBGraphType}
                                                        color={settings.flowBColor}
                                                        labelSize={settings.flowBLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Flow 2"}
                                                        displayUnits={flowRateUnits}
                                                        isTotaliser={false}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />}
                                            </div>

                                        }
                                        {!!(graphTemplate.channelsAvail > 3 ? selectedTab === 1 : selectedTab === 999 ) &&
                                            <div>
                                                {/*Analoge C*/}
                                                
                                                {(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x04)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#6f2da8'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"pressureC"}
                                                        isPressure={true}
                                                        visible={settings.pressureCVisible}
                                                        unit={settings.pressureCUnit}
                                                        minState={settings.pressureCMinState}
                                                        minValue={settings.pressureCMinValue}
                                                        maxState={settings.pressureCMaxState}
                                                        maxValue={settings.pressureCMaxValue}
                                                        valueAs={settings.pressureCValueAs}
                                                        axisPosition={settings.pressureCAxisPosition}
                                                        lineWidth={settings.pressureCLineWidth}
                                                        graphType={settings.pressureCGraphType}
                                                        color={settings.pressureCColor}
                                                        labelSize={settings.pressureCLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Pressure 1"}
                                                        displayUnits={pressureUnits}
                                                        isTotaliser={false}
                                                        totalPerDay={settings.flowATotalPerDay} 
                                                    />}


                                                {/*Analoge D*/}
                                                
                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x08)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#00ADBA'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"pressureD"}
                                                        isPressure={true}
                                                        visible={settings.pressureDVisible}
                                                        unit={settings.pressureDUnit}
                                                        minState={settings.pressureDMinState}
                                                        minValue={settings.pressureDMinValue}
                                                        maxState={settings.pressureDMaxState}
                                                        maxValue={settings.pressureDMaxValue}
                                                        valueAs={settings.pressureDValueAs}
                                                        axisPosition={settings.pressureDAxisPosition}
                                                        lineWidth={settings.pressureDLineWidth}
                                                        graphType={settings.pressureDGraphType}
                                                        color={settings.pressureDColor}
                                                        labelSize={settings.pressureDLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Pressure 2"}
                                                        displayUnits={pressureUnits}
                                                        isTotaliser={false}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />}


                                                {/*Analoge E*/}
                                                
                                                {!!((createCompanyDefault && !window.location.hostname.includes('zonelog.net')) || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x10)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#FF0000'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"pressureE"}
                                                        isPressure={true}
                                                        visible={settings.pressureEVisible}
                                                        unit={settings.pressureEUnit}
                                                        minState={settings.pressureEMinState}
                                                        minValue={settings.pressureEMinValue}
                                                        maxState={settings.pressureEMaxState}
                                                        maxValue={settings.pressureEMaxValue}
                                                        valueAs={settings.pressureEValueAs}
                                                        axisPosition={settings.pressureEAxisPosition}
                                                        lineWidth={settings.pressureELineWidth}
                                                        graphType={settings.pressureEGraphType}
                                                        color={settings.pressureEColor}
                                                        labelSize={settings.pressureELabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Pressure 3"}
                                                        displayUnits={pressureUnits}
                                                        isTotaliser={false}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />}
                                            </div>

                                        }
                                        {selectedTab === 1 && graphTemplate.channelsAvail < 3 && 
                                            <div>
                                                {/*Total A*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x01)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#009fe3'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowATotal"}
                                                        isPressure={false}
                                                        visible={settings.flowATotalVisible}
                                                        unit={settings.flowATotalUnit}
                                                        minState={settings.flowATotalMinState}
                                                        minValue={settings.flowATotalMinValue}
                                                        maxState={settings.flowATotalMaxState}
                                                        maxValue={settings.flowATotalMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowATotalAxisPosition}
                                                        lineWidth={settings.flowATotalLineWidth}
                                                        graphType={settings.flowATotalGraphType}
                                                        color={settings.flowATotalColor}
                                                        labelSize={settings.flowATotalLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Totaliser Flow 1"}
                                                        displayUnits={flowUnits}
                                                        isTotaliser={true}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />
                                                }



                                                {/*Total B*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x02)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#eed202'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowBTotal"}
                                                        isPressure={false}
                                                        visible={settings.flowBTotalVisible}
                                                        unit={settings.flowBTotalUnit}
                                                        minState={settings.flowBTotalMinState}
                                                        minValue={settings.flowBTotalMinValue}
                                                        maxState={settings.flowBTotalMaxState}
                                                        maxValue={settings.flowBTotalMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowBTotalAxisPosition}
                                                        lineWidth={settings.flowBTotalLineWidth}
                                                        graphType={settings.flowBTotalGraphType}
                                                        color={settings.flowBTotalColor}
                                                        labelSize={settings.flowBTotalLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Totaliser Flow 2"}
                                                        displayUnits={flowUnits}
                                                        isTotaliser={true}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />}    
                                            </div>}
                                        {selectedTab === 2 && graphTemplate.channelsAvail > 3 &&
                                            <div>
                                                {/*Total A*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x01)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#009fe3'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowATotal"}
                                                        isPressure={false}
                                                        visible={settings.flowATotalVisible}
                                                        unit={settings.flowATotalUnit}
                                                        minState={settings.flowATotalMinState}
                                                        minValue={settings.flowATotalMinValue}
                                                        maxState={settings.flowATotalMaxState}
                                                        maxValue={settings.flowATotalMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowATotalAxisPosition}
                                                        lineWidth={settings.flowATotalLineWidth}
                                                        graphType={settings.flowATotalGraphType}
                                                        color={settings.flowATotalColor}
                                                        labelSize={settings.flowATotalLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Totaliser Flow 1"}
                                                        displayUnits={flowUnits}
                                                        isTotaliser={true}
                                                        totalPerDay={settings.flowATotalPerDay}
                                                    />
                                                }



                                                {/*Total B*/}

                                                {!!(createCompanyDefault || (unitsAndValues.length > 0 && unitsAndValues.filter(item => item.serial === loggersToTemplate)[0].channelsAvail & 0x02)) &&
                                                    <GraphSettingsComponent
                                                        overViewStyle={overViewStyle}
                                                        borderColor={'#eed202'}
                                                        modalParagraphStyle={modalParagraphStyle}
                                                        handleGraphSettingsChange={handleGraphSettingsChange}
                                                        changeGroup={"flowBTotal"}
                                                        isPressure={false}
                                                        visible={settings.flowBTotalVisible}
                                                        unit={settings.flowBTotalUnit}
                                                        minState={settings.flowBTotalMinState}
                                                        minValue={settings.flowBTotalMinValue}
                                                        maxState={settings.flowBTotalMaxState}
                                                        maxValue={settings.flowBTotalMaxValue}
                                                        valueAs={null}
                                                        axisPosition={settings.flowBTotalAxisPosition}
                                                        lineWidth={settings.flowBTotalLineWidth}
                                                        graphType={settings.flowBTotalGraphType}
                                                        color={settings.flowBTotalColor}
                                                        labelSize={settings.flowBTotalLabelSize}
                                                        startDate={startDate}
                                                        loggers={loggers}
                                                        channel={"Totaliser Flow 2"}
                                                        displayUnits={flowUnits}
                                                        isTotaliser={true}
                                                        totalPerDay={settings.flowBTotalPerDay}
                                                    />}
                                            </div>}
                                        {selectedTab === 2 && graphTemplate.channelsAvail < 3 && <div>Alarms</div>}
                                        {selectedTab === 3 && graphTemplate.channelsAvail > 3 && <div>Alarms pressure version</div>}
                                        

                                    </div>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                        <div>
                                            {createCompanyDefault && <Button color="error" style={{ marginRight: "10px" }} onClick={(): void => handlePostGraphTemplate()} variant="contained">Save as Company Default</Button>}
                                            {!createCompanyDefault && <Button color="warning" style={{ marginRight: "10px" }} onClick={():void => handlePostGraphTemplate() } variant="contained">Save User Template and Load onto Selected Logger</Button>}
                                            <Button color="secondary" variant="contained" onClick={(): void => closeGraphTemplateWindow()}>Exit</Button>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Modal>




                            <CardHeader color="primary" className="view view-cascade" style={{ marginTop: "80px" }}>
                                <Grid container  >
                                    <Grid item lg={12} >
                                        <div style={{display: "flex", justifyContent: "space-between"} }>
                                        <div style={{ display: 'flex' }}>
                                            {location && location.state && location.state.showBackButton &&
                                                <IconButton
                                                    style={{ display: 'inline', marginTop: -14 }}
                                                    onClick={(): void => history.goBack()}
                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                </IconButton>
                                            }
                                                
                                            {location && location.state && location.state.site == null &&
                                                <div style={{ marginRight: "6px" }}>
                                                    Loggers:&nbsp; <br />
                                                    {location.state.showBackButton && loggers.map((logger: APILoggerDetail) => {
                                                        return <Chip key={logger.LoggerId} style={{ marginRight: "5px" }}
                                                            label={logger.Serial} onDelete={(): void => removeLogger(logger.Serial)} color="primary" />
                                                    })
                                                    }
                                                    {!location.state.showBackButton && loggers.map((logger: APILoggerDetail) => {
                                                        return <Chip key={logger.LoggerId} style={{ marginRight: "5px" }}
                                                            label={logger.Serial} color="primary" />
                                                    })
                                                    }

                                                    {loggers.length == 0 &&
                                                        <div style={{ display: 'inline', fontWeight: "bold" }}>None selected!</div>
                                                    }
                                                </div>
                                            }
                                            {location && location.state && location.state.site != null &&
                                                <div>
                                                    Site: &nbsp;{siteNameUserFriendly}
                                                </div>
                                            }
                                                
                                            </div>
                                            <div style={{ margin: "auto 10px auto 0", display: "flex" }}>
                                                <div style={{ border: "1px solid white", display: "flex", padding: " 10px 0 0 10px", margin: "0 40px 0 0"}}>
                                                    <p style={{ marginTop: "22px" } }>Temporary Toggle: </p>
                                                <div style={{marginRight: "10px"} }>
                                                    <p style={{marginTop: "auto 0"} }>Toggle Flow</p>
                                                    <div style={{display: "flex", justifyContent: "center", marginTop: "-25px"} }>
                                                        <Checkbox checked={showFlow} onClick={(e): void => handleToggleFlow(e, "Flow")} />
                                                    </div>
                                                </div>
                                                {hasPressureChannels && <div style={{ marginRight: "10px" }}>
                                                    <p style={{ marginTop: "auto 0" }}>Toggle Pressure</p>
                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                        <Checkbox checked={showPressure} onClick={(e): void => handleToggleFlow(e, "Pressure")} />
                                                    </div>
                                                </div>}
                                                <div style={{ marginRight: "10px" }}>
                                                    <p style={{ marginTop: "auto 0" }}>Toggle Totaliser</p>
                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "-25px" }}>
                                                        <Checkbox checked={showTotal} onClick={(e): void => handleToggleFlow(e, "Total")} />
                                                    </div>
                                                    </div>
                                                </div>
                                                <div style={{margin: "auto 0"} }>
                                                <Button variant="contained" color="secondary" style={{marginRight: "10px"}} onClick={resetZoom}>Reset Zoom</Button>
                                                {unitsAndValues.length > 0 && <Button color="primary" style={{ marginRight: "10px" }} variant="contained" onClick={(): void => toggleCreateTemplateOpen()}>Edit Graph Settings</Button>}
                                                </div>
                                            
                                            </div>
                                        </div>

                                    </Grid>

                                </Grid>
                                <DateRange startDate={startDate} endDate={endDate} rainfall={false} onDateChange={(startDateChange, endDateChange, rainfall): void => onDateChange(startDateChange, endDateChange, rainfall)} type="date" />
                            </CardHeader>

                            {graphData.length < 1 && <div style={{display: "flex", justifyContent: "center", alignItems: "center"} }><h4>No Data available between the dates selected, try expanding the data range</h4></div>}
                            {graphData.length > 0 && startDate && endDate &&

                                <div>
                                    {console.log(startDate) }
                                    {console.log(graphData) }
                                    {console.log(visualRange) }
                                    <Chart
                                        dataSource={graphData}
                                        ref={chartRef }
                                    >
                                        <CommonSeriesSettings
                                            argumentField="date"
                                            ignoreEmptyPoints={true}>
                                            <Point size={15} />
                                            <Font weight={900} size={40} />
                                        </CommonSeriesSettings>
                                        <CommonAxisSettings
                                            valueMarginsEnabled={true}
                                            minValueMargin={ashridgeDefaultInUse ? 0.1 : 0}    //add extra 10% to show a 'gap'
                                            maxValueMargin={ashridgeDefaultInUse ? 0.1 : 0}     //add extra 10% to show a 'gap'
                                        />
                                        {loggers.map((logger) => {

                                            return renderLoggerSeries(logger)
                                        })}

                                        {unitsAndValues && loggers.map((logger) => {
                                            return renderValueAxis(logger)
                                        })}

                                        {/*{location && location.state && location.state.site &&*/}
                                        {/*    renderSiteSeries()}*/}


                                        {console.log(visualRange) }
                                        <ArgumentAxis
                                            width={2}
                                            allowDecimals={false}
                                            argumentType="date"
                                            minValueMargin={0}
                                            maxValueMargin={0}
                                            visualRange={visualRange}
                                        >

                                            <GridChart visible={true} />
                                        </ArgumentAxis>
                                        <Export enabled={true} />
                                        <Crosshair
                                            enabled={true}
                                            color="#949494"
                                            width={3}
                                            dashStyle="dot"
                                        >
                                            <Label
                                                visible={true}
                                                backgroundColor="#949494"
                                            >
                                                <Font
                                                    color="#fff"
                                                    size={14}
                                                />
                                            </Label>
                                        </Crosshair>
                                        <ZoomAndPan
                                            allowMouseWheel={false}
                                            argumentAxis="both"
                                            valueAxis="none"
                                            dragToZoom={true}
                                            
                                        />
                                        <Legend
                                            visible={false}
                                            position="outside"
                                            horizontalAlignment="center"
                                            verticalAlignment="top"

                                        />
                                        {!createTemplateOpen && <Tooltip enabled={true} contentRender={renderTooltip} offsetY={-200} />}
                                    </Chart>
                                    <div style={{ marginTop: "40px" }}>
                                        <CardHeader color="primary">
                                            <h4 style={{ display: "flex", justifyContent: "center" }}>Adjust the range to zoom and drag to move the range on the graph</h4>
                                            <RangeSelector
                                                dataSource={graphData}
                                                onValueChanged={updateVisualRange}
                                                style={{ background: "#ffffff", margin: "10px", padding: "10px" }}
                                            >
                                                <Behavior valueChangeMode="onHandleMove" />
                                                <Size height={150} />
                                                <Scale minorTickCount={1} startValue={startDate} endValue={endDate} />
                                                <ChartOptions>
                                                    <Legend visible={false} />
                                                    {loggers.map((logger) => {
                                                        return renderRangeFinderSeries(logger)
                                                    }
                                                    )}
                                                </ChartOptions>
                                            </RangeSelector>
                                        </CardHeader>
                                    </div>
                                </div>}
                        </div>}
                </div>}
        </>
    )
}
export default MultiLoggerGraphV2