import React, { HTMLAttributes, ReactNode } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ClipLoader from "react-spinners/ClipLoader";
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI, CallPostAPI } from 'Utils/ApiHelper.js';
import { APIGetLoggerEventsModel, buildAPIGetLoggerEventsModel } from "models/APIGetLoggerEventsModel";
import Moment from 'react-moment';
import { APIGetLoggerSummaryModel, buildAPIGetLoggerSummaryModel } from "models/APIGetLoggerSummaryModel";
import { APIGetLoggerConnectionsModel, buildAPIGetLoggerConnectionsModel } from "models/APIGetLoggerConnectionsModel";
import { APIGetSitesModel, buildAPIGetSitesModel } from "models/APIGetSitesModel"
import { Button } from '@mui/material';
import { Redirect } from 'react-router-dom';
import WebMapView from "components/Map/WebMapView";
import { Switch } from 'devextreme-react/switch';
import Map from 'devextreme-react/map';
//Moment date/time formatting
//https://momentjs.com/docs/
import moment from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'
import SignalGauge from '../Connections/NewSignalGauge'
import BatterySignalGraph from '../Connections/BatterySignalGraph';
import BatteryInfoDisplay from '../Connections/BatteryInfoDisplay'
import FirmwareUpdate from './FirmwareUpdate';

// Device image
import CHF from "assets/CHF.png";
import CHF1P from "assets/CHF1P.png";
import CHF2P from "assets/CHF2P.png";
import ZL from "assets/ZoneLog_Logger.png"
import dxDataGrid from "devextreme/ui/data_grid" // DataGrid instance type
import { LoadPanel, DataGrid, Column, FilterRow } from 'devextreme-react/data-grid';
import type { InitializedEvent } from 'devextreme/ui/data_grid';

import ProductPopup from './ProductPopup';

import { withStyles, createStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { compose, spacing, palette, border } from '@mui/system';
import BatteryData from '../Connections/BatteryInfoTor'
import { APIGetLoggerReportsModel, buildAPIGetLoggerReportsModel } from '../../models/APIGetLoggerReportsModel';
import { APIGetLoggerAuditModel, buildAPIGetLoggerAuditModel } from '../../models/APIGetLoggerAuditModel';

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
            
        },
        "& a,& a:hover,& a:focus": {
            color: "#ffffff"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const overViewStyle = {
    backgroundColor: "#f7f7f7",
    borderRadius: "5px",
    padding: "10px 0 0 0",
    width: "90%",
    boxShadow: "2px 2px 10px #00000060",
    marginBottom: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const style = createStyles(styles);
const Box = styled('div')(compose(spacing, palette));

interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: number;
    mapKey: string
}

interface State {
    loading: boolean;
    tableHidden: boolean;
    authorized: boolean;
    logger: APIGetLoggerSummaryModel | null;
    connectionsData: Array<APIGetLoggerConnectionsModel>;
    auditData: Array<APIGetLoggerAuditModel>;
    reportData: any;
    productPopup: boolean;
    redirect: boolean;
    redirectPath: string;
    redirectProps: {
        siteName: string;
        siteId: number | undefined;
        serial: string | undefined;
        deviceId: number | undefined;
    } | null;
    viewMapModal: boolean;
    viewStatsModal: boolean;
    siteData: APIGetSitesModel | null;
    deviceimage: string | undefined;
    showmap: boolean;
    relayMessage: string;
    relayError: boolean;
    mapKey: string;
    eventData: APIGetLoggerEventsModel;
    firmwareUpdateNeeded: boolean;
}

class LCLSummary extends React.Component<Props, State> {

    auditgridInstance?: dxDataGrid;

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            loading: true,
            tableHidden: true,
            authorized: true,
            logger: null,
            connectionsData: [],
            auditData: [],
            reportData: null,
            productPopup: false,
            redirect: false,
            redirectPath: '',
            redirectProps: null,
            viewMapModal: false,
            viewStatsModal: false,
            siteData: null,
            deviceimage: window.location.hostname.includes("zonelog.net") ? ZL : CHF, //default,
            showmap: false,
            relayMessage: '',
            relayError: false,
            mapKey: "",
            eventData: null,
            firmwareUpdateNeeded: false
           
        };

        this.relayChanged = this.relayChanged.bind(this);
        this.saveInstance = this.saveInstance.bind(this);
        this.auditReady = this.auditReady.bind(this);
        this.needsUpdate = this.needsUpdate.bind(this);
    }


    saveInstance = (e: InitializedEvent): void => {
        this.auditgridInstance = e.component;
        e.component?.beginCustomLoading('Loading...');
    }

    auditReady(e: any): void {
        //https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MasterDetailAPI/React/Light/
        //highlight / select the top row in the audit grid
        e.component.selectRowsByIndexes(0); 
    }

    componentDidMount(): void {
        //this.setState({ loading: true })
        const start = moment().subtract(7, 'days').toDate();
        const end = moment().toDate();
        CallGetAPI(CreateUrl('/api/aquaguard/mapkey'), {})
            .then(data => { 
                this.setState({mapKey: data})
        let connectionsData = new Array<APIGetLoggerConnectionsModel>();
        const me = this;
        this.loadAudit();
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + me.props.serial),{})
            .then(response => {
                
                if (response.relayStatus === null) {
                    this.setState({ relayError: true })
                }
                //get logger stats
                const loggerIds: Array<{ Id: number }> = [];

                loggerIds.push({ Id: response.id + 500000 });

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(loggerIds)
                };
                

                    CallGetAPI(CreateUrl('/api/aquaguard/LoggerReportBySerial?serial=' + me.props.serial), {})
                     .then(data => {

                         if (data.length > 0) {
                             
                             
                             // Copy the data records into reportData
                             const reportsData: Array<APIGetLoggerReportsModel> = [];

                             
                             for (let i = 0; i < data.length; i++) {
                                 
                                 reportsData.push(buildAPIGetLoggerReportsModel(data[i]));
                                 
                             }
                             console.log(reportsData)
                             if (reportsData.length > 0) {
                                 //alert(reportsData[0].lastconnection?.toString());
                                 let imgsrc = ""
                                 //set deviceimage based on  product field of reportsData[0]
                                 
                                 if (window.location.hostname.includes('zonelog.net')) {
                                     imgsrc = ZL
                                     let ZLProduct = ""
                                     switch (reportsData[0].product) {
                                         case 'CHFL':
                                             ZLProduct = "ZL-2F"
                                             break
                                         case 'CHF1':
                                             ZLProduct = "ZL-2F-1P"
                                             break
                                         case "CHF2":
                                             ZLProduct = "ZL-2F-2P"
                                             break
                                         case "CH1P":
                                             ZLProduct = "ZL-1P"
                                             break
                                         case "CH2P":
                                             ZLProduct = "ZL-2P"
                                             break
                                         default:
                                             ZLProduct = "unknown"
                                             break
                                     }
                                     reportsData[0].product = ZLProduct
                                 } else {
                                     imgsrc = CHF
                                     if (reportsData[0].product?.toString().includes('2P')) imgsrc = CHF2P;
                                     if (reportsData[0].product?.toString().includes('1P')) imgsrc = CHF1P;

                                 }
                                 me.setState(
                                     {
                                         reportData: buildAPIGetLoggerReportsModel(reportsData[0]),
                                         deviceimage: imgsrc,
                                         tableHidden: false,
                                         loading: false
                                     });
                                
                             }
                         }
                         else {
                             //alert or log no data
                         }
                     })
                     .catch(function (error) {
                         console.log(error);
                     });

                //call GetAPI for  for What3Words use response.latitude and longitude
                
                
                me.setState({
                    logger: buildAPIGetLoggerSummaryModel(response),
                    showmap: buildAPIGetLoggerSummaryModel(response).GpsLatitude != null ? true : false
                });

            })
            .catch(function () {
                me.setState(
                    {
                        authorized: false,
                        logger: null
                    })
                console.log("Unauthorized!")
            });
                
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerConnections?serial=' + this.props.serial + "&startDate=" + moment().subtract(7, "days").format("yyyy-MM-DD") + "&endDate=" + moment().endOf("day").format("yyyy-MM-DDTHH:mm:ss")), {})
            .then(json => {
                
                connectionsData = connectionsData.concat(buildAPIGetLoggerConnectionsModel(json));

                me.setState({
                    connectionsData: connectionsData,
                });
            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        connectionsData: []
                    });
                console.log(ex);
            })

        CallGetAPI(CreateUrl('/api/aquaguard/GetSite?logger=' + this.props.serial), {})
            .then(json => {
                
                const siteData: APIGetSitesModel = buildAPIGetSitesModel(json[0]);

                me.setState({
                    siteData: siteData,
                });

            })
            .catch(function (ex) {
                me.setState(
                    {
                        authorized: false,
                        siteData: null
                    });
                console.log(ex);
            })

                let eventInfo: APIGetLoggerEventsModel[] = [];
                // Looks for log events from the last 7 days - battery logs should happen daily
                CallGetAPI(CreateUrl(`/api/aquaguard/LoggerEvents?serial=${this.props.serial}&startDate=${moment(start).format("YYYY-MM-DD")}&endDate=${moment(end).format("YYYY-MM-DD")}`), {})
                    .then(response => {
                        
                        eventInfo = buildAPIGetLoggerEventsModel(response);
                        const batteryInfo = eventInfo.filter(item => {
                            return item.remainingBattPerc !== null
                        })
                        
                        if (batteryInfo.length > 0) {
                            this.setState({ eventData: batteryInfo[0] })
                        }
                    })
                    .catch(err => console.log(err));

        


                
            })
        
        
    }

    showProductPopup(): void {
        this.setState({ productPopup: true })
    }

    hideProductPopup(): void {
        this.setState({ productPopup: false })
    }

    viewSiteClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/SiteDetail',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: undefined
                }
            });
    }

    viewDeploymentClick(): void {
        this.setState(
            {
                redirect: true,
                redirectPath: '/portal/DeploymentList',
                redirectProps: {
                    siteName: this.state.logger?.SiteNameUserFriendly || this.state.logger?.SiteName || "",
                    siteId: this.state.logger?.FkSiteId,
                    serial: this.state.logger?.Serial.toString(),
                    deviceId: this.state.logger?.Id
                }
            });
    }

    dateColumnCustomizeText(cellInfo: any): string {
        if (cellInfo.value == null)
            return "";
        else
            return moment(cellInfo.value).format("DD/MM/YYYY HH:mm");
    }

    renderAlarms(): ReactNode {
        const alarms: string[] = [];
        const state: ReactNode[] = [];
        if (this.state.logger?.AlarmMessage0 && this.state.logger?.AlarmMessage0 != "") {
            alarms.push(this.state.logger?.AlarmMessage0);
            state.push((this.state.logger?.AlarmState & 0x0001) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);
        }
        if (this.state.logger?.AlarmMessage1 && this.state.logger?.AlarmMessage1 != "") {
            alarms.push(this.state.logger?.AlarmMessage1);
            state.push((this.state.logger?.AlarmState & 0x0002) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage2 && this.state.logger?.AlarmMessage2 != "") {
            alarms.push(this.state.logger?.AlarmMessage2);
            state.push((this.state.logger?.AlarmState & 0x0004) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage3 && this.state.logger?.AlarmMessage3 != "") {
            alarms.push(this.state.logger?.AlarmMessage3);
            state.push((this.state.logger?.AlarmState & 0x0008) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage4 && this.state.logger?.AlarmMessage4 != "") {
            alarms.push(this.state.logger?.AlarmMessage4);
            state.push((this.state.logger?.AlarmState & 0x0010) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage5 && this.state.logger?.AlarmMessage5 != "") {
            alarms.push(this.state.logger?.AlarmMessage5);
            state.push((this.state.logger?.AlarmState & 0x0020) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage6 && this.state.logger?.AlarmMessage6 != "") {
            alarms.push(this.state.logger?.AlarmMessage6);
            state.push((this.state.logger?.AlarmState & 0x0040) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage7 && this.state.logger?.AlarmMessage7 != "") {
            alarms.push(this.state.logger?.AlarmMessage7);
            state.push((this.state.logger?.AlarmState & 0x0080) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage8 && this.state.logger?.AlarmMessage8 != "") {
            alarms.push(this.state.logger?.AlarmMessage8);
            state.push((this.state.logger?.AlarmState & 0x0100) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage9 && this.state.logger?.AlarmMessage9 != "") {
            alarms.push(this.state.logger?.AlarmMessage9);
            state.push((this.state.logger?.AlarmState & 0x0200) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage10 && this.state.logger?.AlarmMessage10 != "") {
            alarms.push(this.state.logger?.AlarmMessage10);
            state.push((this.state.logger?.AlarmState & 0x0400) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage11 && this.state.logger?.AlarmMessage11 != "") {
            alarms.push(this.state.logger?.AlarmMessage11);
            state.push((this.state.logger?.AlarmState & 0x0800) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage12 && this.state.logger?.AlarmMessage12 != "") {
            alarms.push(this.state.logger?.AlarmMessage12);
            state.push((this.state.logger?.AlarmState & 0x1000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage13 && this.state.logger?.AlarmMessage13 != "") {
            alarms.push(this.state.logger?.AlarmMessage13);
            state.push((this.state.logger?.AlarmState & 0x2000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage14 && this.state.logger?.AlarmMessage14 != "") {
            alarms.push(this.state.logger?.AlarmMessage14);
            state.push((this.state.logger?.AlarmState & 0x4000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        if (this.state.logger?.AlarmMessage15 && this.state.logger?.AlarmMessage15 != "") {
            alarms.push(this.state.logger?.AlarmMessage15);
            state.push((this.state.logger?.AlarmState & 0x8000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

        }
        return (
            <div>
                {alarms.map((a, i) => {
                    return (<span key={i}>{a}: {state[i]}<br /></span>)
                })
                }
            </div>
        )

    }

    renderSiteAlarms(): ReactNode {
            const alarms: string[] = [];
        const state: ReactNode[] = [];
            
            if (this.state.siteData?.AlarmMessage0 && this.state.siteData?.AlarmMessage0 != "") {
                alarms.push(this.state.siteData?.AlarmMessage0);
                state.push((this.state.siteData?.AlarmState & 0x0001) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);
            }
            if (this.state.siteData?.AlarmMessage1 && this.state.siteData?.AlarmMessage1 != "") {
                alarms.push(this.state.siteData?.AlarmMessage1);
                state.push((this.state.siteData?.AlarmState & 0x0002) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage2 && this.state.siteData?.AlarmMessage2 != "") {
                alarms.push(this.state.siteData?.AlarmMessage2);
                state.push((this.state.siteData?.AlarmState & 0x0004) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage3 && this.state.siteData?.AlarmMessage3 != "") {
                alarms.push(this.state.siteData?.AlarmMessage3);
                state.push((this.state.siteData?.AlarmState & 0x0008) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage4 && this.state.siteData?.AlarmMessage4 != "") {
                alarms.push(this.state.siteData?.AlarmMessage4);
                state.push((this.state.siteData?.AlarmState & 0x0010) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage5 && this.state.siteData?.AlarmMessage5 != "") {
                alarms.push(this.state.siteData?.AlarmMessage5);
                state.push((this.state.siteData?.AlarmState & 0x0020) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage6 && this.state.siteData?.AlarmMessage6 != "") {
                alarms.push(this.state.siteData?.AlarmMessage6);
                state.push((this.state.siteData?.AlarmState & 0x0040) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage7 && this.state.siteData?.AlarmMessage7 != "") {
                alarms.push(this.state.siteData?.AlarmMessage7);
                state.push((this.state.siteData?.AlarmState & 0x0080) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage8 && this.state.siteData?.AlarmMessage8 != "") {
                alarms.push(this.state.siteData?.AlarmMessage8);
                state.push((this.state.siteData?.AlarmState & 0x0100) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage9 && this.state.siteData?.AlarmMessage9 != "") {
                alarms.push(this.state.siteData?.AlarmMessage9);
                state.push((this.state.siteData?.AlarmState & 0x0200) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage10 && this.state.siteData?.AlarmMessage10 != "") {
                alarms.push(this.state.siteData?.AlarmMessage10);
                state.push((this.state.siteData?.AlarmState & 0x0400) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage11 && this.state.siteData?.AlarmMessage11 != "") {
                alarms.push(this.state.siteData?.AlarmMessage11);
                state.push((this.state.siteData?.AlarmState & 0x0800) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage12 && this.state.siteData?.AlarmMessage12 != "") {
                alarms.push(this.state.siteData?.AlarmMessage12);
                state.push((this.state.siteData?.AlarmState & 0x1000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage13 && this.state.siteData?.AlarmMessage13 != "") {
                alarms.push(this.state.siteData?.AlarmMessage13);
                state.push((this.state.siteData?.AlarmState & 0x2000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage14 && this.state.siteData?.AlarmMessage14 != "") {
                alarms.push(this.state.siteData?.AlarmMessage14);
                state.push((this.state.siteData?.AlarmState & 0x4000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }
            if (this.state.siteData?.AlarmMessage15 && this.state.siteData?.AlarmMessage15 != "") {
                alarms.push(this.state.siteData?.AlarmMessage15);
                state.push((this.state.siteData?.AlarmState & 0x8000) > 0 ? <span style={{ color: 'red' }}>ACTIVE</span> : <span>Off</span>);

            }

        return (
            <div>
                {alarms.map((a, i) => {
                    return (<span key={i}>{a}: {state[i]}<br/></span>)
                })
                }
            </div>
            )

    }

    loadLoggerRelayUpdateTimer(): void {

        //https://stackoverflow.com/questions/13304471/javascript-get-code-to-run-every-minute
        //outer loop do this for 15 mins or until relay status from original page call has changed?

        const timerID = setInterval(() => {
            this.loadLogger();
        }, 30 * 1000); //load logger details every 30 seconds

        //timeout after 15 minutes
        setTimeout(() => {
            clearInterval(timerID); //halt the process
        },  2 * 60000); //move to 15
    }

    loadLogger(): void {

        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LoggerBySerial?serial=' + me.props.serial), {})
            .then(json => {
                //const loggerData: APIGetLoggerSummaryModel = buildAPIGetLoggerSummaryModel(json); //should probably have a quicker mini API call for this
                //var currentRelayStatus = loggerData.RelayStatus;

                const loggerReturn = buildAPIGetLoggerSummaryModel(json);
                me.setState({
                    logger: loggerReturn                    
                });
            })
            .catch(function (ex) {
                console.log(ex);
            })
    }



    loadAudit(): void {
        //refresh the audit grid here with latest ...
        CallGetAPI(CreateUrl('/api/aquaguard/audit?serial=' + this.props.serial + "&type=relay"), {})
            .then(data => {


                if (data.length > 0) {

                    // Copy the data records into reportData
                    const auditData: Array<APIGetLoggerAuditModel> = [];
                    //for (let i = 0; i < data.length; i++)
                    for (let i = 0; i < 5; i++) //just take top 5 entries
                    {
                        if (data[i] != null)
                            auditData.push(buildAPIGetLoggerAuditModel(data[i]));
                    }
                    let message = ""
                    if (auditData[0].details !== auditData[1].details) {
                        message = "Awaiting change from logger, the switch will reactivate once previous change is registered"
                    }
                    this.setState(
                        {
                            auditData: auditData,
                            relayMessage: message
                        });
                }
                else {
                    //no audit data
                }
            })
            .catch(function (ex) {
                //this.setState(
                //    {
                        //authorized: false,
                        //siteData: null
                //    });
                console.log(ex);
            })

    }

    relayChanged(e: any, error: boolean, set: string): void {

        //alert(e.value);

        const me = this;

        /*
        ///     POST /api/aquaguard/LCLoggerRelay
        ///     {
        ///         Serial: 49405,
        ///         UpdateJson: "{'R6': '00' }",
        ///         IsChariot: true,
        ///         UserGuid: 'D6C89E9E-4166-4FF7-8FB2-E7E473A11691'
        ///     }
        */

       //R6 - Relay On / Off
       //S2 - Transmission Frequency (min) todo UI drop down choice of 0005 (5 min), 003C (60 min), 001E (30 min)
       //A1 - Logging Frequency (secs) todo UI drop down choice of 003C (1 min), 012C (5  min), 0384 (15 min)
        if (error) {
            const logger = this.state.logger
            if (set === 'off') {
                e.value = false
                logger.RelayStatus = false
            } else {
                e.value = true
                logger.RelayStatus = true
            }
            this.setState({relayError: false, logger: logger})
        }
        //need to make a call to AG /api/aquaguard/LCLoggerRelay
        const updateJson = (e.value == false) ? "{'R6':'00','S2':'0005','A1':'003C'}" : "{'R6':'01','S2':'001E','A1':'012C'}"; //OFF -> default set unit to transmit every 5 MIN / log every 60 SECS
        const relayStatus = (e.value == false) ? "OFF" : "ON";

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ serial: me.state.logger?.Serial.toString(), updateJson: updateJson, isChariot: true, userGuid: sessionStorage.getItem('userGuid') })
        };

        CallPostAPI(CreateUrl('/api/aquaguard/LCLoggerRelay'), requestOptions)
            .then(response => {
                if (response.ok) {
                    me.setState({                
                        relayMessage: 'Relay command ' + relayStatus + ' sent, device will be updated when it next connects and the switch will be reactivated',
                    });
                }
                else {
                    me.setState({
                        relayMessage: 'Relay error',
                    });
                }
                //console.log(response);
            })
            .catch(function (error) {
                console.log(error);
                me.setState({
                    relayMessage: 'Relay error',
                });
            });

        //relayMessage size > 0 disables the switch button

        //look for a status change
        this.loadLoggerRelayUpdateTimer();

        //wait for above API call to propogate its Rabbit events etc - this will update the Audit table to confirm the relay change meessage was sent
        setTimeout(() => {
            this.loadAudit();
        }, 3000);

    }


    needsUpdate(data: boolean): void {
         
         this.setState({firmwareUpdateNeeded: data})
        //console.log(data)
    }

    render(): ReactNode {

        return (!this.state.redirect) ?
            (<div>

                <ProductPopup show={this.state.productPopup} product={this.state.reportData?.product} image={this.state.deviceimage} title={window.location.hostname.includes("zonelog.net") ? "Zonelog" : "CharIoT"} onClose={this.hideProductPopup.bind(this)} />
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                
                {!this.state.loading &&
                    <div>
                        {this.state.tableHidden &&
                                <ApiFailed />
                        }
                        {!this.state.tableHidden &&
                            
                        <Box padding={0}>
                            <GridContainer direction="row" justify="center"  margin-bottom={2} >
                                    <GridItem item xs={4} style={{ marginBottom: 0 }} >
                                        <div style={{ borderLeft: "10px solid #00BF00", ...overViewStyle, width: "100%", marginTop: "17px" }}>
                                    <Box  style={{ borderRadius: "10px", height: "100%", width: "100%" }}>
                                                <h5 style={{textAlign: "center"} }><b>Device</b></h5>                                        
                                         {this.state.logger &&
                                                    <div style={{textAlign: "center"} }>
                                                <img src={this.state.deviceimage} width={window.location.hostname.includes('zonelog.net') ? 200 : 300} onClick={this.showProductPopup.bind(this)} style={{ cursor: "pointer" }} />
                                            </div>
                                                }
                                                <div style={{marginLeft: "4%"} }>
                                        <div>
                                            <b>{window.location.hostname.includes('zonelog.net') ? "ZONELOG:" : "CharIOT:"} </b>&nbsp;&nbsp; {this.state.reportData?.product}
                                        </div>
                                        <div>
                                            <b>Serial: </b>&nbsp;&nbsp;{this.state.logger?.Serial}
                                        </div>
                                        <div>
                                            <b>SW Version: </b>&nbsp;&nbsp;{this.state.logger?.Swversion}{this.state.logger?.Devversion && this.state.logger?.Devversion > 0 ? <span>rc{this.state.logger?.Devversion}</span> : <span></span>}
                                        </div>
                                        <div>
                                            {this.state.logger?.LastReading != null &&
                                            <div>
                                                <b>Last Data Reading: </b><Moment format="HH:mm DD/MM/YYYY">{this.state.logger?.LastReading}</Moment>
                                            </div>
                                            }
                                            {this.state.logger?.LastReading == null &&
                                            <div>
                                                <b>Last Data Reading: </b><br />No readings available
                                            </div>
                                            }
                                            </div>
                                            
                                        <hr />
                                        <div>
                                            <table style={{ width: "100%"}}>
                                                <tr>
                                                    <td>
                                                        <span style={{ fontWeight: "bold" }}>Site: </span>&nbsp;&nbsp; {this.state.logger?.SiteNameUserFriendly ? this.state.logger?.SiteNameUserFriendly : this.state.logger?.SiteName}
                                                    </td>
                                                    <td>
                                                        {this.state.logger?.SiteName &&
                                                                        <Button style={{ marginBottom: "10px" }}  size="small" color="secondary" variant="contained" onClick={this.viewSiteClick.bind(this)} >View site</Button>
                                                        }
                                                    </td>
                                                </tr>


                                                            <tr>                                                        
                                                    <td>
                                                        {this.state.logger?.Deployed ?
                                                            <div><span style={{ fontWeight: "bold" }}>Deployed: </span> &nbsp;&nbsp;{moment(this.state.logger?.Deployed).format("DD/MM/yyyy")}</div>
                                                            :
                                                            <div><span style={{ fontWeight: "bold" }}>Not deployed: </span> &nbsp;&nbsp;{moment(this.state.logger?.Deployed).format("DD/MM/yyyy")}</div>
                                                        }
                                                        </td>
                                                        <td >
                                                                    <Button size="small" variant="contained" color="primary" onClick={this.viewDeploymentClick.bind(this)}>History</Button>
                                                        </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <hr/>
                                        {this.state.logger?.GpsLatitude != null &&
                                        <div>
                                            <b>GPS: </b>&nbsp;&nbsp;{this.state.logger?.GpsLatitude.toFixed(2)}N {this.state.logger?.GpsLongitude?.toFixed(2)}W
                                        </div>
                                        }
                                        {this.state.reportData?.latestbattery != null &&

                                            <div>
                                                <b>Battery (V) :</b>&nbsp; &nbsp; {this.state.reportData?.latestbattery.toFixed(2)}
                                            </div>
                                        }
                                        <div>
                                            <b>Signal (RSSI) :</b>&nbsp; &nbsp; {this.state.connectionsData[0]?.latestRssi}
                                        </div>
                                        <div>
                                            <b>Transmissions per Day:</b>&nbsp; &nbsp; {this.state.reportData?.transmissionfrequency}
                                        </div>
                                        <div>
                                            <b>Successful Connections:</b>&nbsp; &nbsp; {this.state.reportData?.numdailydialins}
                                        </div>

                                        <div>
                                            <b>Last Message Type:</b>&nbsp; &nbsp; {this.state.reportData?.callinstatus}
                                                    </div>
                                                    
                                        <div>
                                            <b>Network Type :</b>&nbsp; &nbsp; {this.state.reportData?.networktype}
                                        </div>
                                        <div>

                                            <b>Carrier :</b>&nbsp; &nbsp; {this.state.reportData?.carrier}
                                        </div>
                                        <div>
                                            <b>CellId :</b>&nbsp; &nbsp; {this.state.reportData?.cellId}
                                                    </div>
                                        <div>
                                            <b>Band :</b>&nbsp; &nbsp; {this.state.reportData?.band}
                                        </div>
                                                  
                                        <hr/>
                                        {this.state.logger?.AlarmsEnabled !== undefined && this.state.logger?.AlarmsEnabled > 0 &&
                                                        <div style={{marginBottom: "20px"} }>
                                                <b>Alarms:</b> 
                                                {this.renderAlarms()}
                                            </div>
                                        }
                                        {this.state.siteData?.AlarmsEnabled !== undefined && this.state.siteData?.AlarmsEnabled > 0 &&
                                            <div style={{marginBottom: "20px"}}>
                                                <b>Site Alarms:</b>
                                                {this.renderSiteAlarms()}
                                            </div>
                                            }
                                 
                                            {this.state.logger?.Model === 'CHFR' && 
                                            <div style={{marginBottom: "20px"}}>
                                                    <b>Current Relay Status
                                                        {this.state.logger?.RelayStatus == false && " - OFF"}
                                                        {this.state.logger?.RelayStatus == true && " - ON"}
                                                        <br/>
                                                    </b><br/>send Relay switch message..&nbsp;&nbsp; 
                                                    <Switch
                                                                style={{ marginBottom: "10px"} }
                                                        defaultValue={this.state.logger?.RelayStatus}
                                                        onValueChanged={(e):void => this.relayChanged(e, false, "")}
                                                        disabled={!(sessionStorage.getItem('userLevel') != "user" && sessionStorage.getItem('userLevel') != "restricteduser") || this.state.relayError} //not useradmin or above or message already sent
                                                            ></Switch>
                                                            
                                                            {this.state.relayError && this.state.auditData.length > 0  && <div>
                                                        <p>There has been an error in communicating to the relay</p>
                                                        <Button color="secondary" disabled={this.state.auditData[0].details !== this.state.auditData[1].details} style={{marginRight: "10px"}} variant="contained" onClick={(e): void => this.relayChanged(e, true, "off")}>Reset Relay to Off</Button>
                                                        <Button color="secondary" disabled={this.state.auditData[0].details !== this.state.auditData[1].details} variant="contained" onClick={(e): void => this.relayChanged(e, true, "on")}>Reset Relay to On</Button>
                                                    </div>}
                                                    
                                                    <br />{this.state.relayMessage}
                                                            <div style={{marginBottom: "20px"} }>
                                                        {this.state.auditData !== null && this.state.auditData.length > 0 &&
                                                            <div>
                                                                <DataGrid dataSource={this.state.auditData} showBorders={true} onInitialized={this.saveInstance}
                                                                    allowColumnResizing={true}  onContentReady={this.auditReady}>
                                                                    <FilterRow visible={false} />
                                                                    <LoadPanel enabled={false} />
                                                                    <Column dataField="timestamp" caption="When" visible={true} dataType="datetime" format="shortDate" width="125px" customizeText={this.dateColumnCustomizeText} />
                                                                    <Column dataField="email" caption="User" visible={true} dataType="string" width="100px" />
                                                                    <Column dataField="details" caption="Action" visible={true} dataType="string" width="75px" />
                                                                </DataGrid>
                                                            </div>                                                        
                                                        }
                                                    </div>
                                            </div>
                                        }
                                        </div>
                                        </Box>
                                       </div>
                                </GridItem>
                                    <GridItem item xs={this.state.connectionsData.length > 0 || this.state.eventData || this.state.firmwareUpdateNeeded ? 5 : 8} style={{ marginBottom: 0 }}>
                                        <Box padding={2} style={{ borderRadius: "25px" }}>
                                            {this.state.showmap && this.state.mapKey && this.state.reportData?.latitude &&
                                                <div>
                                                    
                                                    <Map
                                                        //zoom={15}
                                                        defaultZoom={15}
                                                        provider="google"
                                                        markers={[
                                                            { location: [this.state.reportData?.latitude, this.state.reportData?.longitude] }
                                                        ]}
                                                        apiKey={this.state.mapKey}
                                                        width="100%"
                                                        height={600}
                                                        controls={true}
                                                        type="hybrid"
                                                    />
                                                    {!window.location.hostname.includes('zonelog.net') && <i>What3Words - {this.state.logger?.What3Words}</i>}
                                                </div>
                                                    
                                            }
                                    </Box>
                                </GridItem>
                                    <GridItem item xs={3} style={{ marginBottom: 0, marginTop: "17px" }}>
                                        <div >
                                            
                                                {this.state.connectionsData.length > 0 &&
                                                <div style={{ borderLeft: "10px solid #00ADBA", ...overViewStyle, width: "100%" }}>
                                                        <SignalGauge connectionsData={this.state.connectionsData} />
                                                    </div>}
                                                {/*<BatterySignalGraph connectionsData={this.state.connectionsData} show="signal" model="LCL" />*/}
                                                
                                                {this.state.eventData && <div style={{ borderLeft: "10px solid #6F2DA8", ...overViewStyle, width: "100%" }}>
                                                    <BatteryInfoDisplay eventData={this.state.eventData} />
                                                </div>}
                                                {!this.state.eventData && <div style={{ borderLeft: "10px solid #6F2DA8", ...overViewStyle, width: "100%" }}>
                                                <BatteryData connectionsData={this.state.connectionsData} />
                                                </div>} 
                                                {this.state.logger && this.state.logger?.Serial !== undefined &&
                                                    
                                                <FirmwareUpdate serial={this.state.logger?.Serial} overViewStyle={overViewStyle} firmwareNeeded={this.needsUpdate} />
                                                    
                                                }
                                                {this.state.eventData && <p>* This value is only an estimate based on current rate of connections, coverage class, temperature and network activity</p>}
                                    
                                    </div>

                                </GridItem>

                            </GridContainer>
                        </Box>
                    }
                    </div>
                }

            </div>

        )
            :
            (<Redirect push to={{
                pathname: this.state.redirectPath,
                state: this.state.redirectProps
            }} />);
    }

}

export default withStyles(style)(LCLSummary);