import React, { ReactNode } from "react";

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList'
import ListItemIcon from '@mui/material/ListItemIcon';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import { AppBar, Box, FormControl, InputLabel, Switch, Toolbar, Typography, Checkbox } from '@mui/material';
import { Select, NativeSelect } from '@mui/material';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

//import cardStyles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Moment } from 'moment';
import TooltipMaterial from '@mui/material/Tooltip';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import NumericInput from 'react-numeric-input';
import Modal from 'react-bootstrap/Modal'

import { NotificationContainer, NotificationManager } from 'react-notifications';

import { withStyles, createStyles } from '@mui/styles';
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { CreateUrl, CallGetAPI, CallPutAPI, CallPostAPI, CallDeleteAPI } from 'Utils/ApiHelper.js';
import { APIPhoneNumber } from "models/APIPhoneNumber"
import { APIGprsDetail } from "models/APIGprsDetail"
import { APISimDetail } from "models/APISimDetail"
import { APIConfigTemplate } from "models/APIConfigTemplate"
import { ChariotConfig, ChariotAlarm, configFromJson, jsonFromConfig } from "models/ChariotConfig"

import SampleRate from './SampleRate';
import WindowPeriod from './WindowPeriod';
import WebMapView from '../Map/WebMapView';

//DevExtreme data grid
import 'devextreme/dist/css/dx.light.css';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, { Column, Editing, Selection } from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import nbiot from '../../assets/nbiot_green_rgb.png'
import lte from '../../assets/lte_m_rgb.png'
import Login from "../api-authorization/Login";
const style = createStyles(styles as Record<string, any>);


interface Props {
    classes: {
        cardTitleWhite: string;
    };
    serial: string;
    product: string;
    model: string;
    channels: number;
    meterConfig: number;
    onConfigSend?: (deviceConfig: string) => void;
}

interface State {
    orgConfig?: ChariotConfig;
    deviceConfig?: ChariotConfig;
    ready: boolean;
    openViewMenu: boolean;
    openTemplateMenu: boolean;
    enableSend: boolean;
    anchorEl: Element | null;
    panel: string;
    simDetails: DataSource;
    gprsDetails: DataSource;
    phoneNumbers: DataSource;
    templates: Array<APIConfigTemplate>;
    simEdit: number | null;
    gprsEdit: number | null;
    phoneEdit: number | null;
    templateName: string;
    templateSelect: number;
    templateNameError: boolean;
    templateNameErrorMsg: string;
    smsTxTime: Date;
    meterConfig: number;
    channelsAvail: number;
    configDate?: Date;
    battery?: number;
    loggerHold: boolean;
    sendAfter: Date | null;
    modbusModal: boolean;
    modbusModalTitle: string;
    editAlarm?: ChariotAlarm;
    alarmTab: number;
    alarms: Array<ChariotAlarm>;
    setUnits: string;
    clrUnits: string;
    // Field error flags
    fieldErrors: {
        FlowAMultiplier: boolean;
        FlowBMultiplier: boolean;
        AnalogueCMultiplier: boolean;
        AnalogueDMultiplier: boolean;
        SetThreshold: boolean;
        ClrThreshold: boolean;
        SetCount: boolean;
        ClrCount: boolean;
        MaxAlarms: boolean;
    };
    transducer1: number | null;
    transducer2: number | null;
}


class ToolkitCHR extends React.Component<Props, State> {
    notificationSystem = React.createRef();

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            orgConfig: undefined,
            deviceConfig: undefined,
            ready: false,
            openViewMenu: false,
            openTemplateMenu: false,
            enableSend: false,
            anchorEl: null,
            panel: "Config",
            simDetails: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            gprsDetails: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            phoneNumbers: new DataSource({ store: { type: "array", key: "id", data: [] } }),
            templates: [],
            simEdit: null,
            gprsEdit: null,
            phoneEdit: null,
            templateName: "",
            templateSelect: 0,
            templateNameError: false,
            templateNameErrorMsg: "",
            smsTxTime: new Date(),
            meterConfig: props.meterConfig,
            channelsAvail: props.channels ? props.channels : 0,
            configDate: undefined,
            battery: undefined,
            alarmTab: 0,
            loggerHold: false,
            sendAfter: null,
            modbusModal: false,
            modbusModalTitle: "",
            alarms: [],
            setUnits: "",
            clrUnits: "",
            fieldErrors: {
                FlowAMultiplier: false,
                FlowBMultiplier: false,
                AnalogueCMultiplier: false,
                AnalogueDMultiplier: false,
                SetThreshold: false,
                ClrThreshold: false,
                SetCount: false,
                ClrCount: false,
                MaxAlarms: false,
            },
            transducer1: null,
            transducer2: null,
        };

    }

    componentDidMount(): void {

        //get SimDetails, GprsDetails & PhoneNumbers
        //this.loadConfigTemplates();
        //this.loadSimDetails();
        //this.loadGprsDetails();
        // Load Alarm Configs
        this.loadAlarmConfigs();
        
    }

    loadSimDetails(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/SimDetailsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    const store = new ArrayStore(
                        {
                            key: "id",
                            data: data as APISimDetail[]
                        });

                    me.setState(
                        {
                            simDetails: new DataSource({ store: store }),
                        });
                }
                else {
                    console.log("No simDetails");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });
    }

    loadGprsDetails(): void {

        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/GprsDetailsForCompany?companyId=' + sessionStorage.getItem('companyId')), {})
            .then(data => {
                if (data.length > 0) {
                    const store = new ArrayStore(
                        {
                            key: "id",
                            data: data as APIGprsDetail[]
                        });

                    me.setState(
                        {
                            gprsDetails: new DataSource({ store: store }),
                        });
                }
                else {
                    console.log("No gprsDetails");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });
    }



    loadTimeframe = (count: number, transInterval: number): string => {
        console.log(count, transInterval)
        if (transInterval === 5) {
            if (count === 1) {
                return '5mins';
            } else if (count === 2) {
                return '10mins';
            } else if (count === 3) {
                return '15mins';
            } else if (count === 6) {
                return '30mins';
            } else if (count === 12) {
                return '1hour';
            } else if (count === 36) {
                return '3hour';
            } else if (count === 72) {
                return '6hour';
            } else if (count === 144) {
                return '12hour';
            } else if (count === 288) {
                return '24hour';
            }
        } else if (transInterval === 15) {
            if (count === 1) {
                return '15mins';
            } else if (count === 2) {
                return '30mins';
            } else if (count === 4) {
                return '1hour';
            } else if (count === 12) {
                return '3hour';
            } else if (count === 24) {
                return '6hour';
            } else if (count === 48) {
                return '12hour';
            } else if (count === 96) {
                return '24hour';
            }
        } else if (transInterval === 30) {
            if (count === 1) {
                return '30mins';
            } else if (count === 2) {
                return '1hour';
            } else if (count === 6) {
                return '3hour';
            } else if (count === 12) {
                return '6hour';
            } else if (count === 24) {
                return '12hour';
            } else if (count === 48) {
                return '24hour';
            }
        } else if (transInterval === 60) {
            if (count === 1) {
                return '1hour';
            } else if (count === 3) {
                return '3hour';
            } else if (count === 6) {
                return '6hour';
            } else if (count === 12) {
                return '12hour';
            } else if (count === 24) {
                return '24hour';
            }
        } else if (transInterval === 180) {
            if (count === 1) {
                return '3hour';
            } else if (count === 2) {
                return '6hour';
            } else if (count === 4) {
                return '12hour';
            } else if (count === 8) {
                return '24hour';
            }
        } else if (transInterval === 360) {
            if (count === 1) {
                return '6hour';
            } else if (count === 2) {
                return '12hour';
            } else if (count === 4) {
                return '24hour';
            }
        } else if (transInterval === 720) {
            if (count === 1) {
                return '12hour';
            } else if (count === 2) {
                return '24hour';
            }
        } else if (transInterval === 1440) {
            if (count === 1) {
                return '24hour';
            }
        }
        return '';
    };



    loadAlarmConfigs(): void {
        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/LCAlarmConfigurations?serial=' + this.props.serial), {})
            .then((data) => {
                    const recs = new Array<ChariotAlarm>();
                    let alarmMask = 1;  // 16 bit mask for alarm channel
                    const loggerId = data[0].fkLCLoggerId;
                const interval = this.state.deviceConfig?.TransmissionInterval ? this.state.deviceConfig?.TransmissionInterval : 0;
                    for (let i = 1; i <= 16; i++) {
                        const alarmConfig: ChariotAlarm = {
                            Idx: i,
                            Id: 0,
                            AlarmMask: alarmMask,
                            AlarmMessage: "",
                            AlarmClass: 0,
                            SetInput: 0,
                            SetOperator: 0,
                            SetThreshold: 0,
                            SetThresholdStr: "0",
                            ClrInput: 0,
                            ClrOperator: 0,
                            ClrThreshold: 0,
                            ClrThresholdStr: "0",
                            SetCount: 0,
                            SetCountStr: "0",
                            ClrCount: 0,
                            ClrCountStr: "0",
                            MaxAlarms: 0,
                            MaxAlarmsStr: "0",
                            FkLCLoggerId: loggerId,
                        }

                        if (data.length > 0) {
                            data.map((a: any) => {
                                console.log(a)
                                if (a.alarmMask == alarmMask) {
                                    alarmConfig.Id = a.id;
                                    alarmConfig.AlarmMessage = a.alarmMessage;
                                    alarmConfig.AlarmClass = a.alarmClass;
                                    alarmConfig.SetInput = a.setInput;
                                    alarmConfig.SetOperator = a.setOperator;
                                    alarmConfig.SetThreshold = a.setThreshold;
                                    alarmConfig.SetThresholdStr = a.setThreshold.toString();
                                    alarmConfig.ClrInput = a.clrInput;
                                    alarmConfig.ClrOperator = a.clrOperator;
                                    alarmConfig.ClrThreshold = a.clrThreshold;
                                    alarmConfig.ClrThresholdStr = a.clrThreshold.toString();
                                    alarmConfig.SetCount = a.setCount;
                                    alarmConfig.SetCountStr = this.loadTimeframe(a.setCount, interval);
                                    alarmConfig.ClrCount = a.clrCount;
                                    alarmConfig.ClrCountStr = this.loadTimeframe(a.clrCount, interval);
                                    alarmConfig.MaxAlarms = a.maxAlarms;
                                    alarmConfig.MaxAlarmsStr = a.maxAlarms.toString();

                                }
                            });
                        }

                        recs.push(alarmConfig);

                        alarmMask = alarmMask << 1;
                    }

                    me.setState(
                        {
                            alarms: recs,
                        });
                },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function () {
                me.createLoadErrorNotification();
            });

    }

    loadConfigTemplates(): void {
        const me = this;
        CallGetAPI(CreateUrl('/api/aquaguard/ConfigTemplatesForCompany?companyId=' + sessionStorage.getItem('companyId') + '&product=' + this.props.product + '&model=' + this.props.model), {})
            .then(data => {
                if (data.length > 0) {

                    me.setState(
                        {
                            templates: data as APIConfigTemplate[],
                        });
                }
                else {
                    console.log("No config templates");
                }
            },
                // reject() - API error
                () => {
                    me.createLoadErrorNotification();
                    console.log("API Error");

                })
            .catch(function (error) {
                console.log(error);
                me.createLoadErrorNotification();
            });
    }

    createSuccessNotification = (msg: string): void => {
        NotificationManager.success(msg, 'Success');
 
    };

    createErrorNotification = (type: string): void => {
        NotificationManager.error('Error Saving ' + type, 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    createLoadErrorNotification = (): void => {
        NotificationManager.error('Error Loading config data ', 'Click me!', 5000, () => {
            alert('callback');
        });
    };


    //save changes, for both edit and new
    saveConfig = (): void => {

        //********************
        //send config update to API

        const me = this;

        const updateJson = jsonFromConfig(this.state.orgConfig,
                                        this.state.deviceConfig
                                        );
        if (updateJson != "{}") {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ serial: me.props.serial, updateJson: updateJson, sendConfigAfter: this.state.sendAfter, isChariot: true })
            };

            CallPostAPI(CreateUrl('/api/Aquaguard/LoggerConfigurations'), requestOptions)
                .then(response => {
                    if (response.ok) {
                        me.createSuccessNotification('Device will be updated on next connection');
                    }
                    else {
                        me.createErrorNotification('Error Saving Config updates');
                    }
                    console.log(response);
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config updates');
                    console.log(error);
                });
        }

    }

    // Menu click handlers

    handleReadClick = (): void => {

        const me = this;

        CallGetAPI(CreateUrl('/api/aquaguard/CurrentLCLoggerConfiguration?serial=' + this.props.serial), {})
            .then(data => {
                if (data.status === undefined) {
                    const config = configFromJson(this.props.product, 
                        data,
                    );
                        
                    me.setState(
                        {
                            orgConfig: JSON.parse(JSON.stringify(config)),   // Save DEEP CLONE of original
                            deviceConfig: config,
                            anchorEl: null,
                            ready: true,
                            enableSend: false,
                            configDate: data.startTime,
                            battery: data.readBatteryChannel / 1000,
                            channelsAvail: this.props.channels ? this.props.channels : 0,
                            transducer1: data.transducer1,
                            transducer2: data.transducer2,
                        });

                }
            },
                // reject() - API error
                () => {
                    console.log("API Error");

                })
            .catch(function (error) {
                console.log(error);
            });

    }

    handleSendClick = (): void => {

        // Evaluate numeric fields
        const config = Object.assign({}, this.state.deviceConfig);
        if (!isNaN(parseFloat(config.FlowAMultiplierStr))) {
            config.FlowAMultiplier = parseFloat(config.FlowAMultiplierStr);
        }
        else {
            alert("Please correct Flow Multiplier");
            return;
        }


        if (this.state.orgConfig?.FlowAMultiplier != config.FlowAMultiplier) {
            alert("Warning, changing the Pulse Multiplier will affect the accuracy of the meter totaliser / meter read, please proceed with caution");
        }

        this.setState({ deviceConfig: config, panel: "Save Config"})
    }

    
    handleSendAfterClick = (): void => {
        if (this.state.deviceConfig) {
            if (this.props.onConfigSend ) {
                this.props.onConfigSend(jsonFromConfig(
                    this.state.orgConfig,
                    this.state.deviceConfig,
                ));
            }
            else {
                this.saveConfig();
            }
        }
        this.setState({ enableSend: false, panel: "Config" });
    }
    
    // Config Templates
    handleLoadTemplate = (): void => {

        const template = this.state.templates.filter((t) => t.id == this.state.templateSelect)[0];
        const deviceConfig: ChariotConfig = JSON.parse(template.configJson);

        if (this.state.deviceConfig) {
            deviceConfig.Sitename = this.state.deviceConfig.Sitename;
            deviceConfig.Latitude = this.state.deviceConfig.Latitude;
            deviceConfig.Longitude = this.state.deviceConfig.Longitude;
            deviceConfig.CurrentState = this.state.deviceConfig.CurrentState;
            deviceConfig.Guid = this.state.deviceConfig.Guid;
            deviceConfig.SWversion = this.state.deviceConfig.SWversion;
        }
        else {
            deviceConfig.Sitename = "";
            deviceConfig.Latitude = null;
            deviceConfig.Longitude = null;
            deviceConfig.CurrentState = "Unknown";
            deviceConfig.Guid = "";
            deviceConfig.SWversion = "";

        }

        this.setState({
            deviceConfig: deviceConfig,
            anchorEl: null,
            ready: true,
            enableSend: false,
            configDate: new Date(),
            battery: 0,
        });

        this.handleTemplateMenuClose();
    }

    handleSaveTemplate = (): void => {
        const me = this;
        const existing = this.state.templates.filter((t) => t.name == this.state.templateName);

        if (existing.length == 0) {
            const template: APIConfigTemplate = {
                id: 0,
                fkCompanyId: parseInt(sessionStorage.getItem('companyId') || "0"),
                name: this.state.templateName,
                product: this.props.product,
                model: this.props.model,
                lastUpdate: new Date(),
                configJson: JSON.stringify(this.state.deviceConfig)
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(template)
            };

            CallGetAPI(CreateUrl('/api/Aquaguard/ConfigTemplate'), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        template.id = data.id;
                        template.lastUpdate = data.lastUpdate;
                        const templates = me.state.templates;
                        templates.push(template);
                        me.setState({ templates: templates, templateSelect: template.id });

                        me.createSuccessNotification("Config template saved");
                    }
                    else {
                        me.createErrorNotification('Error Saving Config template Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config template Changes');
                    console.log(error);
                });
        }
        else {
            // Update existing template
            const template = existing[0];
            template.configJson = JSON.stringify(this.state.deviceConfig);

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(template)
            };

            CallGetAPI(CreateUrl('/api/Aquaguard/ConfigTemplate?id=' + template.id), requestOptions)
                .then(data => {
                    if (data.status === undefined) {
                        template.lastUpdate = data.lastUpdate;
                        const templates = me.state.templates;
                        templates.push(template);
                        me.setState({ templates: templates, templateSelect: template.id });

                        me.createSuccessNotification("Config template updated");
                    }
                    else {
                        me.createErrorNotification('Error Saving Config template Changes');
                    }
                })
                .catch(function (error) {
                    me.createErrorNotification('Error Saving Config template Changes');
                    console.log(error);
                });
        }

        this.handleTemplateMenuClose();
    }

    // Menu handling
    handleViewClick = (event: any): void => {
        this.setState({
            openViewMenu: !this.state.openViewMenu,
            anchorEl: event.currentTarget
        });
    }
    handleTemplateClick = (event: any): void => {
        this.setState({
            openTemplateMenu: !this.state.openTemplateMenu,
            anchorEl: event.currentTarget
        });
    }

    showSimDetails = (): void => {
        this.setState({ openViewMenu: false, panel: "SIM Details" });
    }

    showGprsDetails = (): void => {
        this.setState({ openViewMenu: false, panel: "GPRS Details" });
    }

    showPhoneNumbers = (): void => {
        this.setState({ openViewMenu: false, panel: "Phone Numbers" });
    }
    showLoadTemplate = (): void => {
        this.setState({ openTemplateMenu: false, panel: "Load Template" });
    }
    showSaveTemplate = (): void => {
        this.setState({ openTemplateMenu: false, panel: "Save Template" });
    }

    handleViewMenuClose = (): void => {
        this.setState({ openViewMenu: false, anchorEl: null, panel: "Config" });
    }
    handleTemplateMenuClose = (): void => {
        this.setState({ openTemplateMenu: false, anchorEl: null, panel: "Config" });
    }

    // Field change handlers
    handleTextfieldChange = (e: any): void => {

        // Max text field lengths
        const maxLen = {
            Sitename: 25,
            FlowARef: 15,
            FlowBRef: 15,
            AnalogueCRef: 15,
            AnalogueDRef: 15,
            AnalogueCDp: 2,
            AnalogueDDp: 2,
            FlowAMultiplier: 7,
            AnalogueCMultiplier: 7,
            AnalogueDMultiplier: 7,
        };


        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const config = Object.assign({}, this.state.deviceConfig);
        const errors = this.state.fieldErrors;
        if (inputValue.length <= maxLen[inputName]) {
            if (inputName.endsWith("Multiplier")) {
                // NOTE parseFloat ignores any non-digits AFTER a number, so check last char is digit
                if (isNaN(parseFloat(inputValue)) || (inputValue.slice(-1) != "." && isNaN(parseFloat(inputValue.slice(-1)))) ) {
                    errors[inputName] = true;
                }
                else {
                    errors[inputName] = false;
                }
                config[inputName + "Str"] = inputValue;
            }
            else {
                if (inputName.endsWith("Dp") ) {
                    if (!isNaN(parseInt(inputValue))) {
                        config[inputName] = inputValue;
                    }
                }
                else {
                    config[inputName] = inputValue;
                }
            }
        }
        this.setState({ deviceConfig: config, enableSend: true, fieldErrors: errors});

    }

    handleToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;

        if (inputName == "FlowA" && inputValue) {
            // Default set Type, units & interval
            config.FlowAPulseType = 1;
            config.FlowAUnits = "01";
            config.FlowASchedule = 900;
        }
        if (inputName == "AnalogueC" && inputValue) {
            // Default set Type, units & interval
            config.AnalogueCUnits = "03";
            config.AnalogueCSchedule = 900;
        }
        if (inputName == "AnalogueDC" && inputValue) {
            // Default set Type, units & interval
            config.AnalogueDUnits = "03";
            config.AnalogueDSchedule = 900;
        }
        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleSelectChange = (e: any): void => {
        
        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        
        const config = Object.assign({}, this.state.deviceConfig);
        if (typeof config[inputName] == 'number') {
            config[inputName] = parseInt(inputValue);
        }
        else {
            config[inputName] = inputValue;
        }
        this.setState({ deviceConfig: config, enableSend: true, });

    }

    handleFlowTypeChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;

        this.setState({ deviceConfig: config, enableSend: true, });
    }
    handleNumericChange = (valueAsNumber: number, valueAsString: string, input: any): void => {

        const inputName: string = input.name;
        const inputValue: number = valueAsNumber;
        const config = Object.assign({}, this.state.deviceConfig);
        config[inputName] = inputValue;

        this.setState({ deviceConfig: config, enableSend: true, });

    }


    handleTemplateChange = (e: any): void => {
        this.setState({ templateSelect: e.target.value, });
    }
    handleTemplateNameChange = (e: any): void => {
        const newName = e.target.value;
        if (newName.length < 50) {
            const existing = this.state.templates.filter((t) => t.name == newName);
            if (existing.length == 0) {
                this.setState({ templateName: newName, templateNameError: false, });
            }
            else {
                this.setState({
                    templateName: newName,
                    templateNameError: true,
                    templateNameErrorMsg: "Template exists - config will be updated"
                });
            }
        }
        else {
            this.setState({templateNameError: true, templateNameErrorMsg: "Too long - max 50 chars"})
        }
    }


    handleLoggerHold = (latitude: number, longitude: number): void => {
        console.log("Hold at " + latitude + "," + longitude);
        // Check if hold is on logger spot
        if (Math.abs(latitude - (this.state.deviceConfig?.Latitude || 0)) < 0.001
            && Math.abs(longitude - (this.state.deviceConfig?.Longitude || 0)) < 0.001
        ) {
            this.setState({ loggerHold: true });
        }
    }

    handleLoggerHoldCancel = (): void => {
        this.setState({ loggerHold: false });
    }

    setLoggerLocation = (latitude: number, longitude: number): void => {
        if (this.state.loggerHold) {
            console.log("Release at " + latitude + "," + longitude);
            const config = this.state.deviceConfig;
            if (config) {
                config.Latitude = latitude;
                config.Longitude = longitude;
                this.setState({ deviceConfig: config, enableSend: true, loggerHold: false });
            }
        }

    }

    handleLatitudeChange = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            const val = event.target.value == null ? config.Latitude?.toString() || "0" : event.target.value;
            config.Latitude = parseFloat(val);
            this.setState({ deviceConfig: config, enableSend: true, });
        }

    }
    handleLongitudeChange = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            const val = event.target.value == null ? config.Latitude?.toString() || "0" : event.target.value;
            config.Longitude = parseFloat(val);
            this.setState({ deviceConfig: config, enableSend: true, });
        }

    }

    handleSendAfterChange = (e: Moment): void => {
        this.setState({ sendAfter: e.seconds(0).toDate() });
    }




     // Data Grid event & render handlers
    typeEditorRender = (cell: any): ReactNode => {
        const onValueChanged = this.onTypeChanged.bind(this, cell);
        return <SelectBox
            defaultValue={cell.value}
            dataSource={[
                { value: "Server" },
                { value: "User" }
            ]}
            valueExpr="value"
            displayExpr="value"
            onValueChanged={onValueChanged}
        />;
    }

    onTypeChanged = (cell: any, e: any): void => {
        cell.setValue(e.value);
    }

    // Display render functions
    makeDecimal(val: number | null | undefined): string {

        if (val) {
            if (isNaN(parseInt(val.toString()))) {
                return val + ".0";
            }
            else {
                return val.toFixed(3).toString();
            }
        }
        else {
            return "0.0";
        }
    }

    // Alarm settings
    chariotUnits = (val: string): string => {
        switch (val) {
            case "01":
                return "litres";
            case "02":
                return "m3";
            case "03":
                return "bar";
            case "04":
                return "mb";
            case "05":
                return "mH2O";
            case "06":
                return "psi";
            default:
                return "";

        }
    }

    displayUnits = (alarmType: number | undefined, units: string): string => {
        let ret = units;
        if (alarmType == 1) {
            // Flow alarm
            if (units == 'litres' || units === 'l/s') {
                ret = 'l/s';
            }
            else {
                ret = 'm3/h';
            }
        }

        return ret;
    }

    handleAlarmTextfieldChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const alarm = Object.assign({}, this.state.editAlarm);
        const errors = this.state.fieldErrors;
        if (inputName == "AlarmMessage") {
            if (inputValue.length <= 4000) {
                alarm[inputName] = inputValue;
            }
        }
        else {
            if (inputName.endsWith("Threshold")) {
                // Check it's decimal value
                // NOTE parseFloat ignores any non-digits AFTER a number, so check last char is digit
                if (isNaN(parseFloat(inputValue)) || (inputValue.slice(-1) != "." && isNaN(parseFloat(inputValue.slice(-1))))) {
                    errors[inputName] = true;
                }
                else {
                    errors[inputName] = false;
                }
                alarm[inputName + "Str"] = inputValue;

            }
            else {
                // Check it's integer value
                if (isNaN(parseInt(inputValue)) || inputValue.slice(-1) == "." || isNaN(parseInt(inputValue.slice(-1)))) {
                    errors[inputName] = true;
                }
                else {
                    errors[inputName] = false;
                }
                alarm[inputName + "Str"] = inputValue;
            }
        }
        this.setState({ editAlarm: alarm });

    };

    handleAlarmToggleChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: boolean = e.target.checked;
        const alarm = Object.assign({}, this.state.editAlarm);
        alarm[inputName] = inputValue;

        this.setState({ editAlarm: alarm });

    };

    handleAlarmSelectChange = (e: any): void => {

        const inputName: string = e.target.name;
        const inputValue: string = e.target.value;
        const alarm = Object.assign({}, this.state.editAlarm);
        if (inputName === 'SetCountStr' || inputName === 'ClrCountStr') {
            alarm[inputName] = inputValue;
        } else {
            alarm[inputName] = parseInt(inputValue);
        }
        let setUnits = this.state.setUnits;
        let clrUnits = this.state.clrUnits;
        console.log(inputValue, inputName)
        // Copy Set input to Clr if Clr not set and set Units
        if (inputName.startsWith("SetInput")) {
            if (inputValue === "0" && this.state.deviceConfig?.FlowAUnits) {
                setUnits = this.chariotUnits("00")
            }
            if (inputValue == "1" && this.state.deviceConfig?.FlowAUnits) {
                setUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
                console.log(setUnits)
                if (setUnits === "litres") {
                    setUnits = "l/s";
                }
                else {
                    setUnits = "m3/h";
                }
            }
            console.log(setUnits)
            if (inputValue == "2" && this.state.deviceConfig?.FlowAUnits) {
                setUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
                if (setUnits === "litres") {
                    setUnits = "l/s";
                }
                else {
                    setUnits = "m3/h";
                }
            }
            if ((inputValue == "3" || inputValue == "11") && this.state.deviceConfig?.AnalogueCUnits) {
                setUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueCUnits);
            }
            if ((inputValue == "7" || inputValue == "12") && this.state.deviceConfig?.AnalogueDUnits) {
                setUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueDUnits);
            }
            if (inputValue == "30") {
                setUnits = "";
            }
            if (inputValue == "31") {
                setUnits = "mV";
            }
            if (alarm.ClrInput == 0) {
                alarm.ClrInput = alarm.SetInput;
                clrUnits = setUnits;
            }
        }
        if (inputName.startsWith("ClrInput")) {
            if (inputValue === "0" && this.state.deviceConfig?.FlowAUnits) {
                clrUnits = this.chariotUnits("00")
            }
            if (inputValue == "1" && this.state.deviceConfig?.FlowAUnits) {
                clrUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
                if (clrUnits == "litres") {
                    clrUnits = "l/s";
                }
                else {
                    clrUnits = "m3/h";
                }
            }
            if (inputValue == "2" && this.state.deviceConfig?.FlowAUnits) {
                clrUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
            }
            if ((inputValue == "3" || inputValue == "11") && this.state.deviceConfig?.AnalogueCUnits) {
                clrUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueCUnits);
            }
            if ((inputValue == "7" || inputValue == "12") && this.state.deviceConfig?.AnalogueDUnits) {
                clrUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueDUnits);
            }
            if (inputValue == "30") {
                clrUnits = "";
            }
            if (inputValue == "31") {
                clrUnits = "mV";
            }
        }

        this.setState({ editAlarm: alarm, setUnits: setUnits, clrUnits:  clrUnits });

    }

    handleCreateAlarms = ():void => {
       
        for (let i = 0; i < this.state.alarms.length; i++) {
            if (!this.state.alarms[i].AlarmMessage) {
                this.handleAlarmRowDblClick(i + 1)
                console.log(i + 1)
                return
            }
        }

        
    }

    handleAlarmRowDblClick = (index: any): void => {
        console.log(index)
        const interval = this.state.deviceConfig?.TransmissionInterval ? this.state.deviceConfig?.TransmissionInterval : 0;
        const alarm = Object.assign({}, this.state.alarms[index -1]);
        let setUnits = "";
        let clrUnits = "";
        console.log(alarm)
        // Set units based on set/clrInput
        if (alarm.SetInput == 1 && this.state.deviceConfig?.FlowAUnits) {
            setUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
        }
        if (alarm.SetInput == 2 && this.state.deviceConfig?.FlowAUnits) {
            setUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
        }
        if ((alarm.SetInput == 3 || alarm.SetInput == 11) && this.state.deviceConfig?.AnalogueCUnits) {
            setUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueCUnits);
        }
        if ((alarm.SetInput == 7 || alarm.SetInput == 12) && this.state.deviceConfig?.AnalogueDUnits) {
            setUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueDUnits);
        }
        if (alarm.SetInput == 30) {
            setUnits = "";
        }
        if (alarm.SetInput == 31) {
            setUnits = "mV";
        }

        if (alarm.ClrInput == 1 && this.state.deviceConfig?.FlowAUnits) {
            clrUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
        }
        if (alarm.ClrInput == 2 && this.state.deviceConfig?.FlowAUnits) {
            clrUnits = this.chariotUnits(this.state.deviceConfig?.FlowAUnits);
        }
        if ((alarm.ClrInput == 3 || alarm.ClrInput == 11) && this.state.deviceConfig?.AnalogueCUnits) {
            clrUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueCUnits);
        }
        if ((alarm.ClrInput == 7 || alarm.ClrInput == 12) && this.state.deviceConfig?.AnalogueDUnits) {
            clrUnits = this.chariotUnits(this.state.deviceConfig?.AnalogueDUnits);
        }
        if (alarm.ClrInput == 30) {
            clrUnits = "";
        }
        if (alarm.ClrInput == 31) {
            clrUnits = "mV";
        }

        alarm.SetCountStr = this.loadTimeframe(alarm.SetCount, interval)
        alarm.ClrCountStr = this.loadTimeframe(alarm.ClrCount, interval)
        this.setState({ editAlarm: alarm, alarmTab: 1, setUnits: setUnits, clrUnits: clrUnits });

    }
    handleAlarmTabChange = (e: any, v: any): void => {
        console.log(v)
        this.setState({ alarmTab: v });

    }

    
    countModifier = (count: string, transInterval: number): number => {
        if (transInterval === 5) {
            if (count === '5mins') {
                return 1
            } else if (count === '10mins') {
                return 2
            } else if (count === '15mins') {
                return 3
            } else if (count === '30mins') {
                return 6
            } else if (count === '1hour') {
                return 12
            } else if (count === '3hour') {
                return 36
            } else if (count === '6hour') {
                return 72
            } else if (count === '12hour') {
                return 144
            } else if (count === '24hour') {
                return 288
            }
        } else if (transInterval === 15) {
            if (count === '15mins') {
                return 1
            } else if (count === '30mins') {
                return 2
            } else if (count === '1hour') {
                return 4
            } else if (count === '3hour') {
                return 12
            } else if (count === '6hour') {
                return 24
            } else if (count === '12hour') {
                return 48
            } else if (count === '24hour') {
                return 96
            }
        } else if (transInterval === 30) {
            if (count === '30mins') {
                return 1
            } else if (count === '1hour') {
                return 2
            } else if (count === '3hour') {
                return 6
            } else if (count === '6hour') {
                return 12
            } else if (count === '12hour') {
                return 24
            } else if (count === '24hour') {
                return 48
            }
        } else if (transInterval === 60) {
            if (count === '1hour') {
                return 1
            } else if (count === '3hour') {
                return 3
            } else if (count === '6hour') {
                return 6
            } else if (count === '12hour') {
                return 12
            } else if (count === '24hour') {
                return 24
            }
        } else if (transInterval === 180) {
            if (count === '3hour') {
                return 1
            } else if (count === '6hour') {
                return 2
            } else if (count === '12hour') {
                return 4
            } else if (count === '24hour') {
                return 8
            }
        } else if (transInterval === 360) {
            if (count === '6hour') {
                return 1
            } else if (count === '12hour') {
                return 2
            } else if (count === '24hour') {
                return 4
            }
        } else if (transInterval === 720) {
            if (count === '12hour') {
                return 1
            } else if (count === '24hour') {
                return 2
            }
        } else if (transInterval === 1440) {
            if (count === '24hour') {
                return 1
            }
        }     
        return 0;
    }
    updateAlarm = (): void => {

        // Call API to update AlarmConfig

        const alarm = this.state.editAlarm;
        const interval = this.state.deviceConfig?.TransmissionInterval ? this.state.deviceConfig?.TransmissionInterval : 0;
        if (alarm) {
            alarm.SetThreshold = parseFloat(alarm?.SetThresholdStr);
            alarm.ClrThreshold = parseFloat(alarm?.ClrThresholdStr);
            alarm.MaxAlarms = parseInt(alarm?.MaxAlarmsStr);
            alarm.SetCount = this.countModifier(alarm?.SetCountStr, interval);
            alarm.ClrCount = this.countModifier(alarm?.ClrCountStr, interval);
            
            // Check for any missing fields
            if (alarm.AlarmClass == 0) {
                alert("You must set a severity");
                return;
            }
            if (alarm.AlarmMessage.length == 0) {
                alert("Please define an alarm message")
                return;
            }
        }
        console.log(alarm)

        const me = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(alarm)
        };
        
        CallGetAPI(CreateUrl(`/api/aquaguard/ContactsForCompany?companyId=${sessionStorage.getItem('companyId')}`), {})
            .then(data => {
                if (data.length === 0) {
                    alert('You have no contacts in your contact details in your contact list to receive these alarms, please add some')
                }
                CallGetAPI(CreateUrl('/api/Aquaguard/LCAlarmConfiguration'), requestOptions)
                    .then(data => {
                        if (data.status === undefined) {
                            const alarm = me.state.editAlarm;
                            if (alarm) {
                                alarm.Id = data.id;
                            }

                            // Save current editAlarm details in config
                            const alarms = me.state.alarms;
                            if (alarms && alarm) {
                                alarms[alarm.Idx - 1] = alarm;
                                me.setState({ alarms: alarms });
                            }
                            me.setState({ editAlarm: undefined, alarmTab: 0 });

                            me.createSuccessNotification("Alarm Config saved");
                        }
                        else {
                            me.createErrorNotification('Error Saving Alarm Config Changes');
                        }
                    })
                    .catch(function (error) {
                        me.createErrorNotification('Error Saving Alarm Config Changes');
                        console.log(error);
                    });
            })
            .catch(err => {
                me.createErrorNotification('There was an error checking your contacts list')
                console.log(err)
            })

    }

    deleteAlarm(id: number | undefined): void {
        if (window.confirm("Are you sure you want to delete the alarm?")) {
            CallDeleteAPI(CreateUrl(`/api/aquaguard/LCLAlarmConfig/${id}`), {})
                .then(async data => {
                    await this.loadAlarmConfigs()
                    this.setState({ alarmTab: 0 })
                })
                .catch(function (error) {

                    console.log(error);
                });
        }
    }

    // Display render functions
    inputColumnCustomizeText(cellInfo: any): string {
        switch (cellInfo.valueText) {
            case '1':
                return "Flow1";
            case '9':
                return "Flow2";
            case '3':
                return "Latest Pressure 1";
            case '7':
                return "Latest Pressure 2";
            case '11':
                return "Pressure 1 Difference";
            case '12':
                return "Pressure 2 Difference";
            case '21':
                return "Consumption1";
            case '22':
                return "Consumption2";
            case '30':
                return "Signal Strength (0-31)";
            case '31':
                return "Battery (mV)";
            default:
                return "";
        }


    }

    classColumnCustomizeText(cellInfo: any): string {
        switch (cellInfo.valueText) {
            case '7':
                return "Low";
            case '6':
                return "Normal";
            case '5':
                return "High";
            case '4':
                return "Critical";
            default:
                return "Not set";
        }
    }

    configUpdateChanged = (event: any): void => {
        const config = this.state.deviceConfig;
        if (config) {
            config.Custom = event.target.value;
            this.setState({ deviceConfig: config, enableSend: true, });
        }
    }

    is2FlowChariot = (swVersion: string | undefined): boolean => {
        if (swVersion)
            return swVersion >= "2.4.1";   // Firmware version >= 2.5, i.e. has second flow
        else
            return false;
    }

    // Renders the appropriate SELECT items based on the logging interval (in secs)
    generateSamplingIntervals = (logInterval: number | undefined): Array<{ value: string, label: string }> => {
        if (logInterval) {
            switch (logInterval) {
                case 30:
                    return [{ value: "15", label: "15s" }]
                case 60:        // 1 min
                    return [
                        { value: "15", label: "15s" },
                        { value: "20", label: "20s" },
                        { value: "30", label: "30s" },
                    ]
                case 300:       // 5 min
                    return [
                        { value: "15", label: "15s" },
                        { value: "20", label: "20s" },
                        { value: "30", label: "30s" },
                        { value: "60", label: "1m" },
                    ]
                case 600:     // 10 min
                    return [
                        { value: "15", label: "15s" },
                        { value: "20", label: "20s" },
                        { value: "30", label: "30s" },
                        { value: "60", label: "1m" },
                        { value: "120", label: "2m" },
                    ]
                case 900:     // 15 min
                    return [
                        { value: "15", label: "15s" },
                        { value: "20", label: "20s" },
                        { value: "30", label: "30s" },
                        { value: "45", label: "45s" },
                        { value: "60", label: "1m"},
                        { value: "180", label: "3m" },
                    ]
                case 1800:     // 30 min
                    return [
                        { value: "30", label: "30s" },
                        { value: "45", label: "45s" },
                        { value: "60", label: "1m" },
                        { value: "120", label: "2m" },
                        { value: "180", label: "3m" },
                        { value: "300", label: "5m" },
                        { value: "600", label: "10m" },
                        { value: "900", label: "15m" },
                    ]

                case 3600:     // 60 min
                    return [
                        { value: "60", label: "1m" },
                        { value: "120", label: "2m" },
                        { value: "180", label: "3m" },
                        { value: "240", label: "4m" },
                        { value: "300", label: "5m" },
                        { value: "600", label: "10m" },
                        { value: "900", label: "15m" },
                    ]

                default:
                    return []
            }
        }

        return []
    }

    render(): ReactNode {

        const classes = makeStyles(style);

        const blockUpdate = this.state.deviceConfig?.SWversion.length == 5 && (this.state.deviceConfig?.SWversion.startsWith("1.1.0") || this.state.deviceConfig?.SWversion.startsWith("1.1.1"));

        return (
            <div>
                
                <div style={{marginTop: "10px"} }>
                    <Button
                        id="read-button"
                        onClick={this.handleReadClick}
                        color="secondary"
                        variant="contained"
                        style={{margin: "0 10px 0 17px"} }
                    >
                        {!this.state.enableSend || blockUpdate ? "Load Config" : "Reload Config"}
                    </Button>
                    <Button
                        id="send-button"
                        onClick={this.handleSendClick}
                        disabled={!this.state.enableSend || blockUpdate}
                        color="primary"
                        variant="contained"
                    >
                        Send To Logger
                    </Button>

                    {this.state.deviceConfig &&
                        <div style={{ float: "right", paddingRight: "30px", paddingTop: "5px" }}>
                        Configuration as at {moment(this.state.configDate).format("DD/MM/yyyy HH:mm")}
                        {this.state.deviceConfig.UpdateJSON &&
                            <div> including pending updates</div>
                        }
                        </div>
                    }
                </div>
                {this.state.panel == "Config" &&
                    <CustomTabs
                        headerColor="info"
                        tabs={[
                            {
                                tabName: "Site Details",
                                tabContent: (
                                    <div>
                                        <div>Unit Details</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Serial Number:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.serial}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Computer Time (UTC) [Last connection]
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {
                                                        this.state.deviceConfig?.LastConnection != null &&
                                                        moment(this.state.deviceConfig?.LastConnection).format("DD/MM/YYYY HH:mm:ss")
                                                    }
                                                </Grid>

                                                <Grid item xs={2}>
                                                    Product:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.product}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    Unit Time (UTC)
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {
                                                        this.state.deviceConfig?.LoggerTime != null &&
                                                        moment(this.state.deviceConfig?.LoggerTime).format("DD/MM/YYYY HH:mm:ss")
                                                    }
                                                </Grid>

                                                <Grid item xs={2}>
                                                    Model:
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.props.model}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    SW Version
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.state.deviceConfig?.SWversion}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {blockUpdate &&
                                                        <p color='red'>Cannot remotely update this version</p>
                                                    }
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Unit State</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    Current state
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {this.state.deviceConfig?.CurrentState}
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {this.state.deviceConfig?.CurrentState == "Commissioned" &&
                                                        <div>Use &quot;Undeploy&quot; to decommission the device.</div>
                                                    }
                                                </Grid>
                                                <Grid item xs={1}>
                                                    GUID:
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {this.state.deviceConfig?.Guid}
                                                </Grid>
                                                <Grid item xs={6}>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Site Details</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    Site Name:
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <TextField
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        size="small"
                                                        name="Sitename"
                                                        value={this.state.deviceConfig?.Sitename}
                                                        onChange={this.handleTextfieldChange.bind(this)}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Location
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div>
                                                        Lat: <TextField
                                                            fullWidth={false}
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            value={this.state.deviceConfig?.Latitude}
                                                            onChange={this.handleLatitudeChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div>
                                                        Long: <TextField
                                                            fullWidth={false}
                                                            variant="outlined"
                                                            size="small"
                                                            type="number"
                                                            value={this.state.deviceConfig?.Longitude}
                                                            onChange={this.handleLongitudeChange}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        {this.state.deviceConfig &&
                                            (this.state.loggerHold ?
                                                <div>
                                                    Now double click new location to reposition the logger. <Button size="small" variant="contained" color="primary" onClick={(): void => this.handleLoggerHoldCancel()}>Cancel</Button>
                                                </div>
                                                :
                                                <div>
                                                    To relocate logger, Click and hold to select.
                                                </div>)
                                        }
                                        <Box border={2} padding={3}>
                                            {this.state.ready &&
                                                (this.state.deviceConfig?.Longitude && this.state.deviceConfig?.Latitude) ? <WebMapView center={[this.state.deviceConfig?.Longitude, this.state.deviceConfig?.Latitude]}
                                                    height="30vh"
                                                    zoom={12}
                                                    colour={"primary"}
                                                    trunkSegments={[]}
                                                    regions={[]}
                                                    sites={[]}
                                                    showRegions={false}
                                                    showAllSites={false}
                                                    showRainfall={false}
                                                    alarmFilter={-1}
                                                    singlePoints={[
                                                        {
                                                            id: this.props.serial,
                                                            name: this.props.serial,
                                                            colour: this.state.loggerHold ? "red" : "black",
                                                            coordinates: [this.state.deviceConfig?.Longitude || 0, this.state.deviceConfig?.Latitude || 0],
                                                        }
                                                    ]}
                                                    singlePointsDesc="Logger"
                                                    onHold={this.handleLoggerHold}
                                                    onRelease={this.setLoggerLocation}
                                                    mouseWheelZoom={false}
                                            /> :
                                                <div style={{display: "flex", justifyContent: "center"} }>
                                                    <p>No GPS set for this device</p>
                                                </div>
                                            }
                                        </Box>
                                    </div>
                                )
                            },
                            {
                                tabName: "Communication",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        <div>General Settings</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    Preferred LPWAN
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Select
                                                        name="PreferredLPWAN"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.PreferredLPWAN}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="07" style={{width: "100px"} }>
                                                            <div style={{display: "flex"} }>
                                                                <ListItemIcon>
                                                                    <img src={lte} style={{width: "10%"} } />
                                                                </ListItemIcon>
                                                                
                                                            </div>
                                                        </MenuItem>

                                                        <MenuItem value="09" style={{ width: "100px" }}>
                                                            <ListItemIcon>
                                                                <img src={nbiot} style={{ width: "10%" }} />
                                                            </ListItemIcon>
                                                            
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4}>
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Server
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField name="MQTTServer" fullWidth={false} variant="outlined" size="small" value={this.state.deviceConfig?.MQTTServer} onChange={this.handleTextfieldChange.bind(this)} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4}>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <div>Data Transmission Schedule</div>
                                        <Box border={2} padding={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Switch size="small" color="secondary" checked={this.state.deviceConfig?.TransmissionInterval != undefined && this.state.deviceConfig?.TransmissionInterval?.valueOf() > 0} disabled={true} /> Enable
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4}>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Transmission Interval
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Select
                                                        name="TransmissionInterval"
                                                        size="small"
                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                        value={this.state.deviceConfig?.TransmissionInterval}
                                                        onChange={this.handleSelectChange.bind(this)}
                                                    >
                                                        <MenuItem value="0">Never</MenuItem>
                                                        <MenuItem value="1440">24 Hour</MenuItem>
                                                        <MenuItem value="720">12 Hour</MenuItem>
                                                        <MenuItem value="360">6 Hour</MenuItem>
                                                        <MenuItem value="180">3 Hour</MenuItem>
                                                        <MenuItem value="60">1 Hour</MenuItem>
                                                        <MenuItem value="30">30 Minutes</MenuItem>
                                                        <MenuItem value="15">15 Minutes</MenuItem>
                                                        {/*<MenuItem value="10">10 Minutes</MenuItem>*/}
                                                        <MenuItem value="5">5 Minutes</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                )
                            },
                            ((this.state.meterConfig & 0x01) != 0 ?
                                {
                                    tabName: "Flow Channel",
                                    disabled: !this.state.ready,

                                    tabContent: (
                                        <div>
                                            <div>
                                                {// Channel A
                                                    (this.state.meterConfig & 0x01) != 0 &&
                                                    <div>
                                                        <div>Flow Channel</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Switch name="FlowA" size="small" color="secondary" checked={this.state.deviceConfig?.FlowA} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Channel Reference
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="FlowARef" fullWidth={false} variant="outlined" size="small" value={this.state.deviceConfig?.FlowARef} onChange={this.handleTextfieldChange.bind(this)} disabled={!this.state.deviceConfig?.FlowA} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Input Type:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="FlowAPulseType"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowAPulseType}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        onChange={this.handleFlowTypeChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="1">Forward</MenuItem>
                                                                        <MenuItem value="2">Fwd/Rev</MenuItem>
                                                                        <MenuItem value="3">Fwd/Dir</MenuItem>
                                                                    </Select>

                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Pulse Multiplier
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="FlowAMultiplier"
                                                                        fullWidth={false}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={this.state.deviceConfig?.FlowAMultiplierStr}
                                                                        onChange={this.handleTextfieldChange.bind(this)}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        error={this.state.fieldErrors.FlowAMultiplier}
                                                                        helperText={this.state.fieldErrors.FlowAMultiplier ? "Must be DECIMAL number" : ""}
                                                                    />
                                                                    <Select
                                                                        name="FlowAUnits"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        size="small"
                                                                        value={this.state.deviceConfig?.FlowAUnits}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="01">Litres</MenuItem>
                                                                        <MenuItem value="02">m3</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Logging Interval
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="FlowASchedule"
                                                                        size="small"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowASchedule}
                                                                        disabled={!this.state.deviceConfig?.FlowA}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="43200">12 Hours</MenuItem>
                                                                        <MenuItem value="28800">8 Hours</MenuItem>
                                                                        <MenuItem value="21600">6 Hours</MenuItem>
                                                                        <MenuItem value="10800">3 Hours</MenuItem>
                                                                        <MenuItem value="3600">1 Hour</MenuItem>
                                                                        <MenuItem value="1800">30 Minutes</MenuItem>
                                                                        <MenuItem value="900">15 Minutes</MenuItem>
                                                                        <MenuItem value="600">10 Minutes</MenuItem>
                                                                        <MenuItem value="300">5 Minutes</MenuItem>
                                                                        <MenuItem value="60">1 Minutes</MenuItem>
                                                                        <MenuItem value="30">30 Seconds</MenuItem>
                                                                        <MenuItem value="15">15 Seconds</MenuItem>
                                                                        <MenuItem value="10">10 Seconds</MenuItem>
                                                                        <MenuItem value="1">1 Second</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                } : null ),
                            ((this.state.meterConfig & 0x02) != 0 ?
                                {
                                    tabName: "Flow Channel 2",
                                    disabled: !this.state.ready,

                                    tabContent: (
                                        <div>
                                            <div>
                                                {// Channel B
                                                    (this.state.meterConfig & 0x02) != 0 &&
                                                    <div>
                                                        <div>Flow Channel 2</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Switch name="FlowB" size="small" color="secondary" checked={this.state.deviceConfig?.FlowB} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Channel Reference
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="FlowBRef" fullWidth={false} variant="outlined" size="small" value={this.state.deviceConfig?.FlowBRef} onChange={this.handleTextfieldChange.bind(this)} disabled={!this.state.deviceConfig?.FlowB} />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Input Type:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="FlowBPulseType"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowBPulseType}
                                                                        disabled={!this.state.deviceConfig?.FlowB}
                                                                        onChange={this.handleFlowTypeChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="1">Forward</MenuItem>
                                                                        <MenuItem value="2">Fwd/Rev</MenuItem>
                                                                        <MenuItem value="3">Fwd/Dir</MenuItem>
                                                                    </Select>

                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Pulse Multiplier
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="FlowBMultiplier"
                                                                        fullWidth={false}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={this.state.deviceConfig?.FlowBMultiplierStr}
                                                                        onChange={this.handleTextfieldChange.bind(this)}
                                                                        disabled={!this.state.deviceConfig?.FlowB}
                                                                        error={this.state.fieldErrors.FlowBMultiplier}
                                                                        helperText={this.state.fieldErrors.FlowBMultiplier ? "Must be DECIMAL number" : ""}
                                                                    />
                                                                    <Select
                                                                        name="FlowBUnits"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        size="small"
                                                                        value={this.state.deviceConfig?.FlowBUnits}
                                                                        disabled={!this.state.deviceConfig?.FlowB}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="01">Litres</MenuItem>
                                                                        <MenuItem value="02">m3</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Logging Interval
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="FlowBSchedule"
                                                                        size="small"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.FlowBSchedule}
                                                                        disabled={!this.state.deviceConfig?.FlowB}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="43200">12 Hours</MenuItem>
                                                                        <MenuItem value="28800">8 Hours</MenuItem>
                                                                        <MenuItem value="21600">6 Hours</MenuItem>
                                                                        <MenuItem value="10800">3 Hours</MenuItem>
                                                                        <MenuItem value="3600">1 Hour</MenuItem>
                                                                        <MenuItem value="1800">30 Minutes</MenuItem>
                                                                        <MenuItem value="900">15 Minutes</MenuItem>
                                                                        <MenuItem value="600">10 Minutes</MenuItem>
                                                                        <MenuItem value="300">5 Minutes</MenuItem>
                                                                        <MenuItem value="60">1 Minutes</MenuItem>
                                                                        <MenuItem value="30">30 Seconds</MenuItem>
                                                                        <MenuItem value="15">15 Seconds</MenuItem>
                                                                        <MenuItem value="10">10 Seconds</MenuItem>
                                                                        <MenuItem value="1">1 Second</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                } : null),
                            ((this.state.meterConfig & 0x04) != 0 ?
                                    {
                                            tabName: "Pressure Channel 1",
                                            disabled: !this.state.ready,
                                            tabContent: (
                                                <div>

                                                {// Channel C
                                                    (this.state.meterConfig & 0x04) != 0 &&
                                                    <div>
                                                        <div>Pressure Channel 1</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <Switch name="AnalogueC" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueC} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                    </Grid>
                                                                    <Grid xs={8}>
                                                                        {this.state.transducer1 != null &&
                                                                            <div>
                                                                            {this.state.transducer1} Bar
                                                                            </div>
                                                                        }
                                                                    </Grid>
                                                                <Grid item xs={4}>
                                                                    Channel Ref:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="AnalogueCRef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueC} value={this.state.deviceConfig?.AnalogueCRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    Units
                                                                </Grid>

                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.AnalogueCUnits}
                                                                        name="AnalogueCUnits"
                                                                        disabled={!this.state.deviceConfig?.AnalogueC}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="03">Bar</MenuItem>
                                                                        <MenuItem value="04">millibar</MenuItem>
                                                                        <MenuItem value="05">mH20</MenuItem>
                                                                        <MenuItem value="06">psi</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Logging Interval
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="AnalogueCSchedule"
                                                                        size="small"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.AnalogueCSchedule}
                                                                        disabled={!this.state.deviceConfig?.AnalogueC}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="43200">12 Hours</MenuItem>
                                                                        <MenuItem value="28800">8 Hours</MenuItem>
                                                                        <MenuItem value="21600">6 Hours</MenuItem>
                                                                        <MenuItem value="10800">3 Hours</MenuItem>
                                                                        <MenuItem value="3600">1 Hour</MenuItem>
                                                                        <MenuItem value="1800">30 Minutes</MenuItem>
                                                                        <MenuItem value="900">15 Minutes</MenuItem>
                                                                        <MenuItem value="600">10 Minutes</MenuItem>
                                                                        <MenuItem value="300">5 Minutes</MenuItem>
                                                                        <MenuItem value="60">1 Minute</MenuItem>
                                                                        <MenuItem value="30">30 Seconds</MenuItem>
                                                                        <MenuItem value="10">10 Seconds</MenuItem>
                                                                        <MenuItem value="1">1 Second</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4} visibility="hidden">
                                                                    Multiplier:
                                                                </Grid>
                                                                    <Grid item xs={8} visibility="hidden">
                                                                        <TextField name="AnalogueCMultiplier"
                                                                            fullWidth={false}
                                                                            variant="outlined"
                                                                            size="small"
                                                                            disabled={!this.state.deviceConfig?.AnalogueC}
                                                                            value={this.state.deviceConfig?.AnalogueCMultiplierStr}
                                                                            onChange={this.handleTextfieldChange.bind(this)}
                                                                            error={this.state.fieldErrors.AnalogueCMultiplier}
                                                                            helperText={this.state.fieldErrors.AnalogueCMultiplier ? "Must be DECIMAL number" : ""}
                                                                        />
                                                                    </Grid>
                                                                    {this.is2FlowChariot(this.state.deviceConfig?.SWversion) &&
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                        <Switch name="AnalogueCSample" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueCSample && this.state.deviceConfig?.AnalogueCSample > 0} onChange={this.handleToggleChange.bind(this)} /> Enable Sampling
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    Sampling Interval
                                                                                </Grid>
                                                                             
                                                                                <Grid item xs={8}>
                                                                                    <Select
                                                                                        name="AnalogueCSampleTime"
                                                                                        size="small"
                                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                                        value={this.state.deviceConfig?.AnalogueCSampleTime}
                                                                                        disabled={this.state.deviceConfig?.AnalogueCSample == 0}
                                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                                        >
                                                                                            {this.generateSamplingIntervals(this.state.deviceConfig?.AnalogueCSchedule).map((v: { value: string, label: string }) => {
                                                                                                return <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                                                                            })
                                                                                            }
                                                                                    </Select>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                                </div>
                                        )
                                } : null),
                            ((this.state.meterConfig & 0x08) != 0 ?
                                    {
                                        tabName: "Pressure Channel 2",
                                        disabled: !this.state.ready,
                                        tabContent: (
                                            <div>
                                                {// Channel D
                                                    (this.state.meterConfig & 0x08) != 0 &&
                                                    <div>
                                                        <div>Pressure Channel 2</div>
                                                        <Box border={2} padding={3}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <Switch name="AnalogueD" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueD} onChange={this.handleToggleChange.bind(this)} /> Enable
                                                                </Grid>
                                                                <Grid xs={8}>
                                                                    {this.state.transducer2 != null &&
                                                                        <div>
                                                                            {this.state.transducer2} Bar
                                                                        </div>
                                                                    }
                                                                </Grid>
                                                                 <Grid item xs={4}>
                                                                    Channel Ref:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <TextField name="AnalogueDRef" fullWidth={false} variant="outlined" size="small" disabled={!this.state.deviceConfig?.AnalogueD} value={this.state.deviceConfig?.AnalogueDRef} onChange={this.handleTextfieldChange.bind(this)} />
                                                                </Grid>

                                                                <Grid item xs={4}>
                                                                    Units
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.AnalogueDUnits}
                                                                        name="AnalogueDUnits"
                                                                        disabled={!this.state.deviceConfig?.AnalogueD}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="03">Bar</MenuItem>
                                                                        <MenuItem value="04">millibar</MenuItem>
                                                                        <MenuItem value="05">mH20</MenuItem>
                                                                        <MenuItem value="06">psi</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Logging Interval
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Select
                                                                        name="AnalogueDSchedule"
                                                                        size="small"
                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                        value={this.state.deviceConfig?.AnalogueDSchedule}
                                                                        disabled={!this.state.deviceConfig?.AnalogueD}
                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                    >
                                                                        <MenuItem value="43200">12 Hours</MenuItem>
                                                                        <MenuItem value="28800">8 Hours</MenuItem>
                                                                        <MenuItem value="21600">6 Hours</MenuItem>
                                                                        <MenuItem value="10800">3 Hours</MenuItem>
                                                                        <MenuItem value="3600">1 Hour</MenuItem>
                                                                        <MenuItem value="1800">30 Minutes</MenuItem>
                                                                        <MenuItem value="900">15 Minutes</MenuItem>
                                                                        <MenuItem value="600">10 Minutes</MenuItem>
                                                                        <MenuItem value="300">5 Minutes</MenuItem>
                                                                        <MenuItem value="60">1 Minute</MenuItem>
                                                                        <MenuItem value="30">30 Seconds</MenuItem>
                                                                        <MenuItem value="10">10 Seconds</MenuItem>
                                                                        <MenuItem value="1">1 Second</MenuItem>
                                                                    </Select>
                                                                </Grid>
                                                                <Grid item xs={4} visibility="hidden">
                                                                    Multiplier:
                                                                </Grid>
                                                                <Grid item xs={8} visibility="hidden">
                                                                    <TextField name="AnalogueDMultiplier"
                                                                        fullWidth={false}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        disabled={!this.state.deviceConfig?.AnalogueD}
                                                                        value={this.state.deviceConfig?.AnalogueDMultiplier}
                                                                        onChange={this.handleTextfieldChange.bind(this)}
                                                                        error={this.state.fieldErrors.AnalogueDMultiplier}
                                                                        helperText={this.state.fieldErrors.AnalogueDMultiplier ? "Must be DECIMAL number" : ""}
                                                                    />
                                                                </Grid>
                                                                    {this.is2FlowChariot(this.state.deviceConfig?.SWversion) &&
                                                                        <Grid item xs={12}>
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={12}>
                                                                                    <Switch name="AnalogueDSample" size="small" color="secondary" checked={this.state.deviceConfig?.AnalogueDSample && this.state.deviceConfig?.AnalogueDSample > 0} onChange={this.handleToggleChange.bind(this)} /> Enable Sampling
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    Sampling Interval
                                                                                </Grid>

                                                                                <Grid item xs={8}>
                                                                                    <Select
                                                                                        name="AnalogueDSampleTime"
                                                                                        size="small"
                                                                                        style={{ margin: 0, paddingBottom: 0 }}
                                                                                        value={this.state.deviceConfig?.AnalogueDSampleTime}
                                                                                        disabled={this.state.deviceConfig?.AnalogueDSample == 0}
                                                                                        onChange={this.handleSelectChange.bind(this)}
                                                                                    >
                                                                                        {this.generateSamplingIntervals(this.state.deviceConfig?.AnalogueDSchedule).map((v: {value: string, label: string}) =>
                                                                                        {
                                                                                            return <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                                                                        })
                                                                                        }
                                                                                    </Select>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>

                                                                    }
                                                            </Grid>
                                                        </Box>
                                                    </div>
                                                }
                                            </div>
                                        )
                                } : null),
                                {
                                tabName: "Alarms",
                                disabled: !this.state.ready,
                                tabContent: (
                                    <div>
                                        {this.state.alarmTab == 0 ?
                                            <div>
                                                <b>Double click row to edit alarm details</b>
                                            </div>
                                            :
                                            <div>
                                                {window.location.hostname.includes('zonelog.net') ? "Use Save to update alarm settings. ALl alarms are processed on ZONELOG and any changes will take immediate effect." : "Use Save to update alarm settings. NOTE: Chariot alarms are processed on Aquaguard and any changes will take immediate effect."}
                                            </div>
                                        }
                                        <CustomTabs
                                            headerColor="primary"
                                            plainTabs
                                            value={this.state.alarmTab}
                                            onChange={():void => this.handleAlarmTabChange()}
                                            tabs={[
                                                {
                                                    tabName: "List",
                                                    tabContent: (
                                                        <div>
                                                            <Box border={2} padding={3}>
                                                                <Button variant="contained" onClick={(): void => this.handleCreateAlarms()}>Create New Alarm</Button>
                                                                
                                                                <DataGrid
                                                                    dataSource={this.state.alarms}
                                                                    keyExpr="Idx"
                                                                    onRowDblClick={(e):void => this.handleAlarmRowDblClick(e.data.Idx)}
                                                                >
                                                                    <Selection mode="single" />
                                                                    <Column dataField="Idx" dataType="number" width={40} />
                                                                    <Column dataField="AlarmMessage" dataType="string" />
                                                                    <Column dataField="AlarmClass" dataType="string" customizeText={this.classColumnCustomizeText}/>
                                                                    <Column dataField="SetInput" dataType="string" customizeText={this.inputColumnCustomizeText} />
                                                                    <Column dataField="ClrInput" dataType="string" customizeText={this.inputColumnCustomizeText} />
                                                                    <Column dataField="SetThreshold" dataType="number" />
                                                                    <Column dataField="ClrThreshold" dataType="number" />
                                                                </DataGrid>
                                                            </Box>
                                                        </div>
                                                    )
                                                },
                                                {
                                                    tabName: "Details",
                                                    disabled: !this.state.editAlarm,
                                                    tabContent: (
                                                        <div>
                                                            <Box border={2} padding={3}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={2}>
                                                                        <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
                                                                            Alarm {this.state.editAlarm?.Idx}
                                                                        </Typography>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12}><h5>Set Alarm {this.state.editAlarm?.Idx}</h5></Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial title="This sets the type of alarm you would like on this logger" placement="top" >
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="input-select-label">Set Alarm Type</InputLabel>
                                                                            <Select
                                                                                name="SetInput"
                                                                                label="Set Input"
                                                                                labelId="input-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.SetInput}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="0">Alarm Off</MenuItem>
                                                                                {(this.state.channelsAvail & 0x01) != 0 &&
                                                                                    <MenuItem value="1">Flow 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x01) != 0 &&
                                                                                    <MenuItem value="2">Consumption 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x04) != 0 &&
                                                                                    <MenuItem value="3">Latest Pressure 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x04) != 0 &&
                                                                                    <MenuItem value="11">Pressure 1 Difference</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x08) != 0 &&
                                                                                    <MenuItem value="7">Latest Pressure 2</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x08) != 0 &&
                                                                                    <MenuItem value="12">Pressure 2 Difference</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x02) != 0 &&
                                                                                    <MenuItem value="9">Flow 2</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x02) != 0 &&
                                                                                    <MenuItem value="10">Consumption 2</MenuItem>
                                                                                }
                                                                                <MenuItem value="30">Signal Strength (0-31)</MenuItem>
                                                                                <MenuItem value="31">Battery (mV)</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="This sets the condition of how that alarm will be triggered from the level in the next box (e.g. Consumption 'IS LESS THAN' 10 l/s ) ">
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="operator-select-label">Set Alarm Condition</InputLabel>
                                                                            <Select
                                                                                name="SetOperator"
                                                                                label="Set Operator"
                                                                                labelId="operator-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.SetOperator}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="0">Alarm Off</MenuItem>
                                                                                <MenuItem value="1">is Equal to</MenuItem>
                                                                                <MenuItem value="2">is Not Equal to</MenuItem>
                                                                                <MenuItem value="3">is Less than</MenuItem>
                                                                                <MenuItem value="4">is Greater than</MenuItem>
                                                                                <MenuItem value="5">is Less than or equal to</MenuItem>
                                                                                <MenuItem value="6">is Greater than or equal to</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial title="This sets the input value for the alarm (e.g. Consumption is greater than '10')">
                                                                            <div>
                                                                                <TextField name="SetThreshold"
                                                                                    label="Set Alarm level"
                                                                                    
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={this.state.editAlarm?.SetThresholdStr}
                                                                                    onChange={this.handleAlarmTextfieldChange.bind(this)}
                                                                                    error={this.state.fieldErrors.SetThreshold}
                                                                                    helperText={this.state.fieldErrors.SetThreshold ? "Must be DECIMAL number" : ""}
                                                                                /> {this.displayUnits(this.state.editAlarm?.SetInput, this.state.setUnits)}
                                                                            </div>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="Sets the interval of how long this metric must happen before alarm is sent">
                                                                            <FormControl fullWidth>
                                                                                <InputLabel id="timeframe-select-label">Set Alarm Timeframe</InputLabel>
                                                                                <Select
                                                                                    name="SetCountStr"
                                                                                    label="Set Alarm Timeframe"
                                                                                    labelId="timeframe-select-label"
                                                                                    size="small"
                                                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                                                    value={this.state.editAlarm?.SetCountStr}
                                                                                    onChange={this.handleAlarmSelectChange.bind(this)}
                                                                                >
                                                                                    <MenuItem value="5mins">every 5 minutes</MenuItem>
                                                                                    <MenuItem value="15mins">every 15 minutes</MenuItem>
                                                                                    <MenuItem value="30mins">every 30 minutes</MenuItem>
                                                                                    <MenuItem value="1hour">every hour</MenuItem>
                                                                                    <MenuItem value="3hour">every 3 hours</MenuItem>
                                                                                    <MenuItem value="6hour">every 6 hours</MenuItem>
                                                                                    <MenuItem value="12hour">every 12 hours</MenuItem>
                                                                                    <MenuItem value="24hour">every 24 hours</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={12}><h5>Clear Alarm {this.state.editAlarm?.Idx}</h5></Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="This sets the type of method to clear this alarm">
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="input-select-label">Clear Alarm Type</InputLabel>
                                                                            <Select
                                                                                name="ClrInput"
                                                                                label="ClrInput"
                                                                                labelId="input-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.ClrInput}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="0">Alarm Off</MenuItem>
                                                                                {(this.state.channelsAvail & 0x01) != 0 &&
                                                                                    <MenuItem value="1">Flow 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x01) != 0 &&
                                                                                    <MenuItem value="2">Consumption 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x04) != 0 &&
                                                                                    <MenuItem value="3">Latest Pressure 1</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x04) != 0 &&
                                                                                    <MenuItem value="11">Pressure 1 Difference</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x08) != 0 &&
                                                                                    <MenuItem value="7">Latest Pressure 2</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x08) != 0 &&
                                                                                    <MenuItem value="12">Pressure 2 Difference</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x02) != 0 &&
                                                                                    <MenuItem value="9">Flow 2</MenuItem>
                                                                                }
                                                                                {(this.state.channelsAvail & 0x02) != 0 &&
                                                                                    <MenuItem value="10">Consumption 2</MenuItem>
                                                                                }
                                                                                <MenuItem value="30">Signal Strength (0-31)</MenuItem>
                                                                                <MenuItem value="31">Battery (mV)</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="This sets the condition of how that alarm will be cleared from the level in the next box (e.g. Consumption 'IS GREATER THAN' 10 l/s ) ">
                                                                        <FormControl fullWidth>
                                                                                <InputLabel id="operator-select-label">Clear Alarm Condition</InputLabel>
                                                                            <Select
                                                                                name="ClrOperator"
                                                                                label="Clear Alarm Condition"
                                                                                labelId="operator-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.ClrOperator}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="0">Alarm Off</MenuItem>
                                                                                <MenuItem value="1">is Equal to</MenuItem>
                                                                                <MenuItem value="2">is Not Equal to</MenuItem>
                                                                                <MenuItem value="3">is Less than</MenuItem>
                                                                                <MenuItem value="4">is Greater than</MenuItem>
                                                                                <MenuItem value="5">is Less than or equal to</MenuItem>
                                                                                <MenuItem value="6">is Greater than or equal to</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                   

                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="This sets the clear value for the alarm (e.g. Consumption is greater than '10')">
                                                                            <div>
                                                                                <TextField name="ClrThreshold"
                                                                                    label="Clear Alarm Level"
                                                                                    fullWidth={true}
                                                                                    variant="outlined"
                                                                                    size="small"
                                                                                    value={this.state.editAlarm?.ClrThresholdStr}
                                                                                    onChange={this.handleAlarmTextfieldChange.bind(this)}
                                                                                    error={this.state.fieldErrors.ClrThreshold}
                                                                                    helperText={this.state.fieldErrors.ClrThreshold ? "Must be DECIMAL number" : ""}
                                                                                /> {this.displayUnits(this.state.editAlarm?.ClrInput, this.state.clrUnits)}
                                                                            </div>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <TooltipMaterial placement="top" title="Sets the interval of how long this metric must happen before alarm is cleared">
                                                                            <FormControl fullWidth>
                                                                                <InputLabel id="timeframe-select-label">Clear Alarm Timeframe</InputLabel>
                                                                                <Select
                                                                                    name="ClrCountStr"
                                                                                    label="Set Alarm Timeframe"
                                                                                    labelId="timeframe-select-label"
                                                                                    size="small"
                                                                                    style={{ margin: 0, paddingBottom: 0 }}
                                                                                    value={this.state.editAlarm?.ClrCountStr}
                                                                                    onChange={this.handleAlarmSelectChange.bind(this)}
                                                                                >
                                                                                    <MenuItem value="5mins">every 5 minutes</MenuItem>
                                                                                    <MenuItem value="15mins">every 15 minutes</MenuItem>
                                                                                    <MenuItem value="30mins">every 30 minutes</MenuItem>
                                                                                    <MenuItem value="1hour">every hour</MenuItem>
                                                                                    <MenuItem value="3hour">every 3 hours</MenuItem>
                                                                                    <MenuItem value="6hour">every 6 hours</MenuItem>
                                                                                    <MenuItem value="12hour">every 12 hours</MenuItem>
                                                                                    <MenuItem value="24hour">every 24 hours</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    
                                                                    <Grid item xs={7}>
                                                                        <TooltipMaterial placement="top" title="Set the message for the alarm which will appear in the text messages set in the contact section">
                                                                        <TextField name="AlarmMessage" label="Message" fullWidth={true} variant="outlined" size="small" value={this.state.editAlarm?.AlarmMessage} onChange={this.handleAlarmTextfieldChange.bind(this)} />
                                                                    </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                    <TooltipMaterial title="Set severity of alarm to select how this alarm will be sent">
                                                                        <FormControl fullWidth>
                                                                            <InputLabel id="operator-select-label">Severity</InputLabel>
                                                                            <Select
                                                                                name="AlarmClass"
                                                                                label="Severity"
                                                                                labelId="severity-select-label"
                                                                                size="small"
                                                                                style={{ margin: 0, paddingBottom: 0 }}
                                                                                value={this.state.editAlarm?.AlarmClass}
                                                                                onChange={this.handleAlarmSelectChange.bind(this)}
                                                                            >
                                                                                <MenuItem value="0">Not set</MenuItem>
                                                                                <MenuItem value="7">Low</MenuItem>
                                                                                <MenuItem value="6">Normal</MenuItem>
                                                                                <MenuItem value="5">High</MenuItem>
                                                                                <MenuItem value="4">Critical</MenuItem>
                                                                            </Select>
                                                                            </FormControl>
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <TooltipMaterial placement="top" title="Sets a limit on the amount of alarms will be sent">
                                                                        <TextField name="MaxAlarms"
                                                                            label="Max Alarms per day"
                                                                            fullWidth={false}
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={this.state.editAlarm?.MaxAlarmsStr}
                                                                            onChange={this.handleAlarmTextfieldChange.bind(this)}
                                                                            error={this.state.fieldErrors.MaxAlarms}
                                                                            helperText={this.state.fieldErrors.MaxAlarms ? "Must be INTEGER number" : ""}
                                                                            />
                                                                        </TooltipMaterial>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                                            
                                                                            <Button variant="contained" style={{marginRight: "10px"}} color="secondary" onClick={(): void => this.updateAlarm()}>Save</Button>
                                                                            <Button variant="contained" /*disabled={this.state.editAlarm?.Idx }*/color="error" onClick={(): void => this.deleteAlarm(this.state.editAlarm?.Id)}>Delete</Button>
                                                                        </div>
                                                                    </Grid>
                                                                    

                                
                                                                </Grid>
                                                            </Box>
                                                        </div>
                                                    )
                                                },
                                            ]}
                                        />
                                    </div>
                                )
                            },
                            sessionStorage.getItem("userLevel") == "identityadmin" ?
                                {
                                    tabName: "Custom",
                                    disabled: !this.state.ready,
                                    tabContent: (
                                        <div>
                                            <p>Custom Settings</p>
                                            <TextField
                                                id="generalUpdate"
                                                label="Updates <reg>=xxx (one per line)"
                                                onChange={this.configUpdateChanged}
                                                variant="outlined"
                                                multiline
                                                fullWidth
                                            />
                                        </div>
                                    )
                                } : null,

                        ]}
                    />
                }
                {this.state.panel == "Save Config" &&
                    <Card>
                        <CardHeader color="primary" >
                            <div className={this.props.classes.cardTitleWhite} style={{ paddingLeft: "30px" }}>
                                <h4 className={this.props.classes.cardTitleWhite}>
                                    Save config for immediately or scheduled update
                                </h4></div>
                        </CardHeader>
                        <CardBody>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                    <DateTimePicker
                                    label="Send Config After"
                                    value={this.state.sendAfter}
                                    onChange={this.handleSendAfterChange}
                                    renderInput={(params) => <TextField {...params} />}
                                    disabled={true}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div style={{ paddingLeft: "30px", paddingTop: "20px" }}>
                                <Button variant="contained" color="primary" onClick={(): void => this.handleSendAfterClick()}>Update immediately</Button>&nbsp;
                                <Button variant="contained" color="primary" onClick={(): void => this.handleSendAfterClick()} disabled={this.state.sendAfter == null}>Update after scheduled Date/Time</Button>&nbsp;
                                <Button variant="contained" color="secondary" onClick={(): void => this.setState({ panel: "Config" })}>Cancel</Button>
                            </div>

                        </CardBody>
                    </Card>
                }
                <NotificationContainer />
            </div>
        );
    }

}

export default withStyles(style)(ToolkitCHR);