import React, { Component, ReactNode} from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { withStyles, createStyles } from '@mui/styles';
import ApiFailed from '../../Utils/ApiFailed';
import { CreateUrl, CallGetAPI } from 'Utils/ApiHelper.js';
import ClipLoader from "react-spinners/ClipLoader";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import DateRange from '../../components/Dates/DateRange';
import { Button, Checkbox, ToggleButton, ToggleButtonGroup, Select, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import IconButton from "@mui/material/IconButton";
import Modal from 'react-bootstrap/Modal'
import Dialog from '@mui/material/Dialog';
import MaterialButton from '@mui/material/Button';
import TooltipMaterial from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box, { Item } from 'devextreme-react/box';
// ExcelJS
import ExcelJS, { Column } from 'exceljs';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

//Moment date/time formatting
//https://momentjs.com/docs/
import moment, { Duration, min } from 'moment';
import { adjustTime } from '../../Utils/AdjustTime'
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Chart, { Grid as GridChart, CommonSeriesSettings, ArgumentAxis, Series, Legend, ValueAxis, ZoomAndPan, ScrollBar, Tooltip, TickInterval, Aggregation, Point, Size, Title, AggregationInterval, Label, Height, Crosshair, Font, MinorTickInterval, Tick, MinorTick, Strip, CommonAxisSettings } from 'devextreme-react/chart';
import { APIGetLoggerReadingsModel, buildAPIGetLoggerReadingsModel } from '../../models/APIGetLoggerReadingsModel';
import { APILoggerDetail, buildAPILoggerDetail } from '../../models/APILoggerDetail';
import { enumChariotAlarmInput } from '../../models/enumChariotAlarmInput';
import { enumChariotAlarmOperator } from '../../models/enumChariotAlarmOperator';
import { decodeFlowMultiplier } from 'Utils/FlowMultiplier';
import { Export } from 'devextreme-react/data-grid';

import { PeriodStatsCHR } from '../../components/Readings/PeriodStatsCHR'
import { PeriodStatsTOR } from '../../components/Readings/PeriodStatsTOR'

const styles: Record<any, any> = {
    formControl: {
        minWidth: 120,
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        color: "#FFFFFF",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const style = createStyles(styles);

class ColorIndex {
    blue: number;
    green: number;
    red: number;
    brown: number;
    orange: number;
}

interface Group {
    id: number;
    groupId: number;
    name: string;
    parentId: Array<number>;
    path: string;
    lastUpdate: Date | null;
    level: string;
    subGroups: number;
    tags: Array<string>;
}

interface Options {
    value: number;
    label: string;
}

interface GroupOptions {
    value: number;
    label: string;
    parents: number;
    subGroups: number;
}

// Data to display on graph
interface Readings extends Record<string, any> {
    date: Date;
    /*  Following fields are dynamic for each logger selected
    <serial>channel: string;
    <serial>readingA: number | null;
    <serial>readingB: number | null;
    <serial>readingC: number | null;
    <serial>readingD: number | null;
    <serial>readingE: number | null;
    <serial>readingMax: number | null;
    <serial>readingMin: number | null;
    <serial>readingSet: number | null;
    <serial>readingClr: number | null;
    <serial>readingCmin: number | null;     // These used for Chariot Sampling
    <serial>readingCmax: number | null;
    <serial>readingDmin: number | null;
    <serial>readingDmax: number | null;
    */
}

interface Alarm {
    id: number;
    channel: string;
    message: string;
    setLevel: number;
    clrLevel: number;
    setOperator: enumChariotAlarmOperator;
}

interface Props {
    classes: {
        cardTitleWhite: string;
        formControl: string;
        cardCategoryWhite: string;
    };
    history: {
        goBack: () => void;
    };
    location: {
        state: {
            groupId: number | null;
            loggers: Array<number>;
            showBackButton: boolean;
            site: number | null;
        }
    }
    startDate: Date;
    endDate: Date;
    showDates: boolean;
    ref: React.RefObject<unknown>;
}

interface State {
    exportDialog: boolean;
    queryDialog: boolean;
    statsDialog: boolean;
    exportType: string;
    exportData: boolean[];
    exportEnable: boolean;
    loading: boolean;
    graphLoading: boolean;
    graphHidden: boolean;
    apiFailed: boolean;
    authorized: boolean;
    loggers: Array<APILoggerDetail>
    showFlow: boolean;
    showRainfall: boolean;
    showAnalog: boolean;
    showTotaliser: boolean;
    haveFlow: boolean;
    haveAnalog: boolean;
    haveTotaliser: boolean;
    readingsData: Array<APIGetLoggerReadingsModel>;
    graphData: Array<Readings>;
    startDate: Date;
    offsetStartDate: Date;
    endDate: Date;
    offsetEndDate: Date;
    maxTValue: number;
    minTValue: number;
    altMaxTValue: number;
    altMinTValue: number;
    maxFValue: number;
    minFValue: number;
    altMaxFValue: number;
    altMinFValue: number;
    //maxPValue: number;
    //minPValue: number;
    channels: string[];   // Channel to display ('C', 'D' or 'E')
    unitsF: string;
    altUnitsF: string;
    unitsT: string;
    altUnitsT: string;
    //unitsP: string;
    unitsR: string;
    switchUnits: boolean;
    switchUnitsVal: string;

    maxCValue: number;
    minCValue: number;
    unitsC: string;
    maxDValue: number;
    minDValue: number;
    unitsD: string;
    maxEValue: number;
    minEValue: number;
    unitsE: string;

    hasFlowA: boolean;
    hasFlowB: boolean;
    hasTotalA: boolean;
    hasTotalB: boolean;

    ChannelARef: string;
    ChannelBRef: string;
    ChannelCRef: string;
    ChannelDRef: string;
    ChannelERef: string;

    switchGraphType: boolean;
    switchGraphVal: string;

    alarmLevels: Array<Alarm>;
    showAlarmLevels: boolean;
    siteAlarmLevels: Array<Alarm>;
    showSiteAlarmLevels: boolean;
    // min/max with alarm levels
    maxFValueA: number;
    minFValueA: number;
    altMaxFValueA: number;
    altMinFValueA: number;
    maxCValueA: number;
    minCValueA: number;
    maxDValueA: number;
    minDValueA: number;
    maxEValueA: number;
    minEValueA: number;
    zoomLevel: number;

    SiteNameUserFriendly: string;
    SiteChannelsAvail: number;

    queryGraphValues: { min: number; max: number; };
    selectedAxis: string;
    selectedAxisValueLeft: string;
    selectedAxisValueRight: string;
    axisQuery: boolean;
    scaleQuery: boolean;
    originalStateValues: {
        minF?: number;
        maxF?: number;
        altMinF?: number;
        altMaxF?: number;
        minT?: number;
        maxT?: number;
        altMinT?: number;
        altMaxT?: number;
        minC?: number;
        maxC?: number;
        minD?: number;
        maxD?: number;
        minE?: number;
        maxE?: number;
    };
    numberOfControls: number;
    colorChange: boolean;
    typesOfChannelsUsed: string[];
    haveMinMax: boolean;            // Used with Chariot when sample data collected (i.e. min/mean/max)
    displayAs: string;
}

//https://www.rapidtables.com/web/color/index.html

const colorBlue = ["#00008B", "#0000CD", "#1E90FF", "#4169E1", "#4682B4", "#6495ED", "#5F9EA0", "#0F52BA"];
const colorRed = ["#B22222", "#8B0000", "#FF0000", "#DC143C", "#FF4500", "#C71585", "#FF6347", "#E34234"];
const colorGreen = ["#006400", "#008000", "#228B22", "#32CD32", "#2E8B57", "#3CB371", "#008B8B", "#00A36C"];
const colorBrown = ["#8B4513", "#A52A2A", "#5F3D26", "#6F4E37", "#8B0000", "#654321", "#7B3F00", "#7A5230"];
const colorOrange = ["#FF4500", "#FF8C00", "#FF6347", "#FF7F50", "#FF5722", "#FF6F00", "#FFA500", "#FF7518"];




export class MultiLoggerGraph extends Component<Props, State> {

    timer: any = null;     // Check for all readings loaded ready for export
    seriesType: Array<string>;      //set by renderLoggerSeries to pass series type to renderLegendText
    flowAs: string | null = null;
    flowFactor = 1;

    constructor(props: Readonly<Props>) {
        super(props);

        // console.log("CTOR Props  " + props);
        this.channelRef = React.createRef();
        this.state = {
            exportDialog: false,
            queryDialog: false,
            statsDialog: false,
            exportType: "XLSX",
            exportData: [false, false, false, false, false],
            exportEnable: false,
            authorized: true,
            apiFailed: false,
            graphHidden: true,
            loading: true,
            graphLoading: false,
            loggers: [],
            showFlow: true,
            showRainfall: false,
            showAnalog: false,
            showTotaliser: window.sessionStorage.getItem('graphTotaliser') === 'true' ? true : false,
            haveAnalog: true,
            haveFlow: true,
            haveTotaliser: true,
            readingsData: [],
            graphData: [],
            startDate: (props.startDate != null ? moment(props.startDate) : moment().subtract(7, "days")).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
            offsetStartDate: moment(),
            offsetEndDate: moment(),
            endDate: (props.endDate != null ? moment(props.endDate) : moment().toDate()),
            maxTValue: 0,
            minTValue: Number.MAX_VALUE,
            altMaxTValue: 0,
            altMinTValue: Number.MAX_VALUE,
            maxFValue: 0,
            minFValue: Number.MAX_VALUE,
            altMaxFValue: 0,
            altMinFValue: Number.MAX_VALUE,
            //maxPValue: 0,
            //minPValue: Number.MAX_VALUE,

            maxCValue: 0,
            minCValue: Number.MAX_VALUE,
            maxDValue: 0,
            minDValue: Number.MAX_VALUE,
            maxEValue: 0,
            minEValue: Number.MAX_VALUE,

            channels: ['A', 'B', 'C', 'D', 'E'],
            unitsF: "",
            altUnitsF: "",
            unitsT: "",
            altUnitsT: "",
            //unitsP: "",

            unitsC: "",
            unitsD: "",
            unitsE: "",

            unitsR: "",
            switchUnits: false,
            switchUnitsVal: "m3",

            hasFlowA: false,
            hasFlowB: false,
            hasTotalA: false,
            hasTotalB: false,
            switchGraphType: false,
            switchGraphVal: 'l',

            ChannelARef: "",
            ChannelBRef: "",
            ChannelCRef: "",
            ChannelDRef: "",
            ChannelERef: "",

            alarmLevels: [],
            showAlarmLevels: false,
            siteAlarmLevels: [],
            showSiteAlarmLevels: false,

            maxFValueA: 0,
            minFValueA: Number.MAX_VALUE,
            altMaxFValueA: 0,
            altMinFValueA: Number.MAX_VALUE,
            maxCValueA: 0,
            minCValueA: Number.MAX_VALUE,
            maxDValueA: 0,
            minDValueA: Number.MAX_VALUE,
            maxEValueA: 0,
            minEValueA: Number.MAX_VALUE,

            SiteNameUserFriendly: "",
            SiteChannelsAvail: 0,

            zoomLevel: 1, // Initial zoom level


            queryGraphValues: { min: 0, max: 0 },
            selectedAxis: "",
            selectedAxisValueLeft: "Flow",
            selectedAxisValueRight: sessionStorage.getItem('graphTotaliser') === 'true' ? "FlowTotal" : "ChannelC",
            axisQuery: false,
            scaleQuery: false,
            originalStateValues: {
                minF: 0,
                maxF: 0,
                altMinF: 0,
                altMaxF: 0,
                minT: 0,
                maxT: 0,
                altMinT: 0,
                altMaxT: 0,
                minC: 0,
                maxC: 0,
                minD: 0,
                maxD: 0,
                minE: 0,
                maxE: 0
            },
            numberOfControls: 0,
            colorChange: false,
            typesOfChannelsUsed: [],
            haveMinMax: true,
            displayAs: "mean",       // Used to toggle min/mean/max for sampled data
        };

        this.handleUnzoom = this.handleUnzoom.bind(this)

        this.seriesType = [];

        // Check for fixed flow display units - AQGD-553
        const flowUnits = window.sessionStorage.getItem("flowUnits");
        if (flowUnits && flowUnits != "") {
            if (flowUnits == "ls") {
                this.flowAs = "l/s";
                this.flowFactor = 1;
            }
            if (flowUnits == "lm") {
                this.flowAs = "l/min";
                this.flowFactor = 60;
            }
            if (flowUnits == "m3h") {
                this.flowAs = "m3/h";
                this.flowFactor = 3.6;
            }
            // AQGD-659 - for US market  US gallon/minute
            if (flowUnits == "gm") {
                this.flowAs = "gal/m";
                this.flowFactor = 60 / 3.785411784;
            }
        } 
    }

    /* MLG Component is the main Graphing Component, called via React Router at
     * Groups.tsx graphLoggersClick = () and
     * DeviceList.tsx graphLoggersClick = ()
     *
     * also as an embedded JSX component in DeviceOverview.tsx
     * in render()
     *
     * <MultiLoggerGraph location={{ state: { groupId: 0, loggers: this.state.loggerIds, showBackButton: false } }} history={{ goBack: this.blankFunction}} />
     *
     *
     * FUNCTION and Data Flow -->
     *
     * componentDidMount() and componentDidUpdate(), both call ->
     *
     * reloadData(this.props.location.state.groupId, this.props.location.state.loggers, this.props.location.state.site)
     * to get the full logger details from the loggerId or groupId, this is stored in
     * an array records: Array<APILoggerDetail>
     *
     * this then calls ->
     *
     * drawGraph(me,records)
     * this gets the logReadings into readingsData = new Array<APIGetLoggerReadingsModel>();
     * then iterates readingsData, converts any values and stores in graphData = new Array<Readings>()
     * finally stores both and other vars (ie max / min, units etc) into state via setState(), this will cause a render()
     *
     * render() called at various stages, creates a <Chart> component with the datasource as state.graphData()
     * and iterates the this.state.loggers array to render Series
     *
     */


    // #region Export

    //No native Export facility from DevExtreme Graph
    toggleExportModal(): void {
        this.setState({ exportDialog: !this.state.exportDialog });
        // NOTE Test checks PREVIOUS state
        if (!this.state.exportDialog) {
            this.timer = setInterval(this.exportEnable.bind(this), 1000);
        }
        else {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    toggleStatsModal(): void {
        this.setState({ statsDialog: !this.state.statsDialog });
        
    }

    toggleGraphQueryModal(axis: string): void {
        this.setState({ queryDialog: !this.state.queryDialog, selectedAxis: axis });
        // NOTE Test checks PREVIOUS state

        if (!this.state.queryDialog) {
            this.timer = setInterval(this.exportEnable.bind(this), 1000);
        }
        else {
            clearInterval(this.timer);
            this.timer = null;
        }

    }

    exportEnable(): void {
        const enabled = true;

        this.setState({
            exportEnable: enabled
        });
        if (enabled) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    handleExportAsChange(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ exportType: (event.target as HTMLInputElement).value });
    }

    exportConfirm(): void {

        const workbook = new ExcelJS.Workbook();

        const me = this;

        let readingsData: Array<APIGetLoggerReadingsModel> = [];

        /*
        if (!this.ChannelMLGReadings.current?.state.loading) {
            readingsData = this.ChannelMLGReadings.current?.state.readingsData ? this.ChannelMLGReadings.current?.state.readingsData : [];
        }
        */

        readingsData = me.state.readingsData;

        let worksheet = null;
        
        
        readingsData.forEach(item => {
            const offsetType = sessionStorage.getItem('utcOffset').slice(0, 1)
            //console.log(item.dateStamp)
            if (offsetType === "-") {
                item.dateStampLocal = moment(item.dateStamp).subtract(parseInt(sessionStorage.getItem('utcOffset').slice(1)), 'hours').format('YYYY-MM-DD HH:mm:ss')
                
            } else if (offsetType === "+") {
                item.dateStampLocal = moment(item.dateStamp).add(parseInt(sessionStorage.getItem('utcOffset').slice(1)), 'hours').format('YYYY-MM-DD HH:mm:ss')
                
            } else {
                item.dateStampLocal = item.dateStamp
            }
            
        })

        
        if (readingsData.length > 0) {
            // New worksheet, Freeze first row - col headers
            worksheet = workbook.addWorksheet("All Channels", { views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }] });

            worksheet.columns = [
                { header: "Id", key: "id" },
                { header: "Serial", key: "loggerSerial" },
                { header: "Site", key: "site" },
                { header: "DateTime Local (UTC Offset)", key: "dateStampLocal", style: { numFmt: 'yyyy-mm-dd hh:mm:ss' }, width: 40 },
                { header: "Date (UTC)", key: "dateStamp", style: { numFmt: 'yyyy-mm-dd hh:mm:ss' }, width: 25 },               
                { header: "Type", key: "type" },
                { header: "Value", key: "value", width: "40" },
                { header: "Value2", key: "value2", width: "40" },
                { header: "Units", key: "units" },
                { header: "Min", key: "minValue" },
                { header: "Max", key: "maxValue" },
                { header: "ChannelRef", key: "channelRef" },
                { header: "ChannelSerial", key: "channelSerial" },
                { header: "Letter", key: "channelLetter" },
                { header: "Interval", key: "interval" },
                { header: "Decimals", key: "dp" },
                { header: "Forward", key: "forward", width: "40" },
                { header: "Reverse", key: "reverse", width: "40" },
                { header: "Total", key: "total", width: "40" },
                { header: "Multiplier", key: "multiplier" },
                { header: "Difference", key: "difference" },
                { header: "Flow Rate", key: "flowRate" },
            ] as Column[];



            if (this.state.exportType == "XLSXT") {
                // Add header row
                worksheet.addRow(worksheet.columns.map(column => column.header));

                readingsData.sort((a, b) => (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0));

                // Add data rows
                readingsData.forEach(data => {
                    worksheet.addRow(
                        worksheet.columns.map(column => data[column.key])
                    );
                });

                // Add a table to the worksheet
                worksheet.addTable({
                    name: 'DataTable',
                    ref: 'A2', // Reference to the top-left cell of the data range
                    displayName: 'MyTable',
                    columns: worksheet.columns.map(column => ({ name: column.header, filterButton: true })),
                    rows: readingsData.map(data => worksheet.columns.map(column => {
                        if (column === "Date (UTC)") { data["Date"] }
                        else if (column === "Date (Offset)") { data["Date"] }
                        else data[column.key]
                    })),
                });
            } else {
                readingsData.sort((a, b) => (a.dateStamp ? new Date(a.dateStamp).valueOf() : 0) - (b.dateStamp ? new Date(b.dateStamp).valueOf() : 0));
                worksheet.addRows(readingsData);
            }
        }
        // console.log(readingsData)

        //add 2nd worksheet values as a table
        /*
        if (this.state.exportType != "CSV") {

            //https://stackoverflow.com/questions/76857453/error-typeerror-cannot-read-properties-of-undefined-reading-tl
            worksheet2 = workbook.addWorksheet("TEST TABLE", { views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }] });

            //const myrows = worksheet2.addRows(readingsData);
            const myrows = readingsData.map((data) => [
                data.id,
                data.loggerSerial,
                data.site,
                data.dateStamp,
                data.type,
                data.value,
                data.value2,
                data.units,
                data.minValue,
                data.maxValue,
                data.channelRef,
                data.channelSerial,
                data.channelletter,
                data.interval,
                data.dp,
                data.forward,
                data.reverse,
                data.total,
                data.multiplier,
                data.difference,
                data.flowRate,
            ]);



            worksheet2.addTable({
                name: 'EXPORT AS TABLE',
                ref: 'A1',
                headerRow: true,
                totalsRow: true,
                style: {
                    theme: 'TableStyleDark1',
                    showRowStripes: true,
                    showFirstColumn: true,
                    showLastColumn: true,
                    showColumnStripes: false
                },
                columns: [
                    { name: 'Id', filterButton: false,  },
                    { name: 'Serial', filterButton: true },
                    { name: 'Site', filterButton: true },
                    { name: 'Date', filterButton: true },
                    { name: 'Type', filterButton: true },
                    { name: "Value", filterButton: true },
                    { name: "Value2", filterButton: true },
                    { name: "Units", filterButton: true },
                    { name: "Min", filterButton: true },
                    { name: "Max", filterButton: true },
                    { name: "ChannelRef", filterButton: true },
                    { name: "ChannelSerial", filterButton: true },
                    { name: "Letter", filterButton: true },
                    { name: "Interval", filterButton: true },
                    { name: "Decimals", filterButton: true },
                    { name: "Forward", filterButton: true },
                    { name: "Reverse", filterButton: true },
                    { name: "Total", filterButton: true },
                    { name: "Multiplier", filterButton: true },
                    { name: "Difference", filterButton: true },
                    { name: "Flow Rate", filterButton: true },

                ],
                rows: myrows
            });
        }
        */




        // console.log(worksheet?.rowCount);

        if (this.state.exportType == "CSV") {

            const zip = new JSZip();
            const promises = new Array<Promise<any>>();

            // Generate CSV files
            workbook.worksheets.map((sht) => {
                const options: ExcelJS.CsvWriteOptions = {
                    sheetName: sht.name,
                    sheetId: sht.id,
                    dateUTC: true,
                } as ExcelJS.CsvWriteOptions;
                promises.push(workbook.csv.writeBuffer(options)
                    .then(function (buffer: Buffer) {
                        zip.file(sht.name + ".csv", buffer.buffer);
                    })
                );
            });

            Promise.all(promises).then(() => {
                if (workbook.worksheets.length > 0) {
                    zip.generateAsync({ type: "blob" }).then(function (content: Blob) {
                        saveAs(content, 'Aquaguard Export.zip');
                    });

                }
            });

        }
        else {
            // Generate XLSX files
            // Format the Date column as Date on all worksheets
            //workbook.worksheets.forEach(worksheet => {
            if (worksheet != null)
                worksheet.getColumnKey("dateStamp").eachCell(cell => {
                    if (cell.value?.toString() != "Date") {
                        const dateStr = cell.value?.toString().replace('T', ' ');
                        cell.value = { formula: "DATEVALUE(\"" + dateStr + "\") + TIMEVALUE(\"" + dateStr + "\")", date1904: true };
                    }
                });
            //});
            // Download the workbook
            if (workbook.worksheets.length > 0) {
                workbook.xlsx.writeBuffer().then(function (buffer: Buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Aquaguard Export.xlsx');
                });
            }

        }

        this.toggleExportModal();
    }


    // #endregion

    createSuccessNotification = () => {
        NotificationManager.success('Saved Changes', 'Success')

    };

    createErrorNotification = () => {
        NotificationManager.error('Error Saving Changes', 'Click me!', 5000, () => {
            alert('callback');
        });
    };

    componentDidMount():void {
        const me = this;
        // console.log("CDM Props Loggers " + this.props.location.state.loggers.length);
        // Check if component in popup - so data already loaded
        //if (!this.props.inPopup) {  PREVENTS ANY DATA IN POPUP!!
        //if (this.props.location && this.props.location.state && this.props.location.state.groupId) {
            this.reloadData(this.props.location.state.groupId, this.props.location.state.loggers, this.props.location.state.site, false);
        //}
        //}
    }


    reloadData(groupId: number | null, loggers: Array<number>, site: number | null, dateChange: boolean | null): boolean {

        const me = this;

        // console.log("RELOAD Loggers " + loggers.length);
        this.setState({ loading: true });

        if (groupId != null && groupId != 0) {

            // Group selected - graph loggers deployed to sites in group
            CallGetAPI(CreateUrl('/api/aquaguard/GetAllLoggersForGroup?groupid=' + groupId), {})
                .then(data => {

                    if (data.length > 0) {

                        let siteId = 0;
                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])
                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        // Load alarm levels if single Chariot logger
                        if (records.length == 1) {
                            if (records[0].LoggerId > 500000) {
                                this.loadLCAlarmLevels(records[0].Serial, me);
                            }
                            else {
                                this.loadLoggerAlarmLevels(records[0].Serial, me);
                            }
                        }
                        if (siteId > 0) {
                            // Load alarm levels for Site if all loggers deployed to same site
                            if (records[0].FkSiteId != null) {
                                this.loadSiteAlarmLevels(siteId, me);
                            }
                        }

                        me.drawGraph(me, records, null, dateChange);

                    }
                    else {
                        me.drawGraph(me, [], null, dateChange);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    me.setState(
                        {
                            apiFailed: true,
                            authorized: false
                        })
                });
        }


        if (loggers.length > 0) {
            // List of loggers selected
            const loggerIds: Array<{ Id: number }> = [];

            if (loggers.length > 0) {
                loggers.map((l) => {
                    loggerIds.push({ Id: l });

                });
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loggerIds)
            };

            //console.log("RELOAD LoggerIds Array Length " + loggerIds.length);
            //console.log("RELOAD Request Options Body " + requestOptions.body);


            CallGetAPI(CreateUrl('/api/aquaguard/LoggerDetailsByIdList'), requestOptions)
                .then(data => {
                    
                    //console.log("RELOAD Data Length " + data.length);

                    if (data.length > 0) {

                        // Copy the data records into deviceData, adding the clickEvent
                        const records: Array<APILoggerDetail> = [];
                        let siteId = 0;
                        for (let i = 0; i < data.length; i++) {
                            const rec = buildAPILoggerDetail(data[i])

                            records.push(rec);

                            if (rec.FkSiteId != null) {
                                if (siteId == 0) {
                                    siteId = rec.FkSiteId;
                                }
                                else {
                                    // Check if all loggers from same site
                                    if (siteId != rec.FkSiteId) {
                                        siteId = -1;
                                    }
                                }
                            }
                        }

                        // console.log("RELOAD Records Length " + records.length);

                        // if (records.length > 0) { console.log("RELOAD Records[0] Serial -> " + records[0].Serial); }

                        // Load alarm levels if single Chariot logger
                        if (records.length == 1) {
                            if (records[0].LoggerId > 500000) {
                                this.loadLCAlarmLevels(records[0].Serial, me);
                            }
                            else {
                                this.loadLoggerAlarmLevels(records[0].Serial, me);
                            }
                        }
                        if (siteId > 0) {
                            // Load alarm levels for Site if all loggers deployed to same site
                            if (records[0].FkSiteId != null) {
                                this.loadSiteAlarmLevels(siteId, me);
                            }
                        }
                        me.drawGraph(me, records, null, dateChange);

                    }
                    else {
                        me.drawGraph(me, [], null, dateChange);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    me.setState(
                        {
                            apiFailed: true,
                            authorized: false
                        })
                });
        }

        // For site just pull readings and alarm levels for site
        if (site != null && site != 0) {

            //TODO: Need to get SiteUserFriendlyName
            CallGetAPI(CreateUrl('/api/aquaguard/site?id=' + site), {})
                .then(data => {

                    if (data.siteNameUserFriendly != null) {
                        me.setState({
                            SiteNameUserFriendly: data.siteNameUserFriendly.length > 0 ? data.siteNameUserFriendly : data.siteName
                        })
                    }
                    else {
                        me.setState({
                            SiteNameUserFriendly: data.siteName.length > 0 ? data.siteName : ""
                        })
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    me.setState(
                        {
                            apiFailed: true,
                            authorized: false
                        })
                });

            this.loadSiteAlarmLevels(site, me);

            this.drawGraph(me, [], site, dateChange);
        }

        return true;
    }

    drawGraph = (me: MultiLoggerGraph, loggers: Array<APILoggerDetail>, site: number | null, dateChange: boolean | null): void => {
        console.log(site)
        //drawGraph is called on the return from an async API call so needs a ref to this component in order to call setState
        //cant use 'this' as this is the result of the async call not the MLG instance

        //console.log("DRAWGRAPH State Loggers Length -> " + loggers.length);
        //if (loggers.length > 0) { console.log("DRAWGRAPH State Loggers[0] Id -> " + loggers[0].LoggerId); }

        let readingsData = new Array<APIGetLoggerReadingsModel>();
        const graphData = new Array<Readings>();
        let maxFValue = Number.NEGATIVE_INFINITY;
        let minFValue = Number.MAX_VALUE;
        let altMaxFValue = Number.NEGATIVE_INFINITY;
        let altMinFValue = Number.MAX_VALUE;

        let maxTValue = Number.NEGATIVE_INFINITY;
        let minTValue = Number.MAX_VALUE;
        let altMaxTValue = Number.NEGATIVE_INFINITY;
        let altMinTValue = Number.MAX_VALUE;

        //let maxPValue = 0;
        //let minPValue = Number.MAX_VALUE;

        let maxCValue = Number.NEGATIVE_INFINITY;
        let minCValue = Number.MAX_VALUE;
        let maxDValue = Number.NEGATIVE_INFINITY;
        let minDValue = Number.MAX_VALUE;
        let maxEValue = Number.NEGATIVE_INFINITY;
        let minEValue = Number.MAX_VALUE;

        let unitsF = "";
        let altUnitsF = "";
        let unitsT = "";
        let altUnitsT = "";

        //let unitsP = "";

        let unitsC = "";
        let unitsD = "";
        let unitsE = "";

        let unitsR = "";

        // Can't graph flow/total if no values in period
        let hasFlowA = false;
        let hasFlowB = false;
        let hasTotalA = false;
        let hasTotalB = false;

        // Check for pulse interval logger - i.e. not a fixed interval
        // Switch to column graph type if found
        let interval = -1;
        let pulseInterval = 0; // Count number of varied intervals, if over 50% treat as pulse interval

        // Record Channel names from channelSerial on readings
        let channelARef = "";
        let channelBRef = "";
        let channelCRef = "";
        let channelDRef = "";
        let channelERef = "";
        let channelsAvail = 0;

        // Detect if C or D values have Min & Max saved.
        let haveMinMax = false;

        //get readings for the loggers here

        //generate array of Promises each adding the readingsData for each logger
        const promises = new Array<Promise<any>>();

        const offset = sessionStorage.getItem('utcOffset') ? parseInt(sessionStorage.getItem('utcOffset').slice(1)) : 0
        let offsetStartDate
        let offsetEndDate
        console.log(this.state.endDate)
        if (sessionStorage.getItem('utcOffset').slice(0, 1) === '+') {
            offsetStartDate = moment(this.state.startDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).subtract(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else if (sessionStorage.getItem('utcOffset').slice(0, 1) === '-') {
            offsetStartDate = moment(this.state.startDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).add(offset, 'hours').format("yyyy-MM-DDTHH:mm:ss");
        } else {
            offsetStartDate = moment(this.state.startDate).format("yyyy-MM-DDTHH:mm:ss");
            offsetEndDate = moment(this.state.endDate).format("yyyy-MM-DDTHH:mm:ss");
        }
        console.log(moment(offsetStartDate).toDate())
        if (site == null) {

            loggers.map((logger) => {
                
                // console.log("DRAWGRAPH Push to API -> " + '/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59");

                promises.push(
                    CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})

                        .then(json => {
                            readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));

                        })
                        .catch(function (ex) {
                            me.setState(
                                {
                                    apiFailed: true,
                                    readingsData: [],
                                    loading: false,
                                });
                            console.log(ex);
                        })
                );



                /* Removed this chunk as I think this was the section that was causing the duplicate data, its identical to the section above - Patrick */

                //promises.push(
                //    CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadings?companyid=0&logger=' + logger.Serial + "&startDate=" + utcStartDate + "&endDate=" + utcEndDate), {})
                //        .then(json => {
                //            readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                //            console.log(readingsData)
                //        })
                //        .catch(function (ex) {
                //            me.setState(
                //                {
                //                    apiFailed: true,
                //                    readingsData: [],
                //                    loading: false,
                //                });
                //            console.log(ex);
                //        })
                //);
            });
        }
        else {
            promises.push(
                CallGetAPI(CreateUrl('/api/aquaguard/GetLogReadingsBySite?siteId=' + site + "&startDate=" + offsetStartDate + "&endDate=" + offsetEndDate), {})
                    .then(json => {
                        console.log('hit')
                        readingsData = readingsData.concat(buildAPIGetLoggerReadingsModel(json));
                        console.log(readingsData)
                       
                    })
                    .catch(function (ex) {
                        me.setState(
                            {
                                apiFailed: true,
                                readingsData: [],
                                loading: false,
                            });
                        console.log(ex);
                    })
            );

        }

        //async promises return, add converted readings to graphData array and calculate maximum / minimum
        Promise.all(promises).then(() => {

            //console.log("DRAWGRAPH Readings (length) " + readingsData.length);
            console.log(readingsData)
            readingsData.forEach((v: APIGetLoggerReadingsModel) => {

                if (v.dateStamp != null) {

                    let multiplier = decodeFlowMultiplier(0);
                    if (v.multiplier != null) {
                        multiplier = decodeFlowMultiplier(v.multiplier);

                    }
                    let value = v.value;
                    if (value == null) {
                        value = v.value2;
                    }
                    let prefix = v.loggerSerial;
                    if (site != null) {
                        prefix = "Site";
                    }

                    //-32768 is an invalid reading for logger, so should be ignored
                    // Flow is ALWAYS l/s regardless of logger units
                    
                    if (v.channelletter == 'A') {
                        
                        if (v.units == "mm") {
                            // Treat as accumulated rainfall
                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (v.difference != null && v.difference >= 0) ? v.difference * multiplier.factor : null;
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            // Sampling data
                            rec[prefix + 'readingCmin'] = null;
                            rec[prefix + 'readingCmax'] = null;
                            rec[prefix + 'readingDmin'] = null;
                            rec[prefix + 'readingDmax'] = null;

                            graphData.push(rec);

                            if (value != -32768) {
                                // Expect 4 readings per hour, aggregated
                                if (v.difference != null && (v.difference * multiplier.factor * 4) > maxFValue) {
                                    maxFValue = v.difference * multiplier.factor * 4;
                                }
                                if (v.difference != null && v.difference >= 0 && (v.difference * multiplier.factor) < minFValue) {
                                    minFValue = v.difference * multiplier.factor;
                                }
                            }
                            if (v.units && unitsR == "") {
                                unitsR = v.units;
                            }
                        }
                        else {
                            // Treat as flow
                            let conversion = 1000;          // Assume m3, conversion to l
                            if (this.flowAs === 'gal/m') {
                                conversion = 35.315;
                            }
                            if (this.flowAs === 'l/s') {
                                conversion = 1;
                            }
                            let flowConversion = 3600 / 1000;
                                // units m3, conversion l/s to m3/h
                            if (this.flowAs == null) {
                                unitsF = "l/s";                 // Flow ALWAYS l/s
                                altUnitsF = "m3/h";

                                if (v.units) {
                                    if (v.units.substr(0, 1) != "m") {
                                        conversion = 1 / 1000;              // units l, conversion to m3
                                        unitsT = "l";
                                        altUnitsT = "m3";

                                    }
                                    else {
                                        unitsT = "m3";
                                        altUnitsT = "l";
                                    }
                                }
                                else {
                                    conversion = 1;
                                    flowConversion = 1;
                                }
                            }
                            else {

                                unitsF = this.flowAs == null ? "l/s" : this.flowAs;
                                flowConversion = this.flowFactor;

                                if (v.units && v.units.substr(0, 1) != "m") {
                                    if (this.flowAs === 'gal/m') {
                                        unitsT = "feet3"
                                    } else {
                                        unitsT = "l";
                                    }
                                }


                                else {
                                    if (this.flowAs === 'gal/m') {
                                        unitsT = "feet3"
                                    } else {
                                        unitsT = "m3";
                                    }
                                }

                            }

                            // Check intervals
                            if (v.interval != null && v.interval > 0) {
                                if (interval == -1) {
                                    if (v.interval) {
                                        interval = v.interval;
                                    }
                                }
                                else {
                                    if (v.interval != interval) {
                                        pulseInterval++;
                                    }
                                }
                            }
                            
                            const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                            rec[prefix + 'Channel'] = "Reading";
                            rec[prefix + 'readingA'] = (value != -32768) ? value : null;
                            rec[prefix + 'readingB'] = null;
                            rec[prefix + 'readingC'] = null;
                            rec[prefix + 'readingD'] = null;
                            rec[prefix + 'readingE'] = null;
                            rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                            rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                            rec[prefix + 'readingSet'] = null;
                            rec[prefix + 'readingClr'] = null;
                            rec[prefix + 'flowRateA'] = v.flowRate;
                            rec[prefix + 'altFlowRateA'] = v.flowRate != null ? v.flowRate * flowConversion : null;
                            rec[prefix + 'altReadingA'] = (value != -32768 && value != null) ? value * conversion : null;

                            graphData.push(rec);

                            //limits
                            if (value != -32768) {

                                hasTotalA = true;
                                //flow rate maximums
                                if (v.flowRate != null) {
                                    hasFlowA = true;
                                    if (v.flowRate > maxFValue) {
                                        maxFValue = v.flowRate;
                                    }
                                }
                                if (v.flowRate != null && v.flowRate < minFValue) {
                                    minFValue = v.flowRate;
                                }
                                if (v.flowRate != null && v.flowRate * flowConversion > altMaxFValue) {
                                    altMaxFValue = v.flowRate * flowConversion;
                                }
                                if (v.flowRate != null && v.flowRate * flowConversion < altMinFValue) {
                                    altMinFValue = v.flowRate * flowConversion;
                                }

                                //totaliser maximums
                                if (value != null && value > maxTValue) {
                                    maxTValue = value;
                                }
                                if (value != null && value < minTValue) {
                                    minTValue = value;
                                }
                                if (value != null && value * conversion > altMaxTValue) {
                                    altMaxTValue = value * conversion;
                                }
                                if (value != null && value * conversion < altMinTValue) {
                                    altMinTValue = value * conversion;
                                }

                                minTValue = 0;
                                altMinTValue = 0;

                               
                            }
                        }

                        channelARef = v.channelSerial;
                        channelsAvail |= 0x01;
                    }
                    if (v.channelletter == 'B') {

                        let conversion = 1000;          // Assume m3, conversion to l
                        let flowConversion = 1000 / 3600;   // units m3/h, conversion to l/s

                        if (this.flowAs == null) {
                            if (v.units) {
                                if (v.units.substr(0, 1) != "m") {
                                    conversion = 1 / 1000;              // units l, conversion to m3
                                    flowConversion = 3600 / 1000;   // units l/s, conversion to m3/h
                                    unitsF = "l/s";
                                    altUnitsF = "m3/h";
                                    unitsT = "l";
                                    altUnitsT = "m3";

                                }
                                else {
                                    unitsF = "m3/h";
                                    altUnitsF = "l/s";
                                    unitsT = "m3";
                                    altUnitsT = "l";
                                }
                            }
                            else {
                                conversion = 1;
                                flowConversion = 1;
                            }
                        }
                        else {
                            unitsF = this.flowAs == null ? "l/s" : this.flowAs;
                            flowConversion = this.flowFactor;

                            if (v.units && v.units.substr(0, 1) != "m") {
                                if (this.flowAs === 'gal/m') {
                                    unitsT = "feet3"
                                } else {
                                    unitsT = "l";
                                }
                            }
                            else {
                                if (this.flowAs === 'gal/m') {
                                    unitsT = "feet3"
                                } else {
                                    unitsT = "m3";
                                }

                            }
                        }

                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = (value != -32768) ? value : null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                        rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'flowRateB'] = v.flowRate;
                        rec[prefix + 'altFlowRateB'] = v.flowRate != null ? v.flowRate * flowConversion : null;
                        rec[prefix + 'altReadingB'] = (value != -32768 && value != null) ? value * conversion : null;

                        graphData.push(rec);

                        //limits
                        if (value != -32768) {

                            hasTotalB = true;
                            //flow rate maximums
                            if (v.flowRate != null) {
                                hasFlowB = true;
                                if (v.flowRate > maxFValue) {
                                    maxFValue = v.flowRate;
                                }
                            }
                            if (v.flowRate != null && v.flowRate < minFValue) {
                                minFValue = v.flowRate;
                            }
                            if (v.flowRate != null && v.flowRate * flowConversion > altMaxFValue) {
                                altMaxFValue = v.flowRate * flowConversion;
                            }
                            if (v.flowRate != null && v.flowRate * flowConversion < altMinFValue) {
                                altMinFValue = v.flowRate * flowConversion;
                            }

                            //totaliser maximums

                            if (value != null && value > maxTValue) {
                                maxTValue = value;


                            }
                            if (value != null && value < minTValue) {
                                minTValue = value;


                            }
                            if (value != null && value * conversion > altMaxTValue) {
                                altMaxTValue = value * conversion;


                            }
                            if (value != null && value * conversion < altMinTValue) {
                                altMinTValue = value * conversion;


                            }

                        }

                        channelBRef = v.channelSerial;
                        channelsAvail |= 0x02;

                    }
                    if (v.channelletter == 'C') {
                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = (value != -32768) ? value : null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                        rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingCmin'] = v.minValue;
                        rec[prefix + 'readingCmax'] = v.maxValue;

                        graphData.push(rec);

                        /*
                        if (value != -32768) {
                            if (value != null && value > maxPValue) {
                                maxPValue = value;
                            }
                            if (value != null && value < minPValue) {
                                minPValue = value;
                            }
                        }
                        if (v.units && unitsP == "") {
                            unitsP = v.units;
                        }
                        */

                        if (value != -32768) {
                            if (value != null && value > maxCValue) {
                                maxCValue = value;
                            }
                            if (value != null && value < minCValue) {
                                minCValue = value;
                            }
                            if (v.minValue != null && v.maxValue != null) {
                                haveMinMax = true;
                                if (v.maxValue > maxCValue) {
                                    maxCValue = v.maxValue;
                                }
                                if (v.minValue < minCValue) {
                                    minCValue = v.minValue;
                                }
                            }
                        }
                        if (v.units && unitsC == "") {
                            unitsC = v.units;
                        }

                        channelCRef = v.channelSerial;
                        channelsAvail |= 0x04;
                    }
                    if (v.channelletter == 'D') {
                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = (value != -32768) ? value : null;
                        rec[prefix + 'readingE'] = null;
                        rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                        rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;
                        rec[prefix + 'readingDmin'] = v.minValue;
                        rec[prefix + 'readingDmax'] = v.maxValue;

                        graphData.push(rec);


                        /*
                        if (value != -32768) {
                            if (value != null && value > maxPValue) {
                                maxPValue = value;
                            }
                            if (value != null && value < minPValue) {
                                minPValue = value;
                            }
                        }
                        if (v.units && unitsP == "") {
                            unitsP = v.units;
                        }
                        */

                        if (value != -32768) {
                            if (value != null && value > maxDValue) {
                                maxDValue = value;
                            }
                            if (value != null && value < minDValue) {
                                minDValue = value;

                            }
                            if (v.minValue != null && v.maxValue != null) {
                                haveMinMax = true;
                                if (v.maxValue > maxCValue) {
                                    maxCValue = v.maxValue;
                                }
                                if (v.minValue < minCValue) {
                                    minCValue = v.minValue;
                                }
                            }
                        }
                        if (v.units && unitsD == "") {
                            unitsD = v.units;
                        }

                        channelDRef = v.channelSerial;
                        channelsAvail |= 0x08;
                    }
                    if (v.channelletter == 'E') {
                        const rec: Readings = { date: adjustTime(new Date(v.dateStamp)) };
                        rec[prefix + 'Channel'] = "Reading";
                        rec[prefix + 'readingA'] = null;
                        rec[prefix + 'readingB'] = null;
                        rec[prefix + 'readingC'] = null;
                        rec[prefix + 'readingD'] = null;
                        rec[prefix + 'readingE'] = (value != -32768) ? value : null;
                        rec[prefix + 'readingMax'] = (v.maxValue != -32768) ? v.maxValue : null;
                        rec[prefix + 'readingMin'] = (v.minValue != -32768) ? v.minValue : null;
                        rec[prefix + 'readingSet'] = null;
                        rec[prefix + 'readingClr'] = null;

                        /*
                        graphData.push(rec);
                        if (value != -32768) {
                            if (value != null && value > maxPValue) {
                                maxPValue = value;
                            }
                            if (value != null && value < minPValue) {
                                minPValue = value;
                            }
                        }
                        if (v.units && unitsP == "") {
                            unitsP = v.units;
                        }
                        */

                        if (value != -32768) {
                            if (value != null && value > maxEValue) {
                                maxEValue = value;

                            }
                            if (value != null && value < minEValue) {
                                minEValue = value

                            }
                        }
                        if (v.units && unitsE == "") {
                            unitsE = v.units;
                        }

                        channelERef = v.channelSerial;
                        channelsAvail |= 0x10;
                    }

                }
            });

            graphData.sort((a, b) => a.date.getTime() - b.date.getTime());
            
            //console.log("DRAWGRAPH Readings (length) " + readingsData.length);
            //console.log("DRAWGRAPH Graph Data (length) " + graphData.length);

            //console.log("Max C " + maxCValue);
            //console.log("Min C " + minCValue);

            //add axis margins

            //FLOW
            /*
            if (minFValue == maxFValue && minFValue != 0) {
                minFValue = minFValue - Math.abs(minFValue * 0.1); //minus 10%
                maxFValue = maxFValue + Math.abs(maxFValue * 0.1); //add 10%
            }
            else { minFValue = minFValue - 0.1; maxFValue = maxFValue + 0.1; }

            if (altMinFValue == altMaxFValue && altMinFValue != 0) {
                altMinFValue = altMinFValue - Math.abs(altMinFValue * 0.1); //minus 10%
                altMaxFValue = altMaxFValue + Math.abs(altMaxFValue * 0.1); //add 10%
            }
            else { altMinFValue = altMinFValue - 0.1; altMaxFValue = altMaxFValue + 0.1; }

            //TOTALISER
            if (minTValue == maxTValue && minTValue != 0) {
                minTValue = minTValue - Math.abs(minTValue * 0.1); //minus 10%
                maxTValue = maxTValue + Math.abs(maxTValue * 0.1); //add 10%
            }
            else { minTValue = minTValue - 0.1; maxTValue = maxTValue + 0.1; }

            if (altMinTValue == altMaxTValue && altMinTValue != 0) {
                altMinTValue = altMinTValue - Math.abs(altMinTValue * 0.1); //minus 10%
                altMaxTValue = altMaxTValue + Math.abs(altMaxTValue * 0.1); //add 10%
            }
            else { altMinTValue = altMinTValue - 0.1; altMaxTValue = altMaxTValue + 0.1; }
            */



            //CHANNEL C
            if (minCValue == maxCValue && minCValue != 0) {
                minCValue = minCValue - Math.abs(minCValue * 0.1); //minus 10%
                maxCValue = maxCValue + Math.abs(maxCValue * 0.1); //add 10%
            } else { minCValue = minCValue - 0.1; maxCValue = maxCValue + 0.1; }

            //CHANNEL D
            if (minDValue == maxDValue && minDValue != 0) {
                minDValue = minDValue - Math.abs(minDValue * 0.1); //minus 10%
                maxDValue = maxDValue + Math.abs(maxDValue * 0.1); //add 10%
            }
            else { minDValue = minDValue - 0.1; maxDValue = maxDValue + 0.1; }

            //CHANNEL E
            if (minEValue == maxEValue && minEValue != 0) {
                minEValue = minEValue - Math.abs(minEValue * 0.1); //minus 10%
                maxEValue = maxEValue + Math.abs(maxEValue * 0.1); //add 10%
            }
            else { minEValue = minEValue - 0.1; maxEValue = maxEValue + 0.1; }



            if (unitsC === unitsD || unitsC === unitsE || unitsD === unitsE) {
                //adjust min and max vlaues for channel C, D and E to share a common max ands min value

                //max

                maxCValue = Math.max(maxCValue, maxDValue, maxEValue)
                maxDValue = Math.max(maxCValue, maxDValue, maxEValue)
                maxEValue = Math.max(maxCValue, maxDValue, maxEValue)


                //min

                minCValue = Math.min(minCValue, minDValue, minEValue)
                minDValue = Math.min(minCValue, minDValue, minEValue)
                minEValue = Math.min(minCValue, minDValue, minEValue)

            }

            // Check for no readings and adjust minValues
            if (minFValue == Number.MAX_VALUE) {
                minFValue = maxFValue;
            }
            if (altMinFValue == Number.MAX_VALUE) {
                altMinFValue = altMaxFValue
            }

            if (minTValue == Number.MAX_VALUE) {
                minTValue = maxTValue;
            }
            if (altMinTValue == Number.MAX_VALUE) {
                altMinTValue = altMaxTValue
            }

            if (minCValue == Number.MAX_VALUE) {
                minCValue = maxCValue;
            }

            if (minDValue == Number.MAX_VALUE) {
                minDValue = maxDValue;
            }

            if (minEValue == Number.MAX_VALUE) {
                minEValue = maxEValue;
            }


            // Adjust axis range where min & max are same by adding 1 to max if 0 or add 10%
            if (minFValue == maxFValue) {
                if (maxFValue == 0) {
                    maxFValue = 1;
                }
                else {
                    maxFValue = minFValue * 1.1;
                }
            }
            if (altMinFValue == altMaxFValue) {
                if (altMaxFValue == 0) {
                    altMaxFValue = 1;
                }
                else {
                    altMaxFValue = altMinFValue * 1.1;
                }
            }

            if (minTValue == maxTValue) {
                if (maxTValue == 0) {
                    maxTValue = 1;
                }
                else {
                    maxTValue = minTValue * 1.1;
                }
            }
            if (altMinTValue == altMaxTValue) {
                if (altMaxTValue == 0) {
                    altMaxTValue = 1;
                }
                else {
                    altMaxTValue = altMinTValue * 1.1;
                }
            }

            



            const isPulseInterval = (pulseInterval > (graphData.length / 2));

            me.checkAlarmAxisLevels(me);

            // determine channel refs for Value axis
            if (site == null) {
                if (loggers.length == 1) {
                    // Single logger, use channel refs
                    channelCRef = loggers[0]?.ChannelCRef;
                    channelDRef = loggers[0]?.ChannelDRef;
                    channelERef = loggers[0]?.ChannelERef;
                }
                if (loggers.length > 1) {
                    // Multiple loggers, use generic labels
                    channelCRef = "Analogue C";
                    channelDRef = "Analogue D";
                    channelERef = "Analogue E";
                }
            }

            // Graphdata - flowRate is ALWAYS l/s,  altFlowRate is m3/h REGARDLESS OF logger units

            console.log(graphData)
            me.setState({
                minTValue: minTValue,
                maxTValue: maxTValue,
                altMinTValue: altMinTValue,
                altMaxTValue: altMaxTValue,

                minFValue: minFValue,
                maxFValue: maxFValue,
                altMinFValue: altMinFValue,
                altMaxFValue: altMaxFValue,
                //minPValue: minPValue,
                //maxPValue: maxPValue,

                minCValue: minCValue,
                maxCValue: maxCValue,

                minDValue: minDValue,
                maxDValue: maxDValue,

                minEValue: minEValue,
                maxEValue: maxEValue,

                readingsData: readingsData,
                graphData: graphData,
                graphLoading: false,
                graphHidden: false,
                unitsF: unitsF,
                altUnitsF: altUnitsF,
                unitsT: unitsT,
                altUnitsT: altUnitsT,
                //unitsP: unitsP,

                unitsC: unitsC,
                unitsD: unitsD,
                unitsE: unitsE,

                unitsR: unitsR,
                haveFlow: unitsF != "",
                haveTotaliser: unitsT != "",
                //havePressure: unitsP != "",
                //showPressure: unitsP != "",
                showRainfall: unitsR != "",

                haveAnalog: unitsC != "" || unitsD != "" || unitsE != "",
                showAnalog: unitsC != "" || unitsD != "" || unitsE != "",

                hasFlowA: hasFlowA,
                hasFlowB: hasFlowB,
                showFlow: hasFlowA || hasFlowB,
                hasTotalA: hasTotalA,
                hasTotalB: hasTotalB,

                ChannelARef: site != null ? channelARef : me.state.loggers[0]?.ChannelARef,
                ChannelBRef: site != null ? channelBRef : me.state.loggers[0]?.ChannelBRef,
                ChannelCRef: channelCRef,
                ChannelDRef: channelDRef,
                ChannelERef: channelERef,
                SiteChannelsAvail: channelsAvail,

                switchUnits: unitsT != 'l',        //flow calculated in l/s, switch if logger units m3
                switchUnitsVal: unitsF,
                loggers: loggers,
                loading: false,

                switchGraphType: /*dateChange ? this.state.switchGraphType :*/ isPulseInterval,
                switchGraphVal: isPulseInterval ? 'c' : 'l',
                offsetStartDate: offsetStartDate,
                offsetEndDate: offsetEndDate,
                haveMinMax: haveMinMax,
            });
            this.setState({
                originalStateValues: {
                    minT: minTValue,
                    maxT: maxTValue,
                    altMinT: altMinTValue,
                    altMaxT: altMaxTValue,
                    minF: minFValue,
                    maxF: maxFValue,
                    altMinF: altMinFValue,
                    altMaxF: altMaxFValue,
                    minC: minCValue,
                    maxC: maxCValue,
                    minD: minDValue,
                    maxD: maxDValue,
                    minE: minEValue,
                    maxE: maxEValue,
                }
            })

        });

    }

    // Get logger alarm levels for display as bars on graph
    loadLoggerAlarmLevels(serial: number, me: MultiLoggerGraph): void {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/LoggerAlarmLevels?logger=' + serial + "&startDate=" + moment(this.state.startDate).format("yyyy-MM-DD") + "&endDate=" + moment(this.state.endDate).format("yyyy-MM-DD") + "T23:59:59"), {})
            .then(data => {

                if (data.length > 0) {
                    const alarms: Array<Alarm> = [];
                    const channels: Array<string> = data[0].channels.split(',');
                    for (let i = 0; i < channels.length; i++) {
                        let channel = "";
                        let setLevel = 0;
                        let clrLevel = 0;
                        if (channels[i].startsWith("A:") && channels[i].length > 2) {
                            channel = "A";
                            setLevel = data[0].channelASetLevel * this.flowFactor;
                            clrLevel = data[0].channelAClearLevel * this.flowFactor;
                        }
                        if (channels[i].startsWith("B:") && channels[i].length > 2) {
                            channel = "B";
                            setLevel = data[0].channelBSetLevel * this.flowFactor;
                            clrLevel = data[0].channelBClearLevel * this.flowFactor;
                        }
                        if (channels[i].startsWith("C:") && channels[i].length > 2) {
                            channel = "C";
                            setLevel = data[0].channelCSetLevel;
                            clrLevel = data[0].channelCClearLevel;
                        }
                        if (channels[i].startsWith("D:") && channels[i].length > 2) {
                            channel = "D";
                            setLevel = data[0].channelDSetLevel;
                            clrLevel = data[0].channelDClearLevel;
                        }
                        if (channels[i].startsWith("E:") && channels[i].length > 2) {
                            channel = "E";
                            setLevel = data[0].channelESetLevel;
                            clrLevel = data[0].channelEClearLevel;
                        }
                        if (channel != "") {
                            alarms.push({
                                id: i,
                                channel: channel,
                                message: channels[i].substring(2),
                                setLevel: setLevel,
                                clrLevel: clrLevel,
                                setOperator: enumChariotAlarmOperator.GreaterThan,
                            });
                        }


                    }

                    me.setState(
                        {
                            alarmLevels: alarms,
                            showAlarmLevels: true,
                        });

                }

            })
            .catch(function (error) {
                console.log(error);
                me.setState(
                    {
                        alarmLevels: [],
                    })
            });

    }

    loadLCAlarmLevels(serial: number, me: MultiLoggerGraph): void {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/LCAlarmConfigurations?serial=' + serial), {})
            .then(data => {
                console.log(data)
                if (data.length > 0) {
                    const alarms: Array<Alarm> = [];
                    for (let i = 0; i < data.length; i++) {
                        let channel = "";
                        let setLevel = 0;
                        let clrLevel = 0;
                        console.log(data)
                        if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                            channel = "A";
                            setLevel = data[i].setThreshold * this.flowFactor;
                            clrLevel = data[i].clrThreshold * this.flowFactor;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                            channel = "C";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                            channel = "D";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (channel != "") {
                            alarms.push({
                                id: data[i].id,
                                channel: channel,
                                message: data[i].alarmMessage,
                                setLevel: setLevel,
                                clrLevel: clrLevel,
                                setOperator: data[i].setOperator,
                            });
                        }


                    }

                    me.setState(
                        {
                            alarmLevels: alarms,
                            showAlarmLevels: true,
                        });

                }

            })
            .catch(function (error) {
                console.log(error);
                me.setState(
                    {
                        alarmLevels: [],
                    })
            });

    }

    // Get site alarm levels for display as bars on graph
    loadSiteAlarmLevels(siteId: number, me: MultiLoggerGraph): void {

        // Only show alarm levels for single Chariot logger
        CallGetAPI(CreateUrl('/api/aquaguard/SiteAlarmConfigurations?siteId=' + siteId), {})
            .then(data => {

                if (data.length > 0) {
                    const alarms: Array<Alarm> = [];
                    for (let i = 0; i < data.length; i++) {
                        let channel = "";
                        let setLevel = 0;
                        let clrLevel = 0;
                        if (data[i].setInput == enumChariotAlarmInput.FlowA && data[i].clrInput == enumChariotAlarmInput.FlowA) {
                            channel = "A";
                            setLevel = data[i].setThreshold * this.flowFactor;
                            clrLevel = data[i].clrThreshold * this.flowFactor;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure1Latest && data[i].clrInput == enumChariotAlarmInput.Pressure1Latest) {
                            channel = "C";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (data[i].setInput == enumChariotAlarmInput.Pressure2Latest && data[i].clrInput == enumChariotAlarmInput.Pressure2Latest) {
                            channel = "D";
                            setLevel = data[i].setThreshold;
                            clrLevel = data[i].clrThreshold;
                        }
                        if (channel != "") {
                            alarms.push({
                                id: data[i].id,
                                channel: channel,
                                message: data[i].alarmMessage,
                                setLevel: setLevel,
                                clrLevel: clrLevel,
                                setOperator: data[i].setOperator,
                            });
                        }


                    }

                    me.setState(
                        {
                            siteAlarmLevels: alarms,
                            showSiteAlarmLevels: alarms.length > 0,
                            showAlarmLevels: alarms.length == 0,
                        });


                }

            })
            .catch(function (error) {
                console.log(error);
                me.setState(
                    {
                        siteAlarmLevels: [],
                    })
            });

    }

    checkAlarmAxisLevels(me: MultiLoggerGraph): void {
        // Assume AlarmLevels load faster than readings, so check to min/max here
        if (me.state.alarmLevels.length > 0 || me.state.siteAlarmLevels.length > 0) {
            /*
            let maxFValueA = 0;
            let minFValueA = Number.MAX_VALUE;
            let altMaxFValueA = 0;
            let altMinFValueA = Number.MAX_VALUE;

            let maxCValueA = 0;
            let minCValueA = Number.MAX_VALUE;
            let maxDValueA = 0;
            let minDValueA = Number.MAX_VALUE;
            let maxEValueA = 0;
            let minEValueA = Number.MAX_VALUE;
            */

            let maxFValueA = Number.NEGATIVE_INFINITY;
            let minFValueA = Number.MAX_VALUE;
            let altMaxFValueA = Number.NEGATIVE_INFINITY;
            let altMinFValueA = Number.MAX_VALUE;

            let maxCValueA = Number.NEGATIVE_INFINITY;
            let minCValueA = Number.MAX_VALUE;
            let maxDValueA = Number.NEGATIVE_INFINITY;
            let minDValueA = Number.MAX_VALUE;
            let maxEValueA = Number.NEGATIVE_INFINITY;
            let minEValueA = Number.MAX_VALUE;

            const alarms = me.state.alarmLevels;

            for (let i = 0; i < alarms.length; i++) {
                if (alarms[i].channel == "A") {
                    if (alarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || alarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (alarms[i].setLevel > maxFValueA) {
                            maxFValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel < minFValueA) {
                            minFValueA = alarms[i].clrLevel
                        }
                        if ((alarms[i].setLevel * 1000 / 3600) > altMaxFValueA) {
                            altMaxFValueA = alarms[i].setLevel;
                        }
                        if ((alarms[i].clrLevel * 1000 / 3600) < altMinFValueA) {
                            altMinFValueA = alarms[i].clrLevel
                        }
                    }
                    if (alarms[i].setOperator == enumChariotAlarmOperator.LessThan || alarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (alarms[i].setLevel < minFValueA) {
                            minFValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel > maxFValueA) {
                            maxFValueA = alarms[i].clrLevel
                        }
                        if ((alarms[i].setLevel * 1000 / 3600) < altMinFValueA) {
                            altMinFValueA = alarms[i].setLevel;
                        }
                        if ((alarms[i].clrLevel * 1000 / 3600) > altMaxFValueA) {
                            altMaxFValueA = alarms[i].clrLevel
                        }
                    }
                }
                if (alarms[i].channel == "C") {
                    if (alarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || alarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (alarms[i].setLevel > maxCValueA) {
                            maxCValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel < minCValueA) {
                            minCValueA = alarms[i].clrLevel
                        }
                    }
                    if (alarms[i].setOperator == enumChariotAlarmOperator.LessThan || alarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (alarms[i].setLevel < minCValueA) {
                            minCValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel > maxCValueA) {
                            maxCValueA = alarms[i].clrLevel
                        }
                    }
                }
                if (alarms[i].channel == "D") {
                    if (alarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || alarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (alarms[i].setLevel > maxDValueA) {
                            maxDValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel < minDValueA) {
                            minDValueA = alarms[i].clrLevel
                        }
                    }
                    if (alarms[i].setOperator == enumChariotAlarmOperator.LessThan || alarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (alarms[i].setLevel < minDValueA) {
                            minDValueA = alarms[i].setLevel;
                        }
                        if (alarms[i].clrLevel > maxDValueA) {
                            maxDValueA = alarms[i].clrLevel
                        }
                    }
                }

            }
            const siteAlarms = me.state.siteAlarmLevels;
            for (let i = 0; i < siteAlarms.length; i++) {
                if (siteAlarms[i].channel == "A") {
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (siteAlarms[i].setLevel > maxFValueA) {
                            maxFValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel < minFValueA) {
                            minFValueA = siteAlarms[i].clrLevel
                        }
                        if ((siteAlarms[i].setLevel * 1000 / 3600) > altMaxFValueA) {
                            altMaxFValueA = siteAlarms[i].setLevel;
                        }
                        if ((siteAlarms[i].clrLevel * 1000 / 3600) < altMinFValueA) {
                            altMinFValueA = siteAlarms[i].clrLevel
                        }
                    }
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (siteAlarms[i].setLevel < minFValueA) {
                            minFValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel > maxFValueA) {
                            maxFValueA = siteAlarms[i].clrLevel
                        }
                        if ((siteAlarms[i].setLevel * 1000 / 3600) < altMinFValueA) {
                            altMinFValueA = siteAlarms[i].setLevel;
                        }
                        if ((siteAlarms[i].clrLevel * 1000 / 3600) > altMaxFValueA) {
                            altMaxFValueA = siteAlarms[i].clrLevel
                        }
                    }
                }
                if (siteAlarms[i].channel == "C") {
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (siteAlarms[i].setLevel > maxCValueA) {
                            maxCValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel < minCValueA) {
                            minCValueA = siteAlarms[i].clrLevel
                        }
                    }
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (siteAlarms[i].setLevel < minCValueA) {
                            minCValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel > maxCValueA) {
                            maxCValueA = siteAlarms[i].clrLevel
                        }
                    }
                }
                if (siteAlarms[i].channel == "D") {
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (siteAlarms[i].setLevel > maxDValueA) {
                            maxDValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel < minDValueA) {
                            minDValueA = siteAlarms[i].clrLevel
                        }
                    }
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (siteAlarms[i].setLevel < minDValueA) {
                            minDValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel > maxDValueA) {
                            maxDValueA = siteAlarms[i].clrLevel
                        }
                    }
                }
                if (siteAlarms[i].channel == "E") {
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.GreaterThanOrEqual) {
                        if (siteAlarms[i].setLevel > maxEValueA) {
                            maxEValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel < minEValueA) {
                            minEValueA = siteAlarms[i].clrLevel
                        }
                    }
                    if (siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThan || siteAlarms[i].setOperator == enumChariotAlarmOperator.LessThanOrEqual) {
                        if (siteAlarms[i].setLevel < minEValueA) {
                            minEValueA = siteAlarms[i].setLevel;
                        }
                        if (siteAlarms[i].clrLevel > maxEValueA) {
                            maxEValueA = siteAlarms[i].clrLevel
                        }
                    }
                }

            }

            me.setState({
                minFValueA: minFValueA,
                maxFValueA: maxFValueA,
                altMinFValueA: altMinFValueA,
                altMaxFValueA: altMaxFValueA,

                minCValueA: minCValueA,
                maxCValueA: maxCValueA,

                minDValueA: minDValueA,
                maxDValueA: maxDValueA,

                minEValueA: minEValueA,
                maxEValueA: maxEValueA,
            });
        }

    }

    componentDidUpdate(prevProps: Props): void {

        //check if properties have changed, reload data if so
        const me = this;

        if (prevProps.startDate != this.props.startDate
            || prevProps.endDate != this.props.endDate) {
            this.onDateChange(this.props.startDate, this.props.endDate, this.state.showRainfall);
        }

        if (this.props.location && this.props.location.state && (prevProps.location.state.groupId != this.props.location.state.groupId)
            || this.props.location && this.props.location.state && (JSON.stringify(prevProps.location.state.loggers) != JSON.stringify(this.props.location.state.loggers))) {
            this.reloadData(this.props.location.state.groupId, this.props.location.state.loggers, this.props.location.state.site, true);
        }

        //console.log("CDU Loggers Length " + me.state.loggers.length);
        //console.log("CDU Readings Length" + me.state.readingsData.length);

    }

    removeLogger = (serial: number): void => {
        const loggers = this.state.loggers;
        const idx = loggers.findIndex((l) => l.Serial == serial);
        loggers.splice(idx, 1);
        this.setState({
            loggers: loggers,
        });
    }

    toggleTotaliser = (e: any): void => {
        this.setState({
            showTotaliser: !this.state.showTotaliser,
        })
    }

    toggleFlow = (e: any): void => {
        this.setState({
            showFlow: !this.state.showFlow,
        })
    }

    toggleAnalog = (e: any): void => {
        this.setState({
            showAnalog: !this.state.showAnalog,
        })
    }

    toggleAlarms = (e: any): void => {
        this.setState({
            showAlarmLevels: !this.state.showAlarmLevels,
        })
    }

    toggleSiteAlarms = (e: any): void => {
        this.setState({
            showSiteAlarmLevels: !this.state.showSiteAlarmLevels,
        })
    }

    onDateChange(startDate: Date, endDate: Date, rainfall: boolean): void {
         
        let finalDate = moment(endDate).toDate() >= moment().toDate() ? moment(endDate).toDate() : moment(endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toDate()
        if (finalDate > moment().toDate()) {
            finalDate = moment().toDate()
        }
        this.setState({
            startDate: moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
            endDate: finalDate,
            graphData: []
        });
        this.reloadData(this.props.location.state.groupId, this.props.location.state.loggers, this.props.location.state.site, true);
    }

    toggleFlowUnit(event, value): void {
        // console.log("Switch!");
        
        this.setState({
            switchUnits: !this.state.switchUnits,
            switchUnitsVal: value
        })
    }

    toggleGraphType(e: any): void {
        
        this.setState({
            switchGraphType: !this.state.switchGraphType,
            switchGraphVal: e.target.value
        });
    }

    toggleDisplay(e: any, value: string): void {
        // console.log("Switch!");

        this.setState({
            displayAs: value
        })
    }


    legendClickHandler(e: any): void {
       
        const series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }

    renderLoggerSeries(logger: APILoggerDetail, index: number, indices: ColorIndex): React.ReactNode {

        const series: Array<React.ReactNode> = [];
        // console.log("START renderLoggerSeries " + logger.Serial);


        /// Check for if zonelog and makes all analog pressure
        const isZoneLog = window.location.hostname.includes('zonelog.net')
        // Need to look at logger.channelsAvail......

        //indices.blue = 2;

        //rainfall
        if (this.state.showRainfall) {
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":RAIN"} type="bar" axis="Rain" valueField={logger.Serial + "readingA"} name="Hourly rainfall" point={{ visible: false }} aggregation={{ method: "sum", enabled: true }} />)
                indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
            }
            this.seriesType.push("Rainfall");
        }

        //flow
        if (this.state.showFlow && !this.state.switchUnits && this.flowAs == null) {
            // Show flows in logger units
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0 && this.state.hasFlowA) {
                series.push(<Series color={colorRed[indices.red]} key={logger.Serial + ":A"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={logger.Serial + "flowRateA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0 && this.state.hasFlowB) {
                series.push(<Series color={colorRed[indices.red]} key={logger.Serial + ":B"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={logger.Serial + "flowRateB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
        }
        if (this.state.showFlow && (this.state.switchUnits || this.flowAs != null)) {
            // Show flows in alt units
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0 && this.state.hasFlowA) {
                series.push(<Series color={colorRed[indices.red]} key={logger.Serial + ":A"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={logger.Serial + "altFlowRateA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0 && this.state.hasFlowB) {
                series.push(<Series color={colorRed[indices.red]} key={logger.Serial + ":B"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={logger.Serial + "altFlowRateB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
        }

        //totaliser
        /*
        if (this.state.showTotaliser && !this.state.switchUnits) {
            // Show totaliser in m3
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":AT"} type="spline" axis="FlowTotal" valueField={logger.Serial + "readingA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":BT"} type="spline" axis="FlowTotal" valueField={logger.Serial + "readingB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
            }
        }
        if (this.state.showTotaliser && this.state.switchUnits) {
            // Show totaliser in l
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":AT"} type="spline" axis="FlowTotal" valueField={logger.Serial + "altReadingA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":BT"} type="spline" axis="FlowTotal" valueField={logger.Serial + "altReadingB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
            }
        }
        */

        //always m3 for totaliser
        if (this.state.haveTotaliser && this.state.showTotaliser) {
            // Show totaliser in m3
            console.log(this.state.unitsT)
            if (this.state.unitsT == 'l') {
                // Use altReading if readings in l
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0 && this.state.hasTotalA) {[]
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":AT"} type={this.state.switchGraphType ? "bar"  : "spline" } axis="FlowTotal" valueField={logger.Serial + "altReadingA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                    indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0 && this.state.hasTotalB) {
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":BT"} type={this.state.switchGraphType ? "bar" : "spline"} axis="FlowTotal" valueField={logger.Serial + "altReadingB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                    indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
            }
            else if (this.state.unitsT == 'feet3') {
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0 && this.state.hasTotalA) {
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":AT"} type={this.state.switchGraphType ? "bar" : "spline"} axis="FlowTotal" valueField={logger.Serial + "altReadingA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                    indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0 && this.state.hasTotalB) {
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":BT"} type={this.state.switchGraphType ? "bar" : "spline"} axis="FlowTotal" valueField={logger.Serial + "altReadingB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                    indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }

            }
            
            else {
                // Use Reading already in m3
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x01) > 0 && this.state.hasTotalA) {
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":AT"} type={this.state.switchGraphType ? "bar" : "spline"} axis="FlowTotal" valueField={logger.Serial + "readingA"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelARef} point={{ visible: false }} />);
                    indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
                if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x02) > 0 && this.state.hasTotalB) {
                    series.push(<Series color={colorGreen[indices.green]} key={logger.Serial + ":BT"} type={this.state.switchGraphType ? "bar" : "spline"} axis="FlowTotal" valueField={logger.Serial + "readingB"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelBRef} point={{ visible: false }} />);
                    indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
            }

        }

        //pressure / analog channels
        if (this.state.showAnalog) {
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x04) > 0) {
                if (!this.state.haveMinMax || this.state.displayAs == "mean") {
                    series.push(<Series color={colorBlue[indices.blue]} key={logger.Serial + ":C"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelC" valueField={logger.Serial + "readingC"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelCRef} point={{ visible: false }} />);
                    indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                    this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                }
                else {
                    if (this.state.displayAs == "min") {
                        series.push(<Series color={colorBlue[indices.blue]} key={logger.Serial + ":Cmin"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelC" valueField={logger.Serial + "readingCmin"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelCRef + "-min"} point={{ visible: false }} />);
                        indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                        this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                    }
                    if (this.state.displayAs == "max") {
                        series.push(<Series color={colorBlue[indices.blue]} key={logger.Serial + ":Cmax"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelC" valueField={logger.Serial + "readingCmax"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelCRef + "-max"} point={{ visible: false }} />);
                        indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                        this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                    }
                }
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x08) > 0) {
                if (!this.state.haveMinMax || this.state.displayAs == "mean") {
                    series.push(<Series color={colorBrown[indices.brown]} key={logger.Serial + ":D"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelD" valueField={logger.Serial + "readingD"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelDRef} point={{ visible: false }} />);
                    indices.brown = indices.brown < colorBrown.length ? indices.brown + 1 : 0;
                    this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                }
                else {
                    if (this.state.displayAs == "min") {
                        series.push(<Series color={colorBlue[indices.blue]} key={logger.Serial + ":Dmin"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelD" valueField={logger.Serial + "readingDmin"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelDRef + "-min"} point={{ visible: false }} />);
                        indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                        this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                    }
                    if (this.state.displayAs == "max") {
                        series.push(<Series color={colorBlue[indices.blue]} key={logger.Serial + ":Dmax"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelD" valueField={logger.Serial + "readingDmax"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelDRef + "-max"} point={{ visible: false }} />);
                        indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                        this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
                    }
                }
            }
            if (logger.ChannelsAvail && (logger.ChannelsAvail & 0x10) > 0) {
                series.push(<Series color={colorOrange[indices.orange]} key={logger.Serial + ":E"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelE" valueField={logger.Serial + "readingE"} name={logger.Serial + " [" + logger.SiteNameUserFriendly + "] : " + logger.ChannelERef} point={{ visible: false }} />);
                indices.orange = indices.orange < colorOrange.length ? indices.orange + 1 : 0;
                this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
            }
        }

       
        //console.log("END renderLoggerSeries " + logger.Serial);
        //this.setState({typesOfChannelsUsed: series})
        //this.channelRef = listOfChannels;
        
        return series;
    }

    renderSiteSeries(indices: ColorIndex): React.ReactNode {

        const series: Array<React.ReactNode> = [];
        //console.log("START renderSiteSeries ");

        // Need to look at logger.channelsAvail......

        //indices.blue = 2;

        /// Check for if zonelog and makes all analog pressure
        const isZoneLog = window.location.hostname.includes('zonelog.net')
        //rainfall
        if (this.state.showRainfall) {
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x01) > 0) {
                series.push(<Series color={colorGreen[indices.green]} key={"Site:RAIN"} type="bar" axis="Rain" valueField={"SitereadingA"} name="Hourly rainfall" point={{ visible: false }} aggregation={{ method: "sum", enabled: true }} />)
                indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
            }
            this.seriesType.push("Rainfall");
        }

        //flow
        if (this.state.showFlow && !this.state.switchUnits && this.flowAs == null) {
            // Show flows in logger units
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x01) > 0 && this.state.hasFlowA) {
                series.push(<Series color={colorRed[indices.red]} key={"Site:A"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={"SiteflowRateA"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelARef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x02) > 0 && this.state.hasFlowB) {
                series.push(<Series color={colorRed[indices.red]} key={"Site:B"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={"SiteflowRateB"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelBRef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
        }
        if (this.state.showFlow && (this.state.switchUnits || this.flowAs != null)) {
            // Show flows in alt units
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x01) > 0 && this.state.hasFlowA) {
                series.push(<Series color={colorRed[indices.red]} key={"Site:A"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={"SitealtFlowRateA"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelARef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x02) > 0 && this.state.hasFlowB) {
                series.push(<Series color={colorRed[indices.red]} key={"Site:B"} type={this.state.switchGraphType ? "bar" : "spline"} axis="Flow" valueField={"SitealtFlowRateB"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelBRef} point={{ visible: false }} />);
                indices.red = indices.red < colorRed.length ? indices.red + 1 : 0;
                this.seriesType.push("Flow");
            }
        }

        //always m3 for totaliser
        if (this.state.haveTotaliser && this.state.showTotaliser) {
            // Show totaliser in m3
            if (this.state.unitsT == 'l') {
                // Use altReading if readings in l
                if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x01) > 0 && this.state.hasTotalA) {
                    series.push(<Series color={colorGreen[indices.green]} key={"Site:AT"} type="spline" axis="FlowTotal" valueField={"SitealtReadingA"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelARef} point={{ visible: false }} />);
                    indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
                if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x02) > 0 && this.state.hasTotalB) {
                    series.push(<Series color={colorGreen[indices.green]} key={"Site:BT"} type="spline" axis="FlowTotal" valueField={"SitealtReadingB"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelBRef} point={{ visible: false }} />);
                    indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
            }
            else {
                // Use Reading already in m3
                if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x01) > 0 && this.state.hasTotalA) {
                    series.push(<Series color={colorGreen[indices.green]} key={"Site:AT"} type="spline" axis="FlowTotal" valueField={"SitereadingA"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelARef} point={{ visible: false }} />);
                    indices.green = indices.green < colorGreen.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
                if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x02) > 0 && this.state.hasTotalB) {
                    series.push(<Series color={colorGreen[indices.green]} key={"Site:BT"} type="spline" axis="FlowTotal" valueField={"SitereadingB"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelBRef} point={{ visible: false }} />);
                    indices.green = indices.green < colorRed.length ? indices.green + 1 : 0;
                    this.seriesType.push("Total");
                }
            }
        }

        //pressure / analog channels
        if (this.state.showAnalog) {
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x04) > 0) {
                series.push(<Series color={colorBlue[indices.blue]} key={"Site:C"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelC" valueField={"SitereadingC"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelCRef} point={{ visible: false }} />);
                indices.blue = indices.blue < colorBlue.length ? indices.blue + 1 : 0;
                this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
            }
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x08) > 0) {
                series.push(<Series color={colorBrown[indices.brown]} key={"Site:D"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelD" valueField={"SitereadingD"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelDRef} point={{ visible: false }} />);
                indices.brown = indices.brown < colorBrown.length ? indices.brown + 1 : 0;
                this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
            }
            if (this.state.SiteChannelsAvail && (this.state.SiteChannelsAvail & 0x10) > 0) {
                series.push(<Series color={colorOrange[indices.orange]} key={"Site:E"} type={this.state.switchGraphType ? "bar" : "spline"} axis="ChannelE" valueField={"SitereadingE"} name={"Site [" + this.state.SiteNameUserFriendly + "] : " + this.state.ChannelERef} point={{ visible: false }} />);
                indices.orange = indices.orange < colorOrange.length ? indices.orange + 1 : 0;
                this.seriesType.push(isZoneLog ? "Pressure" : "Analog");
            }
        }

        
        //console.log("END renderSiteSeries ");
        
        return series;

    }

    renderTooltip(point: any): React.ReactNode {
        if (point && point.point && point.point.series && point.point.series.axis) {
            const channel = point.point.series.axis;
            let units = "m3"; // defualt ChannelC / Old Pressure units
            if (channel) {
                switch (channel) {
                    case "Flow":
                        units = this.state.switchUnits ? this.state.altUnitsF : this.state.unitsF;
                        if (this.flowAs != null) {
                            units = this.flowAs;
                        }
                        break;
                    case "ChannelC":
                        units = this.state.unitsC;
                        break;
                    case "ChannelD":
                        units = this.state.unitsD;
                        break;
                    case "ChannelE":
                        units = this.state.unitsE;
                        break;
                    case "Rain":
                        units = "mm";
                        break;
                }

                return (
                    <div>
                        <div>{channel}</div>
                        <div>{moment(point.argument).format("DD-MM-yyyy HH:mm:ss")}</div>
                        <div>{point.seriesName} = {parseFloat(point.value).toFixed(5)}
                            {
                                ' ' + units
                                //channel == "Flow" ? (this.state.switchUnits ? this.state.altUnitsF : this.state.unitsF) : channel == "ChannelC" ? this.state.unitsC : channel = "ChannelD" ? this.state.unitsD: "m3"
                            }
                        </div>
                    </div>
                )
            }
        }
    }

    renderLegendText(seriesInfo: any): string {
        let title: string = seriesInfo.seriesName;

        if (title.indexOf(" [null]") > 0) {
            title = title.replace(" [null]", "");
        }
        if (seriesInfo.seriesIndex < this.seriesType.length) 
            return "<b style={{fontFamily: 'Roboto'}}>" + this.seriesType[seriesInfo.seriesIndex] + "</b> : " + title;
        else
            return title;
    }

    renderCheckBox(item: unknown): any {
        const clicked = !item.series.isVisible()
        return (
            <svg viewBox="0 0 400 400" style={{ width: 1, height: 1 }}>
                <rect x="0.25" y="0.25" width="10" height="10" rx="0.125" ry="0.125" fill="none" stroke={item.marker.fill} strokeWidth="1"></rect>
                {!clicked && <polyline points="2.5,5 4,7.5 8,3" fill="none" stroke={item.marker.fill} strokeLinecap="round" strokeWidth="1.1"></polyline>}
            </svg>
        );




    }

    renderPeriodStats(model: string): React.ReactNode {
        if (model.startsWith("CH")) {
            return (
                
                <PeriodStatsCHR serial={this.state.loggers[0].Serial.toString()}
                    channels={[this.state.ChannelARef,
                    this.state.ChannelBRef,
                    this.state.ChannelCRef,
                    this.state.ChannelDRef,
                    this.state.ChannelERef,]}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    title={this.state.loggers[0].SiteNameUserFriendly}
                    channelsAvail={this.state.loggers[0].ChannelsAvail ? this.state.loggers[0].ChannelsAvail : 0}
                    haveFlow={this.state.haveFlow}
                    haveAnalog={this.state.haveAnalog}
                    haveTotaliser={this.state.haveTotaliser}
                    readingsData={this.state.readingsData}
                    ChannelAUnits={this.state.unitsT != "" ? this.state.unitsT : this.state.unitsR}
                    ChannelBUnits={this.state.unitsT}
                    ChannelCDp={0}
                    ChannelCUnits={this.state.unitsC}
                    ChannelDDp={0}
                    ChannelDUnits={this.state.unitsD}
                    ChannelEDp={0}
                    ChannelEUnits={this.state.unitsE}
                    flowUnits={this.flowAs == null ? this.state.switchUnitsVal : this.flowAs}
                    flowFactor={this.flowFactor}
                />
                

            )


        }

        else {

            return (
                <PeriodStatsTOR serial={this.state.loggers[0].Serial.toString()}
                    channels={[this.state.ChannelARef,
                    this.state.ChannelBRef,
                    this.state.ChannelCRef,
                    this.state.ChannelDRef,
                    this.state.ChannelERef,]}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    title={this.state.loggers[0].SiteNameUserFriendly}
                    isprimary="yes"
                    channelsAvail={this.state.loggers[0].ChannelsAvail ? this.state.loggers[0].ChannelsAvail : 0}
                    ChannelAUnits={this.state.unitsT != "" ? this.state.unitsT : this.state.unitsR}
                    ChannelBUnits={this.state.unitsT}
                    ChannelCDp={0}
                    ChannelCUnits={this.state.unitsC}
                    ChannelDDp={0}
                    ChannelDUnits={this.state.unitsD}
                    ChannelEDp={0}
                    ChannelEUnits={this.state.unitsE}
                    flowUnits={this.flowAs == null ? this.state.switchUnitsVal : this.flowAs}
                    flowFactor={this.flowFactor}
                />

            )

        }

    }

    renderAlarmLevels(al: Alarm, baseColour: string, align: string, altUnits: boolean): React.ReactNode {
        // Extract RGB from baseColour and set band colour to it with 15%
        if (altUnits) {
            // Flow displayed as m3/h so need to scale alarms
            return <Strip key={al.id} startValue={al.setLevel * 1000 / 3600} endValue={al.clrLevel * 1000 / 3600} color={this.hexToRGB(baseColour, 0.15)}>
                <Label text={al.message} horizontalAlignment={align} />
            </Strip>
        }
        else {

            return <Strip key={al.id} startValue={al.setLevel} endValue={al.clrLevel} color={this.hexToRGB(baseColour, 0.15)}>
                <Label text={al.message} horizontalAlignment={align} />
            </Strip>
        }
    }

    hexToRGB(hex: string, alpha: number): string {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    getFlowUnits(): string {
        if (this.flowAs == null) {
            if ((!this.state.switchUnits && this.state.unitsF == 'm3/h')
                || (this.state.switchUnits && this.state.unitsF == 'l/s')) {
                return 'm3/h';
            }
            else {
                return 'l/s';
            }
        }
        else {
            return this.flowAs;
        }
    }

    handleUnzoom(): void {
        this.setState({
            minFValue: this.state.originalStateValues.minF,
            maxFValue: this.state.originalStateValues.maxF,
            altMinFValue: this.state.originalStateValues.altMinF,
            altMaxFValue: this.state.originalStateValues.altMaxF,
            minTValue: this.state.originalStateValues.minT,
            maxTValue: this.state.originalStateValues.maxT,
            altMinTValue: this.state.originalStateValues.altMinT,
            altMaxTValue: this.state.originalStateValues.altMaxT,
            minCValue: this.state.originalStateValues.minC,
            maxCValue: this.state.originalStateValues.maxC,
            minDValue: this.state.originalStateValues.minD,
            maxDValue: this.state.originalStateValues.maxD,
            minEValue: this.state.originalStateValues.minE,
            maxEValue: this.state.originalStateValues.maxE
        })
        this.setState({
            zoomLevel: 1,
            axisQuery: false
        });
    }

    handleMinMaxAxisValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let valueInput = event.target.value;
        const name = event.target.name;
        const queryValues = { ...this.state.queryGraphValues }; 
        // Convert the value if it ends with 'M' or 'm'
        if (valueInput.slice(valueInput.length - 1) === 'M' || valueInput.slice(valueInput.length - 1) === 'm') {
            valueInput = (parseFloat(valueInput.slice(0, valueInput.length - 1)) * 1000000).toString();
        }
        if (valueInput.slice(valueInput.length - 1) === 'K' || valueInput.slice(valueInput.length - 1) === 'k') {
            valueInput = (parseFloat(valueInput.slice(0, valueInput.length - 1)) * 1000).toString();
        }
        queryValues[name] = valueInput;
        this.setState({ queryGraphValues: queryValues }); 
    };


    


    queryConfirm(): void {
        this.setState({ scaleQuery: true })
        this.setState({
            originalStateValues: {
                minF: this.state.minFValue,
                maxF: this.state.maxFValue,
                altMinF: this.state.altMinFValue,
                altMaxF: this.state.altMaxFValue,
                minT: this.state.minTValue,
                maxT: this.state.maxTValue,
                altMinT: this.state.altMinTValue,
                altMaxT: this.state.altMaxTValue,
                minC: this.state.minCValue,
                maxC: this.state.maxCValue,
                minD: this.state.minDValue,
                maxD: this.state.maxDValue,
                minE: this.state.minEValue,
                maxE: this.state.maxEValue
            },
            axisQuery: true
        })
        const { selectedAxis } = this.state;

        
        this.setState({ selectedAxis }, () => {
            const min = parseFloat(this.state.queryGraphValues.min)
            const max = parseFloat(this.state.queryGraphValues.max)

            if (selectedAxis === "left") {
                if (this.state.selectedAxisValueLeft === 'Flow') {
                    this.setState({ minFValue: min, maxFValue: max, altMinFValue: min, altMaxFValue: max })
                } else if (this.state.selectedAxisValueLeft === 'ChannelC') {
                    this.setState({ minCValue: min, maxCValue: max })
                } else if (this.state.selectedAxisValueLeft === 'ChannelE') {
                    this.setState({ minEValue: min, maxEValue: max })
                } else if (this.state.selectedAxisValueLeft === 'Rain') {
                    this.setState({ minFValue: min, maxFValue: max })
                }
            } else if (selectedAxis === "right") {
                if (this.state.selectedAxisValueRight === 'FlowTotal' && this.state.unitsT == 'm3') {
                    this.setState({ minTValue: min, maxTValue: max })
                } else if (this.state.selectedAxisValueRight === 'FlowTotal' && this.state.unitsT == 'l') {
                    this.setState({ altMinTValue: min, altMaxTValue: max })
                } else if (this.state.selectedAxisValueRight === 'ChannelD') {
                    this.setState({ minDValue: min, maxDValue: max })
                }
            }
            
            this.toggleGraphQueryModal(selectedAxis)
        })
    }

    handlePreviousGraphData(): void {
        const startDate = moment(this.state.startDate).subtract(moment(this.state.endDate).diff(this.state.startDate, 'days'), 'days')
        const endDate = moment(this.state.endDate).subtract(moment(this.state.endDate).diff(this.state.startDate, 'days'), 'days')

        
        this.onDateChange(startDate, endDate, false)
    }

    handleForwardGraphData(): void {
        
        const finalDate = moment(this.state.endDate).add(moment(this.state.endDate).diff(this.state.startDate, 'days'), 'days').toDate()

        this.onDateChange(moment(finalDate).subtract(moment(this.state.endDate).diff(this.state.startDate, 'days'), 'days'), finalDate, false)
    }


    render(): ReactNode {
        const { classes, history } = this.props;
        const colors = new ColorIndex();
        colors.blue = 0;
        colors.red = 0;
        colors.green = 0;
        colors.brown = 0;
        colors.orange = 0;

        const me = this;

        //console.log("RENDER State Logger Length  " + me.state.loggers.length);

        //Determine axis min/max
        let maxA = this.state.maxFValue;
        let minA = this.state.minFValue;
        let altMaxA = this.state.altMaxFValue;
        let altMinA = this.state.altMinFValue;

        let maxC = this.state.maxCValue;
        let minC = this.state.minCValue;
        let maxD = this.state.maxDValue;
        let minD = this.state.minDValue;
        let maxE = this.state.maxEValue;
        let minE = this.state.minEValue;



        if (this.state.showAlarmLevels || this.state.showSiteAlarmLevels) {
            if (this.state.maxFValueA > maxA) {
                maxA = this.state.maxFValueA;
            }
            if (this.state.minFValueA < minA) {
                minA = this.state.minFValueA;
            }
            if (this.state.altMaxFValueA > altMaxA) {
                altMaxA = this.state.altMaxFValueA;
            }
            if (this.state.altMinFValueA < altMinA) {
                altMinA = this.state.altMinFValueA;
            }
            if (this.state.maxCValueA > maxC) {
                maxC = this.state.maxCValueA;
            }
            if (this.state.minCValueA < minC) {
                minC = this.state.minCValueA;
            }
            if (this.state.maxDValueA > maxD) {
                maxD = this.state.maxDValueA;
            }
            if (this.state.minDValueA < minD) {
                minD = this.state.minDValueA;
            }
            if (this.state.maxEValueA > maxE) {
                maxE = this.state.maxEValueA;
            }
            if (this.state.minEValueA < minE) {
                minE = this.state.minEValueA;
            }
        }



        // AQGD-605 - Sync Pressure channels C&D if both same units (typical PSV monitoring use case) and single logger graphed
        if (this.state.loggers.length == 1 && this.state.unitsC == this.state.unitsD) {
            if (maxC > maxD) {
                maxD = maxC;
            }
            if (maxD > maxC) {
                maxC = maxD;
            }
            if (minC < minD) {
                minD = minC;
            }
            if (minD < minC) {
                minC = minD;
            }
        }


        let numberOfControls = 0
        if (this.state.siteAlarmLevels.length > 0) {
            numberOfControls++
        }
        if (this.state.alarmLevels.length > 0) {
            numberOfControls++
        }
        if (this.state.haveAnalog) {
            numberOfControls++
        }
        if (this.state.haveFlow) {
            numberOfControls++
        }
        if (this.state.haveTotaliser) {
            numberOfControls++
        }
        if (this.state.haveMinMax) {
            numberOfControls++
        }
        
        const axis = [[], []]

        this.state.loggers.map((logger, index) => {
            // Get the series and extract axis values
            const series = this.renderLoggerSeries(logger, index, colors).map(item => item.props.axis);
             
            // Add unique axis values to the array
            series.forEach(axisValue => {
                if (axisValue === 'Flow' || axisValue === 'ChannelC' || axisValue === 'ChannelE' || axisValue === 'Rain') {
                    if (!axis[0].includes(axisValue)) {
                        axis[0].push(axisValue);
                       
                    }  
                }
                if (axisValue === 'FlowTotal' || axisValue === 'ChannelD') {
                    if (!axis[1].includes(axisValue)) {
                        axis[1].push(axisValue);
                    }
                }
            });
            return null; // We will render the Select outside of map
        })

        //console.log(this.props.location.state.site)
        if (this.props.location && this.props.location.state && this.props.location.state.site) {
            const siteSeries = this.renderSiteSeries(colors).map(item => item.props.axis);
            siteSeries.forEach(axisValue => {
                if (axisValue === 'Flow' || axisValue === 'ChannelC' || axisValue === 'ChannelE' || axisValue === 'Rain') {
                    if (!axis[0].includes(axisValue)) {
                        axis[0].push(axisValue);
                    }
                }
                if (axisValue === 'FlowTotal' || axisValue === 'ChannelD') {
                    if (!axis[1].includes(axisValue)) {
                        axis[1].push(axisValue);
                    }
                }
            });
            return null;
        }


        return (
            <div>
                
                {!this.state.loading && !this.state.apiFailed && this.state.graphData.length < 1 &&
                    <div><h4>No Data available between the dates selected, try expanding the data range</h4></div>
                }
                {this.state.loading &&
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1300
                    }}>
                        <ClipLoader
                            size={150}
                            color={"#123abc"}
                            loading={this.state.loading}
                        />
                    </div>
                }
                {!this.state.loading &&
                    <div>
                        
                        {this.state.apiFailed &&
                            <ApiFailed />
                        }
                        {!this.state.apiFailed &&
                            <div>
                                
                                <GridContainer>
                                    <div style={{ display: 'inline', float: "right" }}>
                                        <div>
                                            <Dialog open={this.state.exportDialog} style={{zIndex: 10000} }>
                                                <Modal.Header closeButton onHide={(): void => this.toggleExportModal()}>
                                                    <Modal.Title id="example-custom-modal-styling-title">
                                                        Export Data
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="show-grid">
                                                    <GridContainer>
                                                        <GridItem xs={6} sm={6} md={12} >
                                                            Export as:&nbsp;
                                                            <RadioGroup aria-label="exportAs" name="exportAs" value={this.state.exportType} onChange={this.handleExportAsChange.bind(this)}>
                                                                <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
                                                                <FormControlLabel value="XLSX" control={<Radio />} label="XLSX" />
                                                                <FormControlLabel value="XLSXT" control={<Radio />} label="XLSX Table (filter options enabled)" />
                                                            </RadioGroup>
                                                        </GridItem>
                                                        <GridItem xs={6} sm={6} md={6}>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                            {this.state.loading &&
                                                                <div>Export not enabled until all readings are loaded</div>
                                                            }
                                                        </GridItem>
                                                    </GridContainer>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <MaterialButton color="primary" variant="contained" style={{ marginRight: "10px" }} onClick={(): void => this.exportConfirm()} disabled={!this.state.exportEnable}>Export</MaterialButton>
                                                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.toggleExportModal()}>Cancel</MaterialButton>
                                                </Modal.Footer>
                                            </Dialog>

                                            <Modal show={this.state.statsDialog} style={{ marginTop: "100px" }} dialogClassName="modal-75w">
                                                <Modal.Header closeButton onHide={(): void => this.toggleStatsModal()}>
                                                    <Modal.Title id="example-custom-modal-styling-title" >
                                                        Stats for period
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body className="show-grid">
                                                    <GridContainer>
                                                        <GridItem xs={6} sm={6} md={12} >
                                                            {this.state.loggers.length > 0  ? this.renderPeriodStats(this.state.loggers[0].Model) : 'loading'}
                                                        </GridItem>
                                                    </GridContainer>
                                                </Modal.Body>
                                                <Modal.Footer>

                                                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.toggleStatsModal()}>Cancel</MaterialButton>
                                                </Modal.Footer>
                                            </Modal>

                                      




                                            {/*Dialog for graph scaling*/}
                                            <Dialog open={this.state.queryDialog}>
                                                <Modal.Header closeButton onHide={(): void => this.toggleGraphQueryModal(this.state.selectedAxis)}>
                                                    <Modal.Title id="example-custom-modal-styling-title">
                                                        Change scale of axis
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body >
                                                    <div style={{ margin: " 0 8px" }}>
                                                        <p >Which axis to scale?</p> 
                                                        
                                                        {this.state.selectedAxis === 'left' ? 
                                                            <Select style={{width: "100%"}} value={this.state.selectedAxisValueLeft} onChange={(e):void => this.setState({selectedAxisValueLeft: e.target.value})}>{axis[0].map((axisLeft, i) => <MenuItem key={i} value={axisLeft}>{axisLeft}</MenuItem>)}</Select>
                                                            :
                                                            <Select style={{ width: "100%" }} value={this.state.selectedAxisValueRight} onChange={(e): void => this.setState({ selectedAxisValueRight: e.target.value})}>{axis[1].map((axisRight, i) => <MenuItem key={i} value={axisRight}>{axisRight}</MenuItem>)}</Select>     
                                                            }
                                                        {this.state.selectedAxisValueRight === 'FlowTotal' && this.state.selectedAxis === 'right' && <p style={{margin: "10px 0 0 0"} }>You can enter short hand (e.g. either 5000000 or 5M / 5000 or 5K)</p>}
                                                    </div>
                                                    
                                                    <div style={{ display: "flex" }}>
                                                        <TextField
                                                            label="Min"
                                                            id="outlined-start-adornment"
                                                            sx={{ m: 1 }}
                                                            name="min"
                                                            onChange={this.handleMinMaxAxisValueChange}
                                                        />
                                                        <TextField
                                                            label="Max"
                                                            id="outlined-start-adornment"
                                                            sx={{ m: 1 }}
                                                            name="max"
                                                            onChange={this.handleMinMaxAxisValueChange} />
                                                    </div>

                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <MaterialButton color="primary" variant="contained" style={{marginRight: "10px"}} onClick={(): void => this.queryConfirm()}>Confirm</MaterialButton>
                                                    <MaterialButton color="secondary" variant="contained" onClick={(): void => this.toggleGraphQueryModal(this.state.selectedAxis)}>Cancel</MaterialButton>
                                                </Modal.Footer>
                                            </Dialog>
                                        </div>
                                    </div>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card>
                                            <CardHeader color="primary" className="view view-cascade  d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
                                                <Grid container >
                                                    <Grid item md={9 - numberOfControls} xs={12}>
                                                        <div style={{ display: 'flex' }}>
                                                            {this.props.location && this.props.location.state && this.props.location.state.showBackButton &&
                                                                <IconButton
                                                                    style={{ display: 'inline', marginTop: -14 }}
                                                                    onClick={(): void => history.goBack()}
                                                                    size="large"><ArrowBackIcon style={{ fill: "white" }} />
                                                                </IconButton>
                                                            }
                                                            {this.props.location && this.props.location.state && this.props.location.state.site == null &&
                                                                <div style={{marginRight: "6px"}}>
                                                                    Loggers:&nbsp; <br />
                                                                    {this.props.location.state.showBackButton && this.state.loggers.map((logger: APILoggerDetail) => {
                                                                        return <Chip key={logger.LoggerId} style={{marginRight: "5px"}}
                                                                            label={logger.Serial} onDelete={(): void => this.removeLogger(logger.Serial)} color="primary" />
                                                                    })
                                                                    }
                                                                    {!this.props.location.state.showBackButton && this.state.loggers.map((logger: APILoggerDetail) => {
                                                                        return <Chip key={logger.LoggerId} style={{ marginRight: "5px" }}
                                                                            label={logger.Serial} color="primary" />
                                                                    })
                                                                    }

                                                                    {this.state.loggers.length == 0 &&
                                                                        <div style={{ display: 'inline', fontWeight: "bold" }}>None selected!</div>
                                                                    }
                                                                </div>
                                                            }
                                                            {this.props.location && this.props.location.state && this.props.location.state.site != null &&
                                                                <div>
                                                                    Site: &nbsp;{this.state.SiteNameUserFriendly}
                                                                </div>
                                                            }
                                                        </div>
                                                    </Grid>
                                                   
                                                    <Grid xs={0} >
                                                        <div style={{ display: 'none', whiteSpace: 'nowrap' }}>
                                                            <Button variant="contained" color="secondary" onClick={(): boolean => this.reloadData(this.props.location.state.groupId, this.props.location.state.loggers, this.props.location.state.site)} >Redraw</Button>
                                                        </div>
                                                    </Grid>
                                                    {this.state.siteAlarmLevels.length > 0 && <Grid xs={1} md={1}>
                                                        <div style={{textAlign: "center"}}>
                                                            <div>Show Site Alarms:<br />
                                                                <Checkbox
                                                                    checked={this.state.showSiteAlarmLevels}
                                                                    onChange={this.toggleSiteAlarms}
                                                                    sx={{
                                                                        color: "white",
                                                                        '&.Mui-checked': {
                                                                            color: "white"

                                                                        },
                                                                    }}/></div>
                                                        </div>
                                                    </Grid>}
                                                    {this.state.alarmLevels.length > 0 && <Grid xs={1} md={1} >
                                                        <div style={{textAlign: "center"}}>
                                                            <div>Show Alarms:<br />
                                                                <Checkbox
                                                                    checked={this.state.showAlarmLevels}
                                                                    onChange={this.toggleAlarms}
                                                                    sx={{
                                                                        color: "white",
                                                                        '&.Mui-checked': {
                                                                            color: "white"

                                                                        },
                                                                    }}/></div>
                                                        </div>
                                                    </Grid>}
                                                    {this.state.haveAnalog && <Grid xs={1} >
                                                        <div style={{textAlign: "center"}}>
                                                            <div>{window.location.hostname.includes('zonelog.net') ? 'Show pressure' : 'Show Analog:'}<br />
                                                                <Checkbox
                                                                    checked={this.state.showAnalog}
                                                                    onChange={this.toggleAnalog}
                                                                    sx={{
                                                                        color: "white",
                                                                        '&.Mui-checked': {
                                                                            color: "white"

                                                                        },
                                                                    }}/></div>
                                                        </div>
                                                    </Grid>}
                                                    {this.state.haveFlow && <Grid xs={1} >
                                                        <div style={{textAlign: "center"}}>
                                                            <div>Show flow:<br />
                                                                <Checkbox
                                                                    checked={this.state.showFlow}
                                                                    onChange={this.toggleFlow}
                                                                    sx={{
                                                                        color: "white",
                                                                        '&.Mui-checked': {
                                                                            color: "white"
                                                                        },
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </Grid>}
                                                    {this.state.haveTotaliser && <Grid xs={1} >
                                                        <div style={{ textAlign: "center" }}>
                                                            <div>Show total:<br />
                                                                <Checkbox
                                                                    checked={this.state.showTotaliser}
                                                                    onChange={this.toggleTotaliser}
                                                                    sx={{
                                                                        color: "white",
                                                                        '&.Mui-checked': {
                                                                            color: "white"
                                                                        },
                                                                    }}
                                                                /></div>
                                                        </div>
                                                    </Grid>}

                                                    <Grid item xs={1}  >
                                                        <div style={{ display: 'inline', textAlign: "center" }}>
                                                            <div style={{ textAlign: "center" }}> Graph type: <br /></div>
                                                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                <ToggleButtonGroup
                                                                    value={this.state.switchGraphVal}
                                                                    exclusive
                                                                    onChange={(e) => this.toggleGraphType(e)}
                                                                    aria-label="flow-units"
                                                                    style={{ border: "1px solid white" }}
                                                                    size="small"

                                                                >
                                                                    <ToggleButton value="l" aria-label="line" style={{ backgroundColor: this.state.switchGraphType ? "inherit" : "white", color: !this.state.switchGraphType ? "black" : "white" }}>
                                                                        <span style={{ textTransform: "none", textDecoration: this.state.switchGraphType ? "none" : "underline" }}>
                                                                            Line
                                                                        </span>
                                                                    </ToggleButton>
                                                                    <ToggleButton value="c" aria-label="column" style={{ backgroundColor: !this.state.switchGraphType ? "inherit" : "white", color: !this.state.switchGraphType ? "white" : "black" }}>
                                                                        <span style={{ textTransform: "none", textDecoration: !this.state.switchGraphType ? "none" : "underline" }}>
                                                                            Column
                                                                        </span>
                                                                    </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>

                                                    
                                                    
                                                    {this.state.haveFlow && this.flowAs == null && <Grid item xs={1} >
                                                        <div style={{ display: 'inline', textAlign: "center" }}>
                                                            <div style={{ textAlign: "center" }}>Flow as:<br /></div>
                                                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                
                                                                <ToggleButtonGroup
                                                                    value={this.state.switchUnitsVal}
                                                                    exclusive
                                                                    onChange={(event, value):void => this.toggleFlowUnit(event, value)}
                                                                    aria-label="flow-units"
                                                                    style={{ border: "1px solid white" }}
                                                                    size="small"
                                                                >
                                                                    <ToggleButton value="m3/h" aria-label="metre-cubed" style={{ backgroundColor: this.state.switchUnits ? "white" : "inherit", color: !this.state.switchUnits ? "inherit" : "black" }} >
                                                                    <span style={{ textTransform: "none", textDecoration: this.state.switchUnits ? "underline" : "none" }}>
                                                                        m<sup>3</sup>/h
                                                                    </span>
                                                                    </ToggleButton>
                                                                    
                                                                    <ToggleButton value="l/s" aria-label="litres" style={{ backgroundColor: !this.state.switchUnits ? "white" : "inherit", color: !this.state.switchUnits ? "black" : "inherit" }}>
                                                                    <span style={{ textTransform: "none", textDecoration: !this.state.switchUnits ? "underline" : "none" }}>
                                                                        l/s
                                                                    </span>
                                                                </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>}
                                                    {this.state.haveFlow && this.flowAs != null && <Grid item xs={1} >
                                                        <div style={{ display: 'inline', textAlign: "center" }}>
                                                            <div style={{ textAlign: "center" }}>Flow as:<br /></div>
                                                            <div style={{display: "flex", justifyContent: "space-around", marginTop: "8px"} }>
                                                            {this.flowAs == "m3/h" ?
                                                                <span style={{ textTransform: "none", padding: 2, textAlign: "center" }}>
                                                                    m<sup>3</sup>/h
                                                                </span>
                                                                :
                                                                <span style={{ textTransform: "none", padding: 2, textAlign: "center" }}>
                                                                    {this.flowAs}
                                                                </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Grid>}

                                                    {this.state.haveMinMax &&  this.state.displayAs != null && <Grid item xs={1} >
                                                        {console.log(this.state.haveMinMax) }
                                                        <div style={{ display: 'inline', textAlign: "center" }}>
                                                            <div style={{ textAlign: "center" }}>Display:<br /></div>
                                                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                <ToggleButtonGroup
                                                                    value={this.state.displayAs}
                                                                    exclusive
                                                                    onChange={(event, value): void => this.toggleDisplay(event, value)}
                                                                    aria-label="display-type"
                                                                    style={{ border: "1px solid white" }}
                                                                    size="small"
                                                                >
                                                                    <ToggleButton value="min" aria-label="min" style={{ backgroundColor: this.state.displayAs == "min" ? "white" : "inherit", color: this.state.displayAs != "min" ? "inherit" : "black" }} >
                                                                        <span style={{ textTransform: "none", textDecoration: this.state.displayAs == "min" ? "underline" : "none" }}>
                                                                            Min
                                                                        </span>
                                                                    </ToggleButton>

                                                                    <ToggleButton value="mean" aria-label="litres" style={{ backgroundColor: this.state.displayAs == "mean" ? "white" : "inherit", color: this.state.displayAs != "mean" ? "inherit" : "black"  }}>
                                                                        <span style={{ textTransform: "none", textDecoration: this.state.displayAs == "mean" ? "underline" : "none" }}>
                                                                            Mean
                                                                        </span>
                                                                    </ToggleButton>

                                                                    <ToggleButton value="max" aria-label="min" style={{ backgroundColor: this.state.displayAs == "max" ? "white" : "inherit", color: this.state.displayAs != "max" ? "inherit" : "black" }} >
                                                                        <span style={{ textTransform: "none", textDecoration: this.state.displayAs == "max" ? "underline" : "none" }}>
                                                                            Max
                                                                        </span>
                                                                    </ToggleButton>
                                                                </ToggleButtonGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>}
                                                  
                                                        

                                                    <Grid item xs={7} >
                                                        
                                                        {this.props.showDates &&
                                                            <div style={{ display: 'inline' }}>
                                                                <DateRange startDate={this.state.startDate} endDate={this.state.endDate} rainfall={false} onDateChange={this.onDateChange.bind(this)} type="date" />
                                                                
                                                            </div>
                                                        }
                                                            
                                                        
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {this.state.loggers.length == 1 &&
                                                            <MaterialButton variant="contained" style={{margin: "21px 0 0 30px"}} onClick={(): void => this.setState({ statsDialog: !this.state.statsDialog })}>View Stats for period</MaterialButton>
                                                        }
                                                    </Grid>

                                                </Grid> 
                                            </CardHeader>
                                            <CardBody>
                                                
                                                <div style={{ background: '	#FFFAFA', height: 700, padding: 5, margin: 5, border: '1px solid black' }}>
                                                    
                                                    {this.state.graphLoading ?
                                                        <div style={{
                                                            position: 'fixed', left: '50%', top: '50%',
                                                            transform: 'translate(-50%, 0%)',
                                                            zIndex: 1300
                                                        }}>
                                                            <ClipLoader
                                                                size={100}
                                                                color={"#123abc"}
                                                                loading={this.state.graphLoading}
                                                            />
                                                        </div>
                                                        :
                                                        !this.state.graphHidden &&
                                                        <div>
                                                                <div style={{backgroundColor: "lightgray", display: "flex", justifyContent: "space-between"} }> 
                                                                    <Button style={{margin: "10px 5px"}} variant="contained" disabled={this.state.graphData.length === 0 } color="primary" onClick={this.toggleExportModal.bind(this)}>Export Graph Data</Button>
                                                                    <div>
                                                                        <p style={{textAlign: "center", margin: "10px 0 0 0"} }>Drag and select an area of the graph to zoom</p>
                                                                        <p>Click on a logger channel in the legend / key (below) to <b>hide or show</b> channel data</p>
                                                                    </div>
                                                                    <Button variant="contained" style={{ margin: "10px 5px" }} color="secondary" href="#" onClick={(e: any): void => { e.preventDefault(); this.handleUnzoom(); }}>unzoom / reset the scale</Button>
                                                                </div>
                                                            <Box width="100%">
                                                                    <Item ratio={1}>
                                                                        <div style={{display: "flex", flexDirection: "column", width: "100%"} }>
                                                                            <TooltipMaterial title="Change the left axis scale">
                                                                                <IconButton aria-label="query scale" style={{ outline: "none" }}>
                                                                                    <QueryStatsIcon onClick={(): void => this.toggleGraphQueryModal("left")} />
                                                                                </IconButton>
                                                                            </TooltipMaterial>
                                                                            <div style={{ display: "flex", justifyContent: 'space-around', flexDirection: "column", width: "100%", marginTop: "250px" }}>
                                                                                <TooltipMaterial title="Get data for previous date range">
                                                                                    <IconButton style={{ background: '#EED202', margin: "0 8px" }} onClick={():void => this.handlePreviousGraphData()}>
                                                                                        <ArrowBackIcon style={{ background: '#EED202', borderRadius: "1000px", color: "black" } } />
                                                                                    </IconButton>
                                                                                </TooltipMaterial>
                                                                            </div>
                                                                        </div>
                                                                    </Item>
                                                                <Item ratio={30}>
                                                                       
                                                                        <Chart onLegendClick={this.legendClickHandler} dataSource={this.state.graphData}>
                                                                            {console.log(this.state.graphData, this.state.loading) }
                                                                        <Size height={600} width="100%" />

                                                                        <CommonSeriesSettings
                                                                            argumentField="date"
                                                                            ignoreEmptyPoints={true}>
                                                                            <Point size={15} />
                                                                            <Font weight={900} size={40} />
                                                                        </CommonSeriesSettings>
                                                                        <CommonAxisSettings
                                                                            valueMarginsEnabled={true}
                                                                            minValueMargin={this.state.axisQuery ? 0 : 0.1}    //add extra 10% to show a 'gap'
                                                                            maxValueMargin={this.state.axisQuery ? 0 : 0.1}    //add extra 10% to show a 'gap'
                                                                            />
                                                                            
                                                                            {
                                                                                
                                                                            //render any relevant series for this logger ie draw <Series> tags
                                                                            this.state.loggers.map((logger, index) => {
                                                                                //console.log("MAP rendering " + logger.Serial)
                                                                                return this.renderLoggerSeries(logger, index, colors);
                                                                            })
                                                                            
                                                                            }
                                                                            
                                                                            {this.props.location && this.props.location.state && this.props.location.state.site != null &&
                                                                            this.renderSiteSeries(colors)
                                                                            }
                                                                            {console.log(this.props.location.site) }
                                                                        {(this.state.showFlow && this.state.haveFlow && !this.state.switchUnits && this.flowAs == null) && //l/s
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="Flow"
                                                                                visualRange={{ startValue: minA, endValue: maxA }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Flow " + this.state.unitsF, font: { color: "red", size: 12 } }}
                                                                                position="left"
                                                                                color="red"
                                                                            >
                                                                                <Label font={{ color: "red" }} />
                                                                                {this.state.showAlarmLevels &&
                                                                                    // Alarm levels shown as stripes on ValueAxis
                                                                                    this.state.alarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "left", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "right", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </ValueAxis>
                                                                        }
                                                                            {console.log(this.props.location)}
                                                                        {(this.state.showFlow && this.state.haveFlow && this.state.switchUnits && this.flowAs == null) && //m3/h
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="Flow"
                                                                                visualRange={{ startValue: altMinA, endValue: altMaxA }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Flow " + this.state.altUnitsF, font: { color: "red", size: 12 } }}
                                                                                position="left"
                                                                                color="red"
                                                                            >
                                                                                <Label font={{ color: "red" }} />
                                                                                {this.state.showAlarmLevels &&
                                                                                    // Alarm levels shown as stripes on ValueAxis
                                                                                    this.state.alarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "left", true);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "right", true);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </ValueAxis>
                                                                        }
                                                                        {(this.state.showFlow && this.state.haveFlow && this.flowAs != null) && //fixed flow units
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="Flow"
                                                                                visualRange={{ startValue: altMinA, endValue: altMaxA }} //add extra 10% to show a 'gap'
                                                                                allowDecimals={true}
                                                                                title={{ text: "Flow " + this.flowAs, font: { color: "red", size: 12 } }}
                                                                                position="left"
                                                                                color="red"
                                                                            >
                                                                                <Label font={{ color: "red" }} />
                                                                                {this.state.showAlarmLevels &&
                                                                                    // Alarm levels shown as stripes on ValueAxis
                                                                                    this.state.alarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "left", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "A") {
                                                                                            return this.renderAlarmLevels(al, colorRed[0], "right", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </ValueAxis>
                                                                        }

                                                                        {/*
                                                            {(this.state.showTotaliser && this.state.haveTotaliser && !this.state.switchUnits) &&
                                                                <ValueAxis
                                                                    width={2}
                                                                    name="FlowTotal"
                                                                    visualRange={{ startValue: this.state.minTValue, endValue: this.state.maxTValue }}
                                                                    allowDecimals={true}
                                                                    title={{ text: "Totaliser " + this.state.unitsT, font: { color: "green", size: 12 } }}
                                                                    position="left"
                                                                    color="green"
                                                                >
                                                                    <Label font={{ color: "green" }} />
                                                                </ValueAxis>
                                                            }
                                                            {(this.state.showTotaliser && this.state.haveTotaliser && this.state.switchUnits) &&
                                                                <ValueAxis
                                                                    width={2}
                                                                    name="FlowTotal"
                                                                    visualRange={{ startValue: this.state.altMinTValue, endValue: this.state.altMaxTValue }}
                                                                    allowDecimals={true}
                                                                    title={{ text: "Totaliser " + this.state.altUnitsT, font: { color: "green", size: 12 } }}
                                                                    position="left"
                                                                    color="green"
                                                                >
                                                                    <Label font={{ color: "green" }} />
                                                                </ValueAxis>
                                                            }
                                                            */}

                                                                        {/*totaliser always m3 except gal/m*/}
                                                                        {(this.state.showTotaliser && this.state.haveTotaliser && this.state.haveFlow && this.state.unitsT == 'm3' && this.flowAs != 'gal/m') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="FlowTotal"
                                                                                visualRange={{ startValue: this.state.minTValue, endValue: this.state.maxTValue }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Totaliser m3", font: { color: "green", size: 12 } }}
                                                                                position="right"
                                                                                color="green"
                                                                            >
                                                                                <Label font={{ color: "green" }} />

                                                                            </ValueAxis>
                                                                        }

                                                                        {(this.state.showTotaliser && this.state.haveTotaliser && this.state.haveFlow && this.state.unitsT == 'l' && this.flowAs != 'gal/m') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="FlowTotal"
                                                                                visualRange={{ startValue: this.state.altMinTValue, endValue: this.state.altMaxTValue }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Totaliser m3", font: { color: "green", size: 12 } }}
                                                                                position="right"
                                                                                color="green"
                                                                            >
                                                                                <Label font={{ color: "green" }} />
                                                                            </ValueAxis>
                                                                        }


                                                                        {(this.state.showTotaliser && this.state.haveTotaliser && this.state.haveFlow && this.state.unitsT == 'feet3') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="FlowTotal"
                                                                                visualRange={{ startValue: this.state.altMinTValue, endValue: this.state.altMaxTValue }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Totaliser Cubic Feet", font: { color: "green", size: 12 } }}
                                                                                position="right"
                                                                                color="green"
                                                                            >
                                                                                <Label font={{ color: "green" }} />
                                                                            </ValueAxis>
                                                                        }

                                                                        {/*(this.state.showPressure && this.state.havePressure) &&
                                                                <ValueAxis
                                                                    width={2}
                                                                    name="Pressure"
                                                                    visualRange={{ startValue: this.state.minPValue, endValue: this.state.maxPValue }}
                                                                    allowDecimals={true}
                                                                    title={{ text: "Pressure " + this.state.unitsP, font: { color: "blue", size: 12 } }}
                                                                position="left"
                                                                color="blue"
                                                                >
                                                                <Label font={{ color: "blue" }} />
                                                                </ValueAxis>
                                                            */
                                                                        }

                                                                        {(this.state.showAnalog && this.state.haveAnalog && this.state.unitsC != '') &&


                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="ChannelC"
                                                                                visualRange={{ startValue: this.state.minCValue, endValue: this.state.maxCValue }}

                                                                                allowDecimals={true}
                                                                                title={{ text: this.state.ChannelCRef + ' ' + this.state.unitsC, font: { color: colorBlue[0], size: 12 } }}
                                                                                position="left"
                                                                                color={colorBlue[0]}
                                                                            >

                                                                                <Label font={{ color: colorBlue[0] }} />


                                                                                {this.state.showAlarmLevels &&
                                                                                    // Alarm levels shown as stripes on ValueAxis
                                                                                    this.state.alarmLevels.map((al) => {
                                                                                        if (al.channel == "C") {
                                                                                            return this.renderAlarmLevels(al, colorBlue[0], "left", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "C") {
                                                                                            return this.renderAlarmLevels(al, colorBlue[0], "right", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }

                                                                            </ValueAxis>


                                                                        }

                                                                        {(this.state.showAnalog && this.state.haveAnalog && this.state.unitsD != '') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="ChannelD"
                                                                                visualRange={{ startValue: this.state.minDValue, endValue: this.state.maxDValue }}

                                                                                allowDecimals={true}
                                                                                title={{ text: this.state.ChannelDRef + ' ' + this.state.unitsD, font: { color: colorBrown[0], size: 12 } }}
                                                                                position="right"
                                                                                color={colorBrown[0]}
                                                                            >
                                                                                <Label font={{ color: colorBrown[0] }} />
                                                                                {this.state.showAlarmLevels &&
                                                                                    // Alarm levels shown as stripes on ValueAxis
                                                                                    this.state.alarmLevels.map((al) => {

                                                                                        if (al.channel == "D") {
                                                                                            return this.renderAlarmLevels(al, colorBrown[0], "left", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "D") {
                                                                                            return this.renderAlarmLevels(al, colorBrown[0], "right", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </ValueAxis>
                                                                        }
                                                                        {(this.state.showAnalog && this.state.haveAnalog && this.state.unitsE != '') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="ChannelE"
                                                                                visualRange={{ startValue: this.state.minEValue, endValue: this.state.maxEValue }}

                                                                                allowDecimals={true}
                                                                                title={{ text: this.state.ChannelERef + ' ' + this.state.unitsE, font: { color: colorOrange[0], size: 12 } }}
                                                                                position="left"
                                                                                color={colorOrange[0]}
                                                                            >


                                                                                <Label font={{ color: colorOrange[0] }} />
                                                                                {this.state.showSiteAlarmLevels &&
                                                                                    this.state.siteAlarmLevels.map((al) => {
                                                                                        if (al.channel == "E") {
                                                                                            return this.renderAlarmLevels(al, colorOrange[0], "right", false);
                                                                                        }
                                                                                        else {
                                                                                            return <Strip key={al.id}></Strip>
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </ValueAxis>
                                                                        }
                                                                        {(this.state.showRainfall && this.state.unitsR == 'mm') &&
                                                                            <ValueAxis
                                                                                width={2}
                                                                                name="Rain"
                                                                                visualRange={{ startValue: this.state.minFValue * 0.9, endValue: this.state.maxFValue * 1.1 }}
                                                                                allowDecimals={true}
                                                                                title={{ text: "Rainfall mm", font: { color: "green", size: 12 } }}
                                                                                position="left"
                                                                                color="green"
                                                                            >
                                                                                <Label font={{ color: "green" }} />
                                                                            </ValueAxis>
                                                                        }


                                                                        <ArgumentAxis
                                                                            width={2}
                                                                            allowDecimals={false}
                                                                            argumentType="datetime"
                                                                            minValueMargin={0.1}
                                                                            maxValueMargin={0.1}
                                                                            wholeRange={[this.state.startDate, this.state.endDate]}
                                                                        >
                                                                            <GridChart visible={true} />
                                                                        </ArgumentAxis>
                                                                        <Export enabled={true} />
                                                                        <Crosshair
                                                                            enabled={true}
                                                                            color="#949494"
                                                                            width={3}
                                                                            dashStyle="dot"
                                                                        >
                                                                            <Label
                                                                                visible={true}
                                                                                backgroundColor="#949494"
                                                                            >
                                                                                <Font
                                                                                    color="#fff"
                                                                                    size={14}
                                                                                />
                                                                            </Label>
                                                                        </Crosshair>
                                                                        <ZoomAndPan
                                                                                allowMouseWheel={false}
                                                                                argumentAxis="both"
                                                                                valueAxis="none"
                                                                                dragToZoom={true}
                                                                        />
                                                                        <ScrollBar
                                                                            visible={false}
                                                                        />
                                                                        <Legend
                                                                                visible={true}
                                                                                position="outside"
                                                                                horizontalAlignment="center"
                                                                                verticalAlignment="top"
                                                                                customizeText={this.renderLegendText.bind(this)}
                                                                                markerRender={this.renderCheckBox.bind(this)}
                                                                        />
                                                                        <Tooltip enabled={true} contentRender={this.renderTooltip.bind(this)}>
                                                                        </Tooltip>
                                                                    </Chart>
                                                                </Item>
                                                                <Item ratio={1}>
                                                                    <TooltipMaterial title="Change the right axis scale">
                                                                        <span>
                                                                        <IconButton aria-label="query scale" style={{ outline: "none" }}>
                                                                            <QueryStatsIcon onClick={(): void => this.toggleGraphQueryModal("right")} />
                                                                            </IconButton>
                                                                            <div style={{ display: "flex", justifyContent: 'space-around', flexDirection: "column", width: "100%", marginTop: "250px" }}>
                                                                                <TooltipMaterial title="Get data for next date range">
                                                                                    <IconButton style={{ background: moment().diff(this.state.endDate, 'hours') < 1 ? '#d3d3d3' : '#EED202', margin: "0 8px" }} onClick={(): void => this.handleForwardGraphData()} disabled={moment().diff(this.state.endDate, 'hours') < 1}>
                                                                                        <ArrowForwardIcon style={{ background: moment().diff(this.state.endDate, 'hours') < 1 ? '#d3d3d3' : '#EED202', borderRadius: "1000px", color: "black" }} />
                                                                                    </IconButton>
                                                                                </TooltipMaterial>
                                                                            </div>
                                                                        </span>
                                                                    </TooltipMaterial>
                                                                </Item>
                                                            </Box>
                                                        </div>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        }

                    </div>
                }
                <NotificationContainer />
            </div>
        );
    }

    static defaultProps = {
        showDates: true,
    };

}


export default withStyles(style)(MultiLoggerGraph);
